<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk editing {{ ids.length }} toplists</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div class="callout callout-info" v-if="!removeOperators">
            This will permanently delete the selected toplists
          </div>

          <base-checkbox
            v-model="deleteOperators"
            v-if="removeOperators"
            name="deleteOperators"
            :label="`Remove operator: ${this.$parent.$parent.title}`"
          />
          <div class="callout callout-info" v-if="deleteOperators">
            This will permanently delete this operator: {{ this.$parent.$parent.title }} from the selected
            toplists
          </div>
        </div>
        <div class="modal-footer d-flex">
          <button class="btn btn-secondary" :class="{ loading: loading }" @click="close()">Cancel</button>
          <button
            class="btn btn-danger ml-2"
            :class="{ loading: loading }"
            @click="remove()"
            v-if="!removeOperators"
          >
            Delete
          </button>
          <button
            class="btn btn-primary ml-2"
            :class="{ loading: loading }"
            @click="submit()"
            v-if="removeOperators"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopListsService from '@services/TopListsService'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

export default {
  components: { BaseCheckbox },
  data() {
    return {
      ids: [],
      loading: false,
      toplist: null,
      deleteOperators: false,
    }
  },
  props: {
    stateName: {
      type: String,
      default: () => 'data-table',
    },
    removeOperators: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(ids) {
      this.ids = ids
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    pagesCountText() {
      return `${this.ids} ${this.ids.length === 1 ? 'toplist' : 'toplists'}`
    },
    async remove() {
      if (this.ids.length > 10) {
        return this.showErrorMessages('You cannot delete more than 10 toplists at a time')
      }
      if (await confirmDelete('Warning', `Are you sure want to delete ${this.ids.length} toplists?`, 'Yes')) {
        this.loading = true
        await TopListsService.bulkRemove({ ids: this.ids })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.clearBulkSelection()
              this.showSuccessMessage('Toplists deleted')
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(() => {
            this.showUnknownErrorMessage
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    clearBulkSelection() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
    },
    async submit() {
      if (!this.deleteOperators) {
        this.showErrorMessages('Please add/choose values for bulk actions')
      } else {
        if (
          await confirmDelete('Warning', `Are you sure want to remove this operator from toplists?`, 'Yes')
        ) {
          this.loading = true
          await TopListsService.deleteOperator({ ids: this.ids })
            .then((response) => {
              if (response.data.messages) {
                this.showErrorMessages(response.data.messages)
              } else {
                this.clearBulkSelection()
                this.showSuccessMessage(`${this.$parent.$parent.title} removed from selected toplists`)
                this.$emit('refresh')
                this.close()
              }
            })
            .catch(() => {
              this.showUnknownErrorMessage
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>
