<template>
  <data-table
    model="GranularTrackingLinks"
    service-file="GranularTrackingLinksService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    :serviceParams="[this.$parent.$parent.affiliate.id]"
    :extraEditInfo="{
      title: 'Are you sure you wish to continue?',
      message: 'Removing a market or operator results in deleting tracking links.',
    }"
    :extraDeleteInfo="{
      title: 'Delete Granular Tracking Links',
      message:
        'You may delete the empty granular tracking links of this type or delete all tracking links of this type',
    }"
    :granular="true"
    ref="list"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Granular Tracking Links`
    )
  },
  methods: {
    path() {
      return `hercules/operators/granular-tracking-links?with=markets,operators&site_id=${this.$route.params['id']}`
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        {
          field: 'markets',
          type: 'text',
          label: 'Markets',
          filterable: false,
          template: TableColumnTemplate.LIST_FROM_ARRAY,
          key: 'label',
          limit: 10,
          bgColor: 'bg-azure',
        },
        {
          field: 'operators',
          type: 'text',
          label: 'Operators',
          filterable: false,
          template: TableColumnTemplate.LIST_FROM_ARRAY,
          key: 'name',
          limit: 10,
          bgColor: 'bg-green',
        },
        { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        site_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
