<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-6">
      <div class="card-header">
        <h3 class="card-title">S3 Backups</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="radio">
              <input type="radio" id="daily" value="db" v-model="type" />
              <label for="daily"
                >Daily
                <div class="form-text">Daily backups, choose a specific date</div>
              </label>
            </div>
          </div>
          <div class="col">
            <div class="radio">
              <input type="radio" id="weekly" value="archive" v-model="type" />
              <label for="weekly"
                >Weekly
                <div class="form-text">Download latest weekly backup</div>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <base-select
              label="Site"
              option-label-key="website"
              :path="`hercules/operators/affiliates?orderBy=website&ascending=1`"
              v-model="site"
              trackBy="website"
            />
          </div>
        </div>
        <div class="row">
          <base-date
            v-if="type === 'db'"
            label="Date"
            v-model="date"
            :config="datePickerConfig"
            class="col-6"
            :clearable="false"
            placeholder="Select a date..."
          />
        </div>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-primary float-right mr-1"
          v-bind:class="{ loading: loadingDb }"
          @click="fetch()"
        >
          Download
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BackupsService from '@/services/devops/BackupsService'
import BaseSelect from '@/components/fields/base-select'
import BaseDate from '@atoms/fields/base-date'
import moment from 'moment'

export default {
  data() {
    return {
      site: null,
      type: 'db',
      date: moment().format('YYYY-MM-DD'),
      datePickerConfig: {
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        enableTime: false,
        maxDate: moment().format(),
      },
      loadingDb: false,
      loadingArchive: false,
    }
  },
  components: { BaseSelect, BaseDate },
  methods: {
    fetch() {
      const self = this
      if (this.type == 'db') {
        this.loadingDb = true
      } else {
        this.loadingArchive = true
      }

      let body = {
        site: this.site,
      }
      if (this.date && this.type == 'db') {
        body = {
          site: this.site,
          date: this.date,
        }
      }
      BackupsService.search(self.type, body)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            if (response.data.result) {
              const key = response.data.result.key
              BackupsService.fetch({ key: key, type: self.type })
                .then((response) => {
                  if (response.data.messages) {
                    this.showErrorMessages(response.data.messages)
                  } else {
                    if (response.data.result) {
                      this.showSuccessMessage('Found ' + this.capitalize(self.type))
                      if (response.data.result.url) {
                        window.open(response.data.result.url, '_blank')
                      } else if (response.data.result.link) {
                        window.open(response.data.result.link, '_blank')
                      }
                    }
                  }
                })
                .catch(this.showUnknownErrorMessage)
                .finally(function () {
                  if (self.type == 'db') {
                    self.loadingDb = false
                  } else {
                    self.loadingArchive = false
                  }
                })
            }
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(function () {
          if (self.type == 'db') {
            self.loadingDb = false
          } else {
            self.loadingArchive = false
          }
        })
    },
  },
}
</script>
