import { createStore } from 'vuex'
import cms from './modules/cms'
import media from './modules/media'
import app from './modules/app'
import tiptap from './modules/tiptap'
import sidebar from './modules/sidebar'
import user from './modules/user'
import baseSelect from './modules/baseSelect'
import dynamicForm from './modules/dynamicForm'
import editPage from './modules/editPage'

//eslint-disable-next-line
const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
  modules: {
    sidebar,
    app,
    tiptap,
    media,
    user,
    cms,
    baseSelect,
    dynamicForm,
    editPage,
  },
  strict: debug,
})
