<template>
  <div>
    <data-table
      model="AffiliateLink"
      ref="table"
      :path="path()"
      :columns="columns()"
      :show-remove="false"
      :customRowClick="rowClick"
      :showEditModal="false"
      :show-create="false"
      :enableBulkSelect="true"
      :csvColumnTemplates="csvColumnTemplates()"
      :column-templates="columnTemplates()"
      @bulkClickByIds="bulkClick"
      :tableLimits="[10, 100, 200]"
    />
    <affiliate-link-edit ref="editModal" :closeAfterRemove="true" @refresh="refreshTable" />
  </div>
</template>

<script lang="jsx">
import AffiliateLinkEdit from '@molecules/dms/affiliate-link/edit-modal.vue'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    if (this.$route.query['child']) {
      this.$refs.editModal.show({ id: this.$route.query['child'] })
    }
  },
  components: { AffiliateLinkEdit },
  methods: {
    columns() {
      return [
        {
          field: 'affiliate_operator.affiliate.name',
          label: 'Site',
          fieldCustomName: 'Sites',
          exact_match: 1,
          search_key: 'affiliate_operator__affiliate__id',
          order_key: 'affiliate_operator__affiliate__id',
          path: 'hercules/globals/sites?orderBy=name&fields_only[]=id&fields_only[]=name&ascending=1',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          advancedFilter: true,
          filterable: true,
          showModalAdvanced: true,
          showEmptyNull: false,
          fetch_on_load: false,
          includeCustomPath: true,
        },
        {
          field: 'affiliate_operator.operator.name',
          label: 'Operator',
          fieldCustomName: 'Operators',
          exact_match: 1,
          search_key: 'affiliate_operator__operator__id',
          order_key: 'affiliate_operator__operator__id',
          path: 'hercules/operators/operators?orderBy=name&ascending=1&fields_only[]=id&fields_only[]=name',
          filterable: true,
          optionLabelKey: 'name',
          basicFilterType: 'select',
          advancedFilter: true,
          showModalAdvanced: true,
          showEmptyNull: false,
          fetch_on_load: false,
          includeCustomPath: true,
        },
        {
          field: 'affiliate_operator.type',
          label: 'Type',
          fieldCustomName: 'Types',
          search_key: 'affiliate_operator__type',
          order_key: 'affiliate_operator__type',
          filterable: true,
        },
        {
          field: 'affiliate_operator.market.label',
          label: 'Market',
          fieldCustomName: 'Markets',
          search_basic_key: 'affiliate_operator__market__label',
          search_key: 'affiliate_operator__market__id',
          order_key: 'affiliate_operator__market__label',
          path: 'hercules/globals/markets?orderBy=label&ascending=1&fields_only[]=id&fields_only[]=label',
          filterable: true,
          optionLabelKey: 'label',
          basicFilterType: 'text',
          advancedFilter: true,
          showModalAdvanced: true,
          showEmptyNull: false,
          fetch_on_load: false,
          includeCustomPath: true,
        },
        { field: 'name', type: 'text', filterable: true, template: TableColumnTemplate.TEXT_TRUNCATE },
        {
          field: 'link',
          type: 'text',
          filterable: true,
          optionLabelKey: 'label',
          basicFilterType: 'text',
          search_key: 'link',
          order_key: 'link',
          advancedFilter: true,
          showModalAdvanced: true,
          fetch_on_load: false,
          includeCustomPath: true,
          model: 'AffiliateLink',
          template: TableColumnTemplate.CLIPBOARD,
          templateClipboardClass: true,
          textarea: true,
          extraFilters: ['only_populated', 'only_empty'],
          optionPlaceholder: 'Separate URLs by new line (press enter)',
        },
        {
          field: 'custom_link',
          label: 'Mobile Link',
          type: 'text',
          filterable: true,
          optionLabelKey: 'custom_link',
          basicFilterType: 'text',
          search_key: 'custom_link',
          order_key: 'custom_link',
          advancedFilter: true,
          showModalAdvanced: true,
          fetch_on_load: false,
          includeCustomPath: true,
          model: 'AffiliateLink',
          template: TableColumnTemplate.CLIPBOARD,
          templateClipboardClass: true,
          textarea: true,
          extraFilters: ['only_populated', 'only_empty'],
          optionPlaceholder: 'Separate URLs by new line (press enter)',
        },
        {
          field: 'crawl_status',
          label: 'Crawl status',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: false,
          clearOnClick: true,
          basicFilterType: 'select',
          customOptions: [
            { label: 'Pending', id: 'pending' },
            { label: 'Completed', id: 'completed' },
            { label: 'Failed', id: 'failed' },
            { label: 'Scheduled', id: 'scheduled' },
            { label: 'Skipped', id: 'skipped' },
          ],
          optionLabelKey: 'label',
        },
        {
          field: 'affiliate_operator.status',
          label: 'Operator Status',
          fieldCustomName: 'Status',
          type: 'text',
          advancedFilter: true,
          showModalAdvanced: true,
          search_key: 'affiliate_operator__status',
          order_key: 'affiliate_operator__status',
          filterable: true,
          exact_match: 1,
          customOptions: [
            { label: 'Active', id: 'active' },
            { label: 'Inactive', id: 'inactive' },
            { label: 'Coming soon', id: 'coming_soon' },
            { label: 'Blacklisted', id: 'blacklisted' },
            { label: 'Not recommended', id: 'not_recommended' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          includeCustomPath: true,
          template: TableColumnTemplate.STATUS,
        },
        {
          field: 'updated_at',
          type: 'text',
          filterable: true,
          label: 'Updated',
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    csvColumnTemplates() {
      return Object.assign({
        crawl_status: function (row) {
          switch (row.crawl_status) {
            case null:
              return 'Not Valid'
            case 'pending':
              return 'Pending'
            case 'failed':
              return row.crawl_status_message.substring(0, 100)
            case 'completed':
              return 'Valid'
            case 'scheduled':
              return 'Scheduled'
            case 'skipped':
              return 'Skipped'
            default:
              return 'Not Valid'
          }
        },
      })
    },
    columnTemplates() {
      return Object.assign({
        crawl_status: function (row) {
          let message = row.crawl_status_message
          if (row.crawl_status === 'pending') {
            return (
              <span data-tooltip="Not verified">
                <i class="uil uil-question-circle text-dark"></i>
              </span>
            )
          }
          if (row.crawl_status === 'completed') {
            return (
              <span data-tooltip="Valid">
                <i class="uil uil-check-circle text-success"></i>
              </span>
            )
          } else if (row.crawl_status === 'scheduled') {
            return (
              <span data-tooltip="Scheduled">
                <i class="uil uil-clock text-warning"></i>
              </span>
            )
          } else if (row.crawl_status === 'skipped') {
            return (
              <span data-tooltip={message}>
                <i class="uil uil-minus-circle text-black-25"></i>
              </span>
            )
          } else {
            return (
              <span data-tooltip={message}>
                <i class="uil uil-ban text-danger"></i>
              </span>
            )
          }
        },
      })
    },
    path() {
      return `hercules/operators/affiliate-links?with=${[
        'affiliate_operator.market',
        'affiliate_operator.operator',
        'affiliate_operator.affiliate',
      ].join(',')}`
    },
    bulkClick(ids) {
      this.$refs.editModal.show({ ids: ids })
    },
    refreshTable() {
      this.$refs.table.deselectBulk()
      this.$refs.table.search()
    },
    rowClick(event, row) {
      this.$refs.editModal.show({ id: row.id })
    },
  },
}
</script>
