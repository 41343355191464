<template>
  <div>
    <base-select
      placeholder="Select a site"
      v-model="site"
      label="Site"
      :path="`hercules/sites/sites?orderBy=name&ascending=1`"
      option-label-key="name"
    />
    <div v-if="site && isLoading">
      Loading...
    </div>
    <div v-else-if="site && !isLoading">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">CMS Statistics</h3>
        <div class="callout callout-help mb-0">
          Only showing pages that are <strong>NOT</strong> deleted and have status <strong>active</strong>
        </div>
      </div>

      <div></div>
      <table class="table" v-if="site && cmsStats">
        <thead>
          <tr>
            <th class="font-weight-bold text-primary">Page type</th>
            <th v-for="(market, index) in cmsStats.site_markets" :key="index" class="text-center">
              {{ market }}
            </th>
            <th class="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(template, index) in cmsStats.templates" :key="index">
            <td class="text-muted border-right">{{ template }}</td>
            <td v-for="(market, index) in cmsStats.site_markets" :key="index" class="text-center">
              {{ cmsStats.pages_by_template[template].markets[market] }}
            </td>
            <td class="text-center border-left font-weight-bold">
              {{ cmsStats.pages_by_template[template].page_count }}
            </td>
          </tr>
          <tr class="font-weight-bold">
            <td class="bg-secondary ">Total</td>
            <td
              v-for="(market, index) in cmsStats.site_markets"
              :key="index"
              class="text-center bg-lime bg-secondary"
            >
              {{ cmsStats.pages_by_market[market].page_count }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select'

export default {
  components: { BaseSelect },
  data() {
    return {
      cmsStats: null,
      site: 39,
      isLoading: true
    }
  },
  watch: {
    site() {
      this.getCmsStats()
    }
  },
  created() {
    this.setTitle()
    this.getCmsStats()
  },
  methods: {
    getCmsStats() {
      if (this.site === null) return
      this.isLoading = true
      this.$http
        .get(`hercules/sites/stats/${this.site}`)
        .then(response => {
          this.cmsStats = response.data.result
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
