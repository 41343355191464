<template>
  <div>
    <data-table
      model="cms/Author"
      service-file="AuthorsService"
      path="hercules/sites/authors?with=site&with_count=pages"
      :columns="columns()"
      :show-edit-modal="false"
      :column-templates="columnTemplates()"
      :rowActionButtons="rowActions()"
      ref="list"
    />
    <clone-modal ref="cloneModal" @refresh="$refs.list && $refs.list.search()" />
  </div>
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import AuthorsService from '@services/AuthorsService'
import CloneModal from '@molecules/cms/authors/clone-modal'

export default {
  components:{ CloneModal },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'site', type: 'text', label: 'Site', filterable: true },
        { field: 'pages_count', type: 'text', filterable: true, label: 'Author Pages' },
        { field: 'reviewer_pages_count', type: 'text', filterable: true, label: 'Reviewer Pages'},
        { field: 'active', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.list.isLoading = true
      let object = { id: id }
      object[field] = value
      AuthorsService.update(object)
        .then(() => {
          this.$refs.list.search()
          this.showSuccessMessage(`Author set to ${object.active ? 'active' : 'inactive'}`)
        })
        .catch(() => {
          this.showErrorMessage('Failed to update author')
          this.$refs.list.isLoading = false
        })
    },
    rowActions() {
      let actions = [
        {
          action: this.cloneAuthorClick,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'btn-info'
        }
      ]
      return actions
    },
    cloneAuthorClick(row) {
      this.$nextTick(() => {
        this.$refs.cloneModal.show(row)
      })
    },
    columnTemplates() {
      const self = this
      return Object.assign({
        site: function (row) {
          return row.site.name
        },
        active: function (row) {
          return (
            <EnabledActionColumn
              row={row}
              field={'active'}
              onColumnClick={self.actionColumnClick}
              confirmTitle={'Careful, this action affects the pages!'}
              confirmMessage={
                row.active
                  ? 'Author of the affected pages will be set to none. Do you want to proceed?'
                  : null
              }
              confirmButtonTitle={'Yes'}
              confirmButtonIconClass={''}
              confirmButtonClass={'btn bg-orange'}
            />
          )
        },
      })
    },
  },
}
</script>
