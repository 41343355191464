<template>
  <div class="d-grid">
    <div class="card" v-for="(card, index) in cards" :key="index">
      <div class="card-header font-weight-bold">
        <h5>{{ card.title }}</h5>
      </div>
      <div class="card-body" v-html="card.description" />
    </div>
  </div>
</template>
<script>
import PagesService from '@services/PagesService'

export default {
  data() {
    return {
      cards: []
    }
  },
  methods: {
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    },
    getCards() {
      const ids = this.findFieldByName('cards_selector_manual_list').value
      PagesService.get({ ids: ids }).then(response => {
        this.cards = response.data.result
      })
    }
  },
  mounted() {
    this.getCards()
  },
  computed: {},
  props: {
    fieldSpecificAttributes: {},
    pageModule: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
</style>
