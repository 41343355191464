<template>
  <div>
    <tags-type-filter @filterChanged="filterChanged" />
    <data-table
      ref="table"
      :key="getService()"
      :model="getModel()"
      :service-file="getService()"
      :path="path()"
      permissionPath="koala/tag-values"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      :tableLimits="[10, 50, 100]"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import TagsTypeFilter from '@molecules/koala/tag-values/tags-type-filter.vue'
import { capitalize } from 'lodash'

export default {
  components: { TagsTypeFilter },
  created() {
    this.setTitle()
  },
  data() {
    return {
      user: this.$auth.user(),
      tagValues: false,
      tagsType: 'brands',
      tagsTypePascalCase: 'Brands',
    }
  },
  methods: {
    getService: function () {
      return `koala/Tags${this.tagsTypePascalCase}Service`
    },
    getModel: function () {
      return `koala/Tags${this.tagsTypePascalCase}`
    },
    toPascalCase(string) {
      const words = string.split('-')
      const capitalized = words.map((word) => capitalize(word))

      return capitalized.join('')
    },
    columns() {
      return [
        {
          label: 'Name',
          type: 'text',
          search_key: 'name',
          showModalAdvanced: true,
          field: 'name',
        },
        {
          label: 'Updated',
          type: 'date',
          filterable: false,
          field: 'updated',
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    columnTemplates() {
      return Object.assign({
        name: function (row) {
          return row.name
        },
      })
    },
    path() {
      return `koala/v1/tags/${this.tagsType}`
    },
    filterChanged(filter) {
      this.tagsType = filter.tags_type
      this.tagsTypePascalCase = this.toPascalCase(filter.tags_type)

      this.$store.commit('data-table/setActivePage', 1)
      this.$store.dispatch('data-table/setSiteFilters', {})
    },
  },
}
</script>
