<template>
  <div>
    <iframe class="iframe-module" :src="this.findFieldByName('iframe_source')"/>
    <page-edit-module-field-preview
        v-for="(moduleField, index) in pageModule.module_value_fields"
        v-bind:key="index"
        :index="index"
        :siteId=pageModule.site_id
        :affiliateId=0
        :marketId=0
        :moduleField="moduleField"
    />
  </div>

</template>
<script>
import PageEditModuleFieldPreview from "@templates/forms/module-dynamic-fields-preview.vue";

export default {
  components: {
    PageEditModuleFieldPreview
  },
  methods: {
    findFieldByName(name) {
      let iFrameSource = this.pageModule.module_value_fields.find(item => item.field.name === name).value;
      if(this.isYoutubeLink(iFrameSource)) {
        let videoId = this.getYouTubeVideoId(iFrameSource);
        return `https://www.youtube.com/embed/${videoId}`
      } else {
        return iFrameSource
      }
    },
    isYoutubeLink(link) {
      const youtubeRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/;

      return youtubeRegex.test(link);
    },
    getYouTubeVideoId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[7].length===11)? match[7] : false;
    }
  },
  props: {
    pageModule: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.iframe-module {
    min-height: 350px;
    border: none;
    width: 100%;
}
</style>
