import Campaigns from '@pages/koala/campaigns.vue'
import KoalaCampaigns from '@pages/koala/koala_campaigns.vue'
import Units from '@pages/koala/units.vue'
import Currencies from '@pages/koala/currencies.vue'
import Platforms from '@pages/koala/platforms.vue'
import Operators from '@pages/koala/operators.vue'
import Accounts from '@pages/koala/accounts.vue'
import Proxies from '@pages/koala/proxies.vue'
import PlatformReports from '@pages/koala/platform-reports.vue'
import AccountReports from '@pages/koala/account-reports.vue'
import Monitoring from '@pages/koala/monitoring.vue';
import Repull from '@pages/koala/repull.vue';
import TagValues from '@pages/koala/tag-values.vue';
import GroupedMonitoring from '@pages/koala/grouped-monitoring.vue'

export default [
  {
    path: '/koala-monitoring',
    component: Monitoring,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Monitoring',
      permission: 'view.koala/monitoring'
    }
  },
  {
    path: '/koala-grouped-monitoring',
    component: GroupedMonitoring,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Grouped Monitoring',
      permission: 'view.koala/monitoring'
    }
  },
  {
    path: '/old-campaigns',
    component: Campaigns,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Campaigns',
      permission: 'view.koala/campaigns',
    },
  },
  {
    path: '/koala-campaigns',
    component: KoalaCampaigns,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'KoalaCampaigns',
      permission: 'view.koala/campaigns',
    },
  },
  {
    path: '/koala-campaigns-new',
    component: KoalaCampaigns,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'KoalaCampaigns',
      permission: 'view.koala/campaigns',
    },
  },
  {
    path: '/koala-units',
    component: Units,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Units',
      permission: 'view.koala/business-units',
    },
  },
  {
    path: '/koala-currencies',
    component: Currencies,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Currencies',
      permission: 'view.koala/currencies',
    },
  },
  {
    path: '/koala-platforms',
    component: Platforms,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Platforms',
      permission: 'view.koala/platforms',
    },
  },
  {
    path: '/koala-operators',
    component: Operators,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Operators',
      permission: 'view.koala/operators',
    },
  },
  {
    path: '/koala-accounts',
    component: Accounts,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Accounts',
      permission: 'view.koala/accounts',
    },
  },
  {
    path: '/koala-proxies',
    component: Proxies,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Proxies',
      permission: 'view.koala/proxies',
    },
  },
  {
    path: '/koala-platform-reports',
    component: PlatformReports,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Reports',
      permission: 'view.koala/reports',
    },
  },
  {
    path: '/koala-repull',
    component: Repull,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Repull',
      permission: 'view.koala/account-jobs'
    }
  },
  {
    path: '/koala-account-reports',
    component: AccountReports,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Account Reports',
      permission: 'view.koala/account-reports',
    },
  },
  {
    path: '/tag-values',
    component: TagValues,
    meta: {
      auth: true,
      parent: 'Affiliate Management',
      name: 'Tag Values',
      permission: 'view.koala/tag-values'
    }
  },
]
