<template>
  <data-table
    model="Role"
    service-file="RolesService"
    path="backoffice/roles"
    :show-edit-modal="false"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      title: 'Roles'
    }
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    }
  }
}
</script>
