<template>
  <div>
    <h3>{{ findFieldByName('top_list_title').value }}</h3>
    <div class="nav nav-tabs" v-if="activeList && isMulti">
      <div
        class="nav-item"
        v-for="(item, index) in topListItems"
        :key="index"
        v-bind:class="{ active: activeList.id === item.id }"
      >
        <button class="nav-link" @click="listItemClick(item)">
          {{ findFieldByNameInField(item, 'top_list_item_title').value }}
        </button>
      </div>
    </div>
    <top-list-item v-if="activeList" :module-field="activeList" ref="listItem" />
  </div>
</template>
<script>

import TopListItem from '@atoms/cms/modules/preview/top-list-item'

export default {
  data() {
    return { activeList: null }
  },
  components: { TopListItem },
  props: {
    fieldSpecificAttributes: {},
    pageModule: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.activeList = this.topListItems[0]
  },
  methods: {
    listItemClick(item) {
      this.activeList = item
      this.$nextTick(() => {
        this.$refs.listItem.getListData()
      })
    },
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    },
    findFieldByNameInField(field, name) {
      return field.children.find(item => item.field.name === name)
    }
  },
  computed: {
    topListItems() {
      return this.findFieldByName('top_list_items').children
    },
    selectedTopListItem() {
      return this.topListItems.filter((item, index) => index === this.activeList)
    },
    isMulti() {
      return this.findFieldByName('top_list_items').children.length > 1
    }
  }
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
</style>
