const state = {
  object: {},
}

const getters = {
  getObject: (state) => {
    return state.extraOption
  },
}

const actions = {
  setObject({ commit }, value) {
    commit('populateObject', value)
  },
}

const mutations = {
  setObject(state, value) {
    state.object = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
