<template>
  <div class="row pt-1">
    <div class="col mx-auto py-5">
      <div class="text-center mb-4">
        <svg
          id="login-logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 117.96 117.84"
          height="120px"
          fill="#fff"
        >
          <path
            d="M117.96 29.34v59.39c0 8.31-2.42 15.35-7.03 20.43l-.33.36c-.13.14-.26.28-.4.41-.05.06-.11.11-.17.17l-.07.07c-.05.05-.11.11-.17.16-.07.07-.15.14-.22.21l-.36.33c-4.93 4.44-11.88 6.97-20.62 6.97H29.36c-8.74 0-15.69-2.53-20.62-6.97l-.36-.33c-.13-.13-.27-.26-.4-.38-.08-.08-.16-.16-.23-.24-.13-.13-.26-.26-.39-.4l-.33-.36C2.42 104.09 0 97.04 0 88.73V29.34c0-9 2.54-15.97 7.03-20.86.11-.12.22-.24.33-.35l.12-.12c.05-.05.11-.11.16-.17l.07-.07c.06-.06.11-.11.17-.16.1-.1.21-.2.32-.31l.36-.33C13.67 2.35 20.83 0 29.36 0H88.6c8.54 0 15.7 2.35 20.81 6.97l.36.33c.1.1.21.2.31.29.08.08.16.15.23.23.1.1.2.2.29.3.11.12.22.23.33.35 4.49 4.9 7.03 11.88 7.03 20.87"
            :fill="$store.state.app.environmentColor"
          />

          <path
            class="st1"
            d="M68.16 16.66l-.93 25.83 9.71 6.96v33.64l-10.89-7.8-.89 24.54 13.25-8.56 14.64-38.22 8.63.37zM41.02 83.09V49.45l9.83-7.05-.92-25.74-33.52 36.76 8.63-.37 14.64 38.22 13.25 8.56-.89-24.64z"
          />

          <path fill="#ffd100" d="M72.94 51.51L58.98 41.5 45.02 51.51V75.3l13.96-10 13.96 10z" />
        </svg>

        <h1 class="mt-5">Reset password</h1>
      </div>

      <div class="card mx-auto" style="max-width: 450px">
        <div class="card-body">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <label class="text-muted">Email</label>

              <input
                type="email"
                id="email"
                autofocus="true"
                name="email"
                class="form-control"
                placeholder="user@example.com"
                v-model="email"
                autocomplete="email"
                required
                :readonly="done"
              />
            </div>

            <button
              v-if="!done"
              type="submit"
              class="btn btn-block btn-primary"
              v-bind:class="{ loading: isLoading }"
            >
              Reset password
            </button>
          </form>

          <div v-if="done">
            <div class="alert alert-success" role="alert">
              Please check your email for further instructions.
            </div>

            <p class="text-muted">
              Didn't receive an email? Request an account via Slack (
              <a href="slack://channel?team=T0707SH4P&id=CCFDPGRUL">#gm-alpha-hercules</a>
              )
            </p>
          </div>
        </div>
      </div>

      <p class="text-center">
        <router-link :to="{ path: '/login' }" class="text-bluegrey-1">Login to your account</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import AuthService from '@services/AuthService'

export default {
  data() {
    return {
      email: null,
      done: false,
      isLoading: false,
    }
  },
  created() {
    this.$parent.$parent.showSidebar = false
    this.$parent.$parent.showNavbar = false
    this.setTitle()
  },
  methods: {
    requestSent() {
      this.done = true
      this.showSuccessMessage('Request has been sent!')
    },
    async onSubmit() {
      this.isLoading = true
      await AuthService.forgotPassword(this.email)
        .then((response) => {
          if (!response.data.success && response.data.messages && response.data.messages.length > 0) {
            this.showErrorMessage(response.data.messages[0])
            return
          }
          this.requestSent()
        })
        .catch((error) => {
          const data = error.response.data
          const errors = Object.hasOwn(data, 'errors') ? data.errors : false
          if (errors) {
            Object.keys(errors).forEach((key) => {
              this.showErrorMessage(errors[key][0])
            })
            return
          }
          const messages = Object.hasOwn(data, 'messages') ? data.messages : false
          if (messages) {
            messages.forEach((message) => {
              if (message === 'User not found') {
                this.requestSent()
              } else {
                this.showErrorMessage(message)
              }
            })
            return
          }
          this.showUnknownErrorMessage()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
