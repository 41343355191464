<template>
  <div class="modal" ref="modal" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="structure.config !== undefined">Add New type</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <dynamic-form
            form-type="create"
            ref="form"
            :structure="structure"
            :object="object"
            :formFields="structure.list"
            :path="structure.config.url"
            :on-submit="submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import BonusService from '@/services/BonusesService'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService'

let service

export default {
  components: { DynamicForm },
  beforeMount() {
    service = this.model === 'affiliate' ? AffiliateOperatorsService : BonusService
  },
  data() {
    return {
      structure: {
        config: { name: 'Bonus Type Value' },
        list: [
          { name: `${this.model}_id`, type: 'hidden', required: true },
          { name: 'name', type: 'text', display_name: 'Name', required: true, auto_focus: true }
        ]
      },
      object: {},
      isActive: false
    }
  },
  props: {
    parentId: {
      required: true,
      type: Number
    },
    typeId: {
      required: true,
      type: Number
    },
    model: {
      required: true,
      type: String
    }
  },
  methods: {
    show() {
      document.getElementById('modal').appendChild(this.$el)
      this.isActive = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.modal.classList.add('show')
        }, 30)
      })
    },
    submit() {
      const form = this.$refs.form
      form.isLoading = true

      //Converting new "Bonuses -> Selected Types" names to kebab-case before saving
      if (Object.hasOwn(this.object, 'name')) {
        this.object.name = this.object.name.replace(/ /g, '-').toLowerCase()
      }

      service
        .postType(this.parentId, this.object)
        .then(response => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.object = {}
            this.close()
            this.$emit('refresh', response.data.result.id)
            this.showSuccessMessage('Type created')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(function() {
          form.isLoading = false
        })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.isActive = false
    }
  }
}
</script>
