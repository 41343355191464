<template>
  <div v-if="prefilledModule">
    <div class="row mb-1">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-prefilled-modules` }"
            data-tooltip="Back to prefilled modules"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ prefilledModule.name }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(prefilledModule.updated_at) }}</span>
          <span class="badge bg-azure ml-2">{{ prefilledModule.site.name }}</span>
          <span class="badge bg-orange ml-2">{{ $prettyLabels(prefilledModule.module.name) }}</span>
          <span
            class="badge bg-green ml-2 clickable"
            v-if="prefilledModule.active"
            @click="() => (prefilledModule.active = false)"
            >active</span
          >
          <span
            class="badge bg-black-25 ml-2 clickable"
            v-if="!prefilledModule.active"
            @click="() => (prefilledModule.active = true)"
            >inactive</span
          >
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <li v-for="(tab, index) in tabs" v-bind:class="{ active: activeTab === tab }" v-bind:key="index">
        <a @click="tabClick(tab)">{{ $capitalize(tab) }}</a>
      </li>
    </ul>
    <prefilled-module-edit-general v-if="activeTab === 'general'" :prefilled-module="prefilledModule" />
    <prefilled-module-edit-markets v-if="activeTab === 'markets'" :prefilled-module="prefilledModule" />
  </div>
</template>
<script>
import PrefilledModulesService from '@services/PrefilledModulesService'
import PrefilledModuleEditGeneral from './prefilled-module-edit-general'
import PrefilledModuleEditMarkets from './prefilled-module-edit-markets'

export default {
  components: {
    PrefilledModuleEditGeneral,
    PrefilledModuleEditMarkets,
  },
  data() {
    return {
      tabs: ['general', 'markets'],
      activeTab: 'general',
      prefilledModule: null,
    }
  },
  beforeMount() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.replace('#', '')
    }
  },
  created() {
    this.getPrefilledModule()
  },
  methods: {
    getPrefilledModule() {
      PrefilledModulesService.getOne(this.$route.params['id'], {
        with: [
          'site',
          'module',
          'prefilled_module_markets',
          'prefilled_module_markets.site_market',
          'prefilled_module_markets.site_market.market',
        ].join(),
        sort_by_market: true,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.prefilledModule = response.data.result[0]
            this.activeIndex = this.prefilledModule.prefilled_module_markets[0].id
          }
        })
        .catch(self.showUnknownErrorMessage)
    },
    tabClick(tab) {
      this.activeTab = tab
      history.replaceState(undefined, undefined, `#${tab.toLowerCase()}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.modules {
  z-index: 1;
}
</style>
