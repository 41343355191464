<template>
    <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Clone Author Data</h5>
            <button class="close" v-on:click="close"></button>
          </div>
          <dynamic-form
            form-type="edit"
            v-if="object"
            :inModal="true"
            ref="form"
            :formFields="structure.list"
            :path="structure.config.url"
            :structure="structure"
            :object="object"
            :on-submit="submit"
            submit-label="Clone"
          />
        </div>
      </div>
    </div>
</template>
  
<script>
import AuthorsService from '@services/AuthorsService'
import DynamicForm from '@/components/fields/DynamicForm'
import AuthorCloned from '../../../../../models/cms/AuthorCloned'
export default {
components: { DynamicForm },
data() {
  return {
    loading: false,
    object: null,
    structure: {},
    config: AuthorCloned.getConfig()
  }
},
mounted() {
  document.getElementById('modal').appendChild(this.$el)
},
methods: {
  show(object) {
    this.structure = AuthorCloned.getStructure(object.id)
    this.object = Object.assign({}, object)
    this.object.market = this.object.market_id = null
    this.$refs.modal.classList.add('show')
  },
  close() {
    this.$refs.modal.classList.remove('show')
    this.object = null
  },
  submit() {
    AuthorsService.clone(this.object)
      .then(response => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
          this.$refs.form.isLoading = false
        } else {
          this.showSuccessMessage('Author Data cloned')
          this.$refs.form.isLoading = false
          this.close()
        }
      })
      .catch(this.showUnknownErrorMessage)
      .finally(() => {
        this.$emit('refresh')
      })
  }
}
}
</script>