<template>
  <div>
    <div v-bind:key="index" v-for="(moduleField, index) in pageModule.module_value_fields">
      <page-select-modal
        v-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_selector_manual_list'
        "
        :value="moduleField.value === null ? '' : moduleField.value"
        @valuePicked="(value) => selectedPages(moduleField, value)"
        :parameters="pageSelectFieldGetParams"
        :pageTypeId="pageType ? pageType.id : null"
        :type="pageType ? pageType.type : 'Page'"
        customLabel="Cards"
        tableClass="table table-sm"
        tableWrapperClass="card-body px-2 py-0 px-0"
        :showFilter="pageType ? true : false"
      />
      <cards-v2-filters
        v-else-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_selector_filters'
        "
        :parameters="{ site_id: siteId, market_id: marketId, match_market_language: 1 }"
        :value="cardsFilters"
        :pageType="pageType"
        v-model="cardsFilters"
      />
      <base-select
        :label="moduleField.field.name"
        v-else-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_page_type'
        "
        :modelValue="moduleField.value"
        v-model="moduleField.value"
        :path="`hercules/sites/templates?site_id=${siteId}&is_filterable=1&orderBy=name&ascending=1`"
        option-label-key="name"
        emitOption="type"
        placeholder="Select page type"
        @update:modelValue="(value, option) => getPageType(value)"
        :clearable="true"
        helpText="Leave empty to select from all page types"
      />
      <page-edit-module-field
        v-else-if="
          moduleField.field.name !== 'cards_selector_manual_list' &&
          moduleField.field.name !== 'cards_selector_filters'
        "
        :index="index"
        :siteId="siteId"
        :affiliateId="affiliateId"
        :marketId="marketId"
        :moduleField="moduleField"
        :visibleFields="visibleFields"
      />
    </div>
  </div>
</template>
<script>
import PageSelectModal from '@molecules/fields/page-multi-select/multi-select-modal.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select'
import DynamicFilters from '@/components/fields/dynamic-filters'
import CardsV2Filters from '@/components/atomic/molecules/cms/modules/cards-v2-filters'
import BaseRadio from '@atoms/fields/base-radio.vue'
import ModuleGroupAddItem from '@atoms/cms/modules/module-group-add-item'
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'
import TemplateService from '@services/TemplatesService'

export default {
  components: {
    PageSelectModal,
    BaseSelect,
    BaseInput,
    ModuleGroupAddItem,
    BaseRadio,
    PageEditModuleField,
    DynamicFilters,
    CardsV2Filters,
  },
  props: {
    siteId: {
      required: false,
      default: null,
    },
    marketId: {
      required: false,
      default: null,
    },
    pageModule: {
      type: Object,
      required: true,
      default: () => {},
    },
    affiliateId: {},
  },
  beforeMount() {
    // load page type before mount because many fields depend on this
    this.getPageType(this.findFieldByName(this.pageModule, 'cards_page_type').value, false)
  },
  data() {
    return {
      pageType: null,
    }
  },
  computed: {
    cardsFilters: {
      get: function () {
        return JSON.parse(this.findFieldByName(this.pageModule, 'cards_selector_filters').value)
      },
      set: function (newValue) {
        this.findFieldByName(this.pageModule, 'cards_selector_filters').value = JSON.stringify(newValue)
      },
    },
    cardsSelector() {
      return this.findFieldByName(this.pageModule, 'cards_selector').value
    },
    visibleFields() {
      let visibleFields = [
        'cards_title',
        'cards_link',
        'cards_page_type',
        'cards_selector',
        'cards_enable_pagination',
        'cards_v2_extra_data',
      ]
      switch (this.cardsSelector) {
        case 'select_manually':
          return visibleFields.concat(['cards_selector_manual_list'])
        case 'use_filters':
          return visibleFields.concat([
            'cards_selector_filters_limit',
            'cards_selector_filters',
            'cards_selector_filters_sort_by',
          ])
      }
      return visibleFields
    },
    showAddPages() {
      return this.pageType ? true : false
    },
    pageSelectFieldGetParams() {
      let params = {
        site_id: this.siteId,
        market_id: this.marketId,
        affiliate_id: this.affiliateId,
        match_market_language: 1,
        status: 'active',
      }
      if (this.pageType) {
        params.type = this.pageType.type
      }
      return params
    },
  },
  methods: {
    getPageType(id, resetFilters = true) {
      if (resetFilters) {
        // only maintain non page type specific filters
        this.cardsFilters = {
          page_authors: this.cardsFilters?.page_authors,
          page_reviewer: this.cardsFilters?.page_reviewer,
        }
        // manual list needs resetting because it is based on page type
        this.findFieldByName(this.pageModule, 'cards_selector_manual_list').value = null
      }
      if (!id) {
        this.pageType = null
      } else {
        TemplateService.getOne(this.findFieldByName(this.pageModule, 'cards_page_type').value)
          .then((response) => {
            if (response.data.success) {
              this.pageType = response.data.result[0]
            }
          })
          .catch(() => {
            this.showErrorMessage('Failed to get page type')
          })
      }
    },
    selectedPages(moduleField, value) {
      moduleField.value = value
    },
    findFieldByName(pageModule, name) {
      return pageModule.module_value_fields.find((item) => item.field.name === name)
    },
  },
}
</script>
