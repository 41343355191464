const state = {
  notifications: [],
  uniqueKey: Math.random().toString(36).slice(2),
  environmentName: '',
  environmentColor: '',
  engimaEnv: {
    local: 'dev.',
    experiment: 'dev',
    production: '',
    dev: 'dev.',
    staging: 'prelive.',
  },
  baseModelSelectLoadedPage: [],
  previewImage: null,
}

const getters = {
  getNotifications: (state) => {
    return state.notifications
  },
  getBaseModelSelectLoadedPage: (state) => {
    return state.baseModelSelectLoadedPage
  },
}

const actions = {
  addNotification({ commit }, value) {
    commit('addNotification', value)
  },
  removeNotification({ commit }, index) {
    commit('removeNotification', index)
  },
  removeNotificationById({ commit }, index) {
    commit('removeNotificationById', index)
  },
  setEnvironmentName({ commit }, value) {
    commit('setEnvironmentName', value)
  },
  setEnvironmentColor({ commit }, value) {
    commit('setEnvironmentColor', value)
  },
  setBaseModelSelectLoadedPage({ commit }, value) {
    commit('setBaseModelSelectLoadedPage', value)
  },
}

const mutations = {
  addNotification(state, value) {
    state.notifications.push(value)
  },
  removeNotification(state, index) {
    state.notifications.splice(index, 1)
  },
  removeNotificationById(state, id) {
    state.notifications.some((notification, key) => {
      if (notification.id === id) {
        state.notifications.splice(key, 1)
        return
      }
    })
  },
  setEnvironmentName(state, value) {
    state.environmentName = value
  },
  setEnvironmentColor(state, value) {
    state.environmentColor = value
  },
  setBaseModelSelectLoadedPage(state, value) {
    if (!value) {
      state.baseModelSelectLoadedPage = []
      return
    }
    const findIndex = state.baseModelSelectLoadedPage.findIndex((val) => val.id === value.id)
    if (findIndex === -1) {
      state.baseModelSelectLoadedPage.push(value)
    } else {
      state.baseModelSelectLoadedPage.splice(findIndex, 1)
    }
  },
  setPreviewImage(state, value) {
    state.previewImage = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
