<template>
  <div v-if="section">
    <div class="card bg-white-25 pb-4">
      <div class="card-header pb-0" v-if="showCardHeader">
        <h3 class="card-title">Modules preview</h3>
      </div>
      <draggable
        filter=".dropdown"
        class="pt-4 section-modules card-body"
        :list="section.module_values"
        handle=".module-drag"
        item-key="id"
      >
        <template #item="{ element, index }">
          <module-preview
            v-bind:key="index"
            :index="index"
            :id="`section-module-preview-${index}`"
            :page-module="element"
            :allowModuleDelete="allowModuleDelete"
            :openModuleValues="openModuleValues"
            :moduleOwnerType="moduleOwnerType"
            :siteId="siteId"
            :affiliateId="affiliateId"
            :marketId="marketId"
            @addModule="(idx) => moduleDropdownClick(key)"
            @editModule="(idx) => $emit('editModule', idx)"
            ref="pageModule"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>
<script>
import ModulePreview from '@organisms/cms/modules/module-preview'
import Draggable from 'vuedraggable'
import MultiSelect from 'vue-multiselect'

export default {
  components: {
    ModulePreview,
    Draggable,
    MultiSelect,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    allowModules: {
      type: Boolean,
      default: () => true,
    },
    allowPrefilledModules: {
      type: Boolean,
      default: () => true,
    },
    allowModuleDelete: {
      type: Boolean,
      default: () => true,
    },
    openModuleValues: {
      type: Boolean,
      default: () => false,
    },
    moduleOwnerType: {
      type: String,
      required: false,
    },
    moduleOwnerId: {
      required: false,
    },
    siteId: {
      required: true,
    },
    affiliateId: {
      required: true,
    },
    showCardHeader: {
      default: () => true,
    },
    marketId: {},
  },
}
</script>

<style scoped>
.section-modules > div {
  cursor: auto;
}
</style>
