<template>
  <div :class="wrapperClass" :hidden="type == 'hidden'">
    <label v-if="label">{{ $prettyLabels(label) }}</label>

    <span v-if="required">&nbsp;*&nbsp;</span>

    <div class="d-inline">
      <template v-if="options.length > 0 && !readOnly">
        <a class="select-all text-muted ml-2" v-if="max === 0" v-on:click="selectAll">select all</a>

        <span class="text-muted ml-1 mr-1">/</span>

        <a class="deselect-all text-muted" v-on:click="deselectAll">deselect all</a>
      </template>
    </div>

    <div class="dropdown" :class="{ show: showDropdown }" v-click-outside-dropdown="closeDropdown">
      <div
        class="multiselect__arrow"
        :class="{ active: showDropdown }"
        :style="readOnly ? 'display: none' : ''"
      ></div>

      <div
        @click="openDropdown"
        class="form-control-multi-select nested-dropdown"
        v-if="options.length > 0 || !loadOnMount"
      >
        <span v-if="!showDropdown && activeOptions.length === 0" class="placeholder">
          {{ computedPlaceholder }}
        </span>

        <span
          v-if="!firefoxBrowser && isEdit"
          @click.stop="copyTags(activeOptions)"
          class="d-flex justify-content-end mr-4 text-muted copy-btn"
        >
          <i class="uil uil-copy"></i>
        </span>

        <template v-if="options.length > 0 && activeOptions.length > 0">
          <div class="options d-block selected-active-option">
            <span
              v-for="(option, key) in activeOptions"
              :key="key"
              :id="option.id"
              v-bind:class="{
                'disabled-option': option.active == 0
              }"
            >
              <!-- Country Code -->
              {{
                option.parent_id && options.find(m => m.id == option.parent_id).code
                  ? options.find(m => m.id === option.parent_id).code + ' - '
                  : ''
              }}
              <!-- Name -->
              {{ $prettyLabels(Object.hasOwn(option, selectLabel) ? option[selectLabel] : option) }}
              <i
                aria-hidden="true"
                tabindex="1"
                class="close cursor-pointer"
                :style="readOnly ? 'display: none' : ''"
                @click.stop="removeOption(option)"
              ></i>
            </span>
          </div>
        </template>

        <input
          type="text"
          placeholder="Select one or multiple options..."
          v-model="search"
          v-show="showDropdown"
          class="w-100"
          ref="search-country"
          @keypress.enter.exact.stop.prevent="enterSelectItem()"
        />

        <ul class="dropdown-menu">
          <li v-if="loading">Loading ...</li>

          <li
            class="pl-3 text-truncate list-item-custom"
            v-for="(parent, key) in getOptionItems.filter(item => {
              // searching wasn't returning the children bcs they were filtered out, this fixes it
              return search.length ? item : item.parent_id === null
            })"
            :key="key"
            v-bind:class="{
              active: activeOptions.find(item => item.id == parent.id),
              'disabled-option': parent.active == 0
            }"
          >
            <div
              class="checkbox d-flex align-items-center list-item-parent"
              :class="{ 'disable-hover-style': !!options.find(m => m.parent_id === parent.id) }"
            >
              <!-- parent.name + '-' +parent.id becasue having the id only wasn't working for certain fields that had single numbers -->

              <input
                type="checkbox"
                :id="field + '-' + parent.name + '-' + parent.id"
                :value="parent.id"
                v-model="selectedOptions"
                @change.stop.prevent="onChange($event, parent.id)"
              />

              <label :for="field + '-' + parent.name + '-' + parent.id" class="optionLabel nested-checkbox">
                {{ $prettyLabels(Object.hasOwn(parent, selectLabel) ? parent[selectLabel] : parent) }}
              </label>
            </div>

            <ul class="mt-2 list-unstyled" v-if="options.find(m => m.parent_id === parent.id)">
              <li
                class="mx-3 pl-3 text-truncate list-item-child"
                v-for="(child, sKey) in parent.children"
                :key="sKey"
                v-bind:class="{
                  active: activeOptions.find(item => item.id == child.id),
                  'disabled-option': child.active == 0
                }"
              >
                <div class="checkbox d-flex align-items-center">
                  <!-- child.name + '-' +child.id becasue having the id only wasn't working for certain fields that had single numbers -->

                  <input
                    type="checkbox"
                    :id="field + '-' + child.name + '-' + child.id"
                    :value="child.id"
                    @change.stop.prevent="onChange($event, child.id, false)"
                    v-model="selectedOptions"
                  />

                  <label :for="field + '-' + child.name + '-' + child.id" class="optionLabel nested-checkbox">
                    {{ parent.code ? parent.code + ' - ' : ''
                    }}{{ $prettyLabels(Object.hasOwn(child, selectLabel) ? child[selectLabel] : child) }}
                  </label>
                </div>
              </li>
            </ul>
          </li>

          <li v-if="getOptionItems.length === 0 && !loading">No matching records</li>
        </ul>
      </div>

      <div v-else class="form-control text-muted">
        <template v-if="loading">
          <span>Loading ...</span>
        </template>

        <template v-else>
          <span>No {{ noOptionsPlaceholder }} available</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import GeneralService from '@services/GeneralService'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    selectLabel: {
      type: String,
      default: 'name'
    },
    trackByOption: {
      type: String,
      default: () => 'id'
    },
    field: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'list-many'
    },
    wrapperClass: {
      default: () => 'form-group'
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    notifiedByField: {
      type: String,
      default: ''
    },
    loadOnMount: {
      type: Boolean,
      default: false
    },
    noOptionsPlaceholder: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    disableCopy: {
      type: Boolean,
      default: false
    }
  },
  // mounted() {

  // },
  created() {
    this.isEdit = this.$route?.params && this.$route.params.id
    this.firefoxBrowser = navigator.userAgent.indexOf('Firefox') != -1
    if ((this.path && this.loadOnMount) || (this.value && this.value.length > 0)) {
      this.getOptions()
    }
  },
  directives: {
    clickOutsideDropdown: {
      mounted: function(el, binding) {
        el.clickOutsideEvent = function(event) {
          // Here we'll check that the click was outside the element and call method
          if (!(el === event.target || el.contains(event.target))) {
            // And if it did, call method provided in attribute value
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  methods: {
    enterSelectItem() {
      const option =
        this.getOptionItems[0].children.length == 1
          ? this.getOptionItems[0].children[0]
          : this.getOptionItems[0]

      const idxInSelectedOptions = this.selectedOptions.findIndex(id => id == option.id)
      this.onChange({ target: { checked: idxInSelectedOptions == -1 } }, option.id, option.parent_id == null)
      this.search = ''
    },
    async selectAll() {
      await (this.path && !this.loadOnMount && this.options.length == 0 && this.getOptions())
      this.selectedOptions = this.options.map(n => n.id)
      this.activeOptions = this.options.filter(n => n.parent_id === null)
      this.$emit('sync', this.field, this.activeOptions, this.trackBy())
    },
    deselectAll() {
      this.selectedOptions = []
      this.activeOptions = []
      this.$emit('sync', this.field, this.activeOptions, this.trackBy())
    },
    removeOption(option) {
      const idx = this.selectedOptions.findIndex(n => n == option.id)

      if (idx !== -1) {
        // onChange will handle parents and splice will handle everything else
        this.selectedOptions.splice(idx, 1)
        this.onChange({ target: { checked: false } }, option.id, option.parent_id == null)
      }
    },
    closeDropdown() {
      this.showDropdown = false
      this.search = ''
    },
    openDropdown() {
      // no need to call this if the dropdown is already open (event bubbling)
      this.path && !this.loadOnMount && this.options.length == 0 && this.getOptions()
      if (this.showDropdown) {
        return
      }
      this.showDropdown = true

      if (this.selectedOptions.length) {
        let opt = this.options.filter(n => this.selectedOptions.includes(n.id))
        const children = opt
          .filter(n => n.children.length > 0)
          .map(n => n.children.map(n => n.id))
          .flat()

        this.selectedOptions = [...this.selectedOptions, ...children]
      }
      setTimeout(() => {
        this.$refs['search-country'].focus()
      }, 500)
    },
    getOptions(params = {}) {
      this.loading = true
      if (params) {
        this.parameters = { ...this.parameters, ...params }
      }
      GeneralService.fetchItems(this.path, this.parameters).then(response => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
        } else {
          this.options =
            response.data.result.length > 0 &&
            response.data.result.map(item => {
              return item
            })

          if (this.value || this.activeOptions.length > 0) {
            this.setOptionsFromValues(response.data.result)
          }
          this.loading = false

          this.$emit('sync', this.field, this.activeOptions, this.trackBy())
        }
      })
    },
    setActiveOptions(checked, id) {
      const optionIndex = this.activeOptions.findIndex(n => n.id == id)
      if (checked) {
        if (optionIndex === -1) {
          // Check if not already added
          this.activeOptions.push(this.options.find(n => n.id == id))
        }
        // Remove all children of this parent from activeOptions
        this.activeOptions = this.activeOptions.filter(n => n.parent_id !== id)
      } else {
        if (optionIndex !== -1) {
          // Check if exists, then remove
          this.activeOptions.splice(optionIndex, 1)
        }
      }
    },

    onChange($event, id, fromParent = true) {
      const childrenOfParent = this.options.filter(n => n.parent_id == id).map(n => n.id)
      if (fromParent) {
        if (this.options.find(n => n.id == id).parent_id == null) {
          const missingChildren = childrenOfParent.filter(n => !this.selectedOptions.includes(n))
          this.setActiveOptions($event.target.checked, id)
          if ($event.target.checked) {
            // Push missing children and id if they're not already selected
            missingChildren.forEach(childId => {
              if (!this.selectedOptions.includes(childId)) {
                this.selectedOptions.push(childId)
              }
            })
            if (!this.selectedOptions.includes(id)) {
              this.selectedOptions.push(id)
            }
          } else {
            this.selectedOptions = this.selectedOptions.filter(n => !childrenOfParent.includes(n) && n !== id)
          }
        }
      } else {
        // clicked on child
        const child = this.options.find(n => n.id == id)
        const parentId = child.parent_id
        const siblings = this.options.filter(n => n.parent_id === parentId)

        if ($event.target.checked) {
          if (!this.selectedOptions.includes(id)) {
            this.selectedOptions.push(id)
          }
          this.setActiveOptions(true, id)
          const allSiblingsSelected = siblings.every(n => this.selectedOptions.includes(n.id))
          if (allSiblingsSelected && !this.selectedOptions.includes(parentId)) {
            this.selectedOptions.push(parentId)
            this.setActiveOptions(true, parentId)
          }
        } else {
          this.selectedOptions = this.selectedOptions.filter(n => n !== id)
          this.setActiveOptions(false, id)
          if (this.selectedOptions.includes(parentId)) {
            this.selectedOptions = this.selectedOptions.filter(n => n !== parentId)
            this.setActiveOptions(false, parentId)
            siblings
              .filter(n => n.id !== id && this.selectedOptions.includes(n.id))
              .forEach(sib => {
                if (!this.activeOptions.find(option => option.id === sib.id)) {
                  this.setActiveOptions(true, sib.id)
                }
              })
          }
        }
      }
      this.$emit('sync', this.field, this.activeOptions, this.trackBy(), false, 'onChange')
    },

    copyTags(value) {
      navigator.clipboard.writeText(JSON.stringify({ path: this.path, value }))
      // Page name comes back as ex. "Operators". I'm doing this to have it "operator".
      const pageName = this.$route.matched[0].meta.name
        .toLowerCase()
        .substr(0, this.$route.matched[0].meta.name.length - 1)
      if (this.path !== 'hercules/operators/countries') {
        this.showSuccessMessage(
          `${this.label} copied to clipboard. You can paste this data in a ${this.label} field of another ${pageName}`,
          'Success',
          false,
          '5000'
        )
      } else {
        let secondLabel =
          this.label === 'Restricted countries' ? 'Blacklisted Countries' : 'Restricted countries'
        this.showSuccessMessage(
          `${this.label} copied to clipboard. You can paste this data in the ${this.label} field or a ${secondLabel} field of another ${pageName}`,
          'Success',
          false,
          '5000'
        )
      }
    },
    trackBy() {
      return this.manualOptions && this.manualOptions.length > 0
        ? this.trackByOption
          ? this.trackByOption
          : ''
        : this.trackByOption
    },
    setOptionsFromValues(data) {
      this.activeOptions.forEach(element => {
        this.selectedOptions.push(element[this.trackByOption])
      })

      if (this.value instanceof Array) {
        this.value.forEach(element => {
          if (!this.selectedOptions.includes(element[this.trackByOption])) {
            this.selectedOptions.push(element[this.trackByOption])
          } else {
            this.selectedOptions.push(element)
          }
        })
      } else if (this.value != null) {
        if (!this.selectedOptions.includes(this.value)) {
          this.selectedOptions.push(this.value)
        }
      }

      this.activeOptions = data.filter(item => {
        if (this.selectedOptions.includes(item instanceof Object ? item[this.trackByOption] : item)) {
          return item
        }
      })
      //sort for vee-validation to be correct
      this.activeOptions.sort((a, b) => {
        return (
          this.selectedOptions.indexOf(a[this.trackByOption]) -
          this.selectedOptions.indexOf(b[this.trackByOption])
        )
      })
    }
  },
  computed: {
    computedPlaceholder() {
      return this.placeholder ? this.placeholder : 'Select one or multiple options'
    },
    getOptionItems() {
      let searchLower = this.search.toLowerCase()
      let results = []

      for (let option of this.options) {
        const optionLabelLower = option[this.selectLabel].toLowerCase()
        if (!option.parent_id && optionLabelLower.includes(searchLower)) {
          results.push(option)
        }
      }

      let matchedChildren = this.options.filter(
        option => option.parent_id && option[this.selectLabel].toLowerCase().includes(searchLower)
      )

      for (let child of matchedChildren) {
        let parent = this.options.find(opt => opt.id === child.parent_id)
        if (parent && !results.some(result => result.id === parent.id)) {
          results.push(parent)
        }
        results.push(child)
      }

      results = results.filter(n => n.parent_id == null)

      results.sort((a, b) => {
        const aLabel = a[this.selectLabel].toLowerCase()
        const bLabel = b[this.selectLabel].toLowerCase()

        const startsWithA = aLabel.startsWith(searchLower)
        const startsWithB = bLabel.startsWith(searchLower)

        if (startsWithA && !startsWithB) {
          return -1
        } else if (!startsWithA && startsWithB) {
          return 1
        } else {
          return 0
        }
      })

      return results.map(n => {
        const searchMatchesChild =
          n.children &&
          n.children.some(child => child[this.selectLabel].toLowerCase().startsWith(searchLower))

        return {
          ...n,
          children: searchMatchesChild
            ? n.children.filter(child => child[this.selectLabel].toLowerCase().startsWith(searchLower))
            : n.children
        }
      })
    }
  },
  data() {
    return {
      data: this.value ? (this.value instanceof Array ? this.value : [this.value]) : [],
      selectedOptions: this.value
        ? this.value instanceof Array
          ? this.value.map(n => n.id)
          : [this.value]
        : [],
      options: [],
      activeOptions: this.value ? (this.value instanceof Array ? this.value : [this.value]) : [],
      showDropdown: false,
      loading: false,
      firefoxBrowser: false,
      isEdit: false,
      search: '',
      focused: false
    }
  }
}
</script>

<style lang="scss">
.optionLabel.nested-checkbox {
  width: 100%;
  &::before {
    top: 0 !important;
  }
}

.list-item-custom.active {
  &:hover {
    background: #f6f6fb !important;
    color: #17182f;
  }
  background: #f3f3f3 !important;
  color: #17182f;
}

.list-item-custom:has(.disable-hover-style).active {
  padding-bottom: 0 !important;
  &:hover {
    background: #f3f3f3 !important;
  }
}

.list-item-custom:has(> .disable-hover-style) {
  &:hover {
    background: white !important;
    &.list-item-parent {
      .optionLabel {
        background: #f3f3f3 !important;
      }
    }
  }
}

.disable-hover-style {
  padding-left: 0.25rem !important;
  vertical-align: middle !important;

  input {
    margin-left: 0.9em !important;
  }
  &:hover {
    color: #6e6e84 !important;
  }
  label:hover {
    background: #f6f6fb;
    color: #17182f;
  }
}

.list-item-child {
  padding: 1rem 0;
  padding-left: 0.25rem;
  &:hover {
    background: #f6f6fb !important;
    color: #17182f;
  }
}

.list-item-child.active {
  &:hover {
    background: #f6f6fb !important;
    color: #17182f;
  }
  background: #f3f3f3 !important;
  color: #17182f;
}
</style>

<style lang="scss" scoped>
.dropdown {
  cursor: pointer;
}

.dropdown.disabled {
  cursor: not-allowed;
}

.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.dropdown .dropdown-menu.show li.active {
  background-color: #f3f3f3;
}

.dropdown .dropdown-menu.show li:hover {
  background-color: #f6f6fb;
}

.dropdown .dropdown-menu li span {
  opacity: 0.5;
}

.form-control-multi-select {
  background-color: #f1f2f9;
  border-color: #f1f2f9;
  padding: 11px 18px 11px 8px;
  font-weight: 500;
  min-height: 44px;
  border-radius: 4px;
}

.dropdown.disabled .form-control-multi-select {
  background-color: #e3e6ef;
  border-color: #e3e6ef;
}

.form-control-multi-select:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

.form-control-multi-select span.placeholder {
  margin-left: 8px;
  pointer-events: none;
  opacity: 0.5;
}

.form-control-multi-select .options span {
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 16px;
  background-color: white;
  margin: 5px 10px;
  margin-left: 0;
  color: #47596d;
  line-height: 30px;
  padding: 1px 16px;
}

.form-control-multi-select .options span i {
  position: unset;
  height: 20px;
  margin-left: 5px;
}

.form-control-multi-select input {
  display: inline-block;
  border: none;
  background: transparent;
  opacity: 0.7;
  padding-left: 8px;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.dropdown .dropdown-menu.show li.active-item,
.dropdown .dropdown-menu.show li.active-item-remove {
  background-color: #f6f6fb;
}

.active-item::after,
.active-item-remove:after {
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 20px;
}

.active-item::after {
  content: 'Press enter to select';
}

.active-item-remove::after {
  content: 'Press enter to remove';
}

.disabled-option {
  color: #ced0dd !important;
}

.disabled-option span {
  color: #6e6e84;
}

.copy-btn {
  width: fit-content;
  float: right;
  padding: 2px;
  &:active {
    background-color: white;
    border-radius: 4px;
  }
}
</style>
