<template>
  <div>
    <div class="modal modal-xlarge" ref="modal" :class="{ show: visible }" v-click-outside="close">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header d-block pt-5 pb-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="modal-title">Media Library</h5>
              <div class="ml-auto mr-2 d-flex align-items-center">
                <base-checkbox
                  v-if="activeTab === 'specific'"
                  label="Watermarked"
                  class="mr-3 pt-3"
                  v-model="filterWatermarked"
                  :switch="true"
                />
                <base-select
                  :capitalizeLabel="false"
                  style="width: 200px"
                  :clearable="true"
                  v-if="activeTab == 'specific'"
                  :clearableAbsolutePositioned="true"
                  wrapperClass="form-group mb-0 position-relative"
                  path="hercules/globals/sites?orderBy=name&ascending=1"
                  :loadOnMount="true"
                  v-model="selectedSiteId"
                  placeholder="Select a site"
                  selectLabel="name"
                  :allowNullOption="true"
                  :removeActiveText="true"
                  trackBy="id"
                  optionLabelKey="name"
                  @update:modelValue="refreshAssets"
                />
                <base-select
                  style="width: 200px"
                  v-if="activeTab == 'specific'"
                  :clearable="true"
                  :clearableAbsolutePositioned="true"
                  wrapperClass="form-group mb-0  ml-3 position-relative"
                  path="hercules/globals/markets?orderBy=label&ascending=1"
                  :loadOnMount="true"
                  :capitalizeLabel="false"
                  v-model="selectedMarketId"
                  placeholder="Select a market"
                  selectLabel="label"
                  :allowNullOption="true"
                  :removeActiveText="true"
                  optionLabelKey="label"
                  @update:modelValue="refreshAssets"
                />
              </div>
              <div class="d-flex align-items-center ml-2">
                <asset-search ref="search" @searchFilter="filter => searchAssets(filter)" />
                <button class="btn btn-info mr-2 ml-3" @click="() => openImageModal(true)">
                  <i class="uil uil-image-upload"></i> Add new
                </button>
              </div>
              <button class="close p-0 m-0" v-on:click="close"></button>
            </div>
            <div>
              <div class="nav nav-tabs">
                <div class="nav-item" v-if="!isStandardised || siteSpecific">
                  <button
                    @click.stop.prevent="() => (activeTab = 'global')"
                    class="nav-link"
                    :class="{ active: activeTab == 'global' }"
                  >
                    General
                  </button>
                </div>
                <div class="nav-item" v-if="siteSpecific">
                  <button
                    @click.stop.prevent="() => (activeTab = 'specific')"
                    class="nav-link"
                    :class="{ active: activeTab == 'specific' }"
                  >
                    Site Specific
                  </button>
                </div>
                <div class="nav-item" v-if="isStandardised || siteSpecific">
                  <button
                    @click.stop.prevent="() => (activeTab = 'standardised')"
                    class="nav-link"
                    :class="{ active: activeTab === 'standardised' }"
                  >
                    Standardised
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body loading-opacity" v-bind:class="{ 'is-loading': loading }">
            <div class="row">
              <div class="col-9 pr-0">
                <ul class="d-flex flex-x5 list-unstyled asset-list mb-0">
                  <li
                    v-for="(asset, index) in assets"
                    v-bind:key="index"
                    v-on:click="selectAsset(asset)"
                    v-on:dblclick="addAsset(asset)"
                    class="pb-2 pt-0 align-content-center"
                    v-bind:class="[selectedAsset && asset.id === selectedAsset.id && 'selected']"
                  >
                    <base-asset :asset="asset" />
                  </li>
                </ul>
                <div class="container row justify-content-center pb-0 mb-0 pr-4 mt-2">
                  <pagination
                    ref="pagination"
                    v-if="assetCount > limit"
                    :page-count="pages"
                    :activePage="activePage"
                    :clickHandler="paginateClick"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    container-class="pagination col-9 mb-0 mt-2"
                  >
                  </pagination>
                </div>
              </div>
              <asset-details
                ref="assetDetail"
                :buttonText="buttonText"
                @insert="addAsset"
                @clone="cloneImage"
                @openImageModal="openImageModal"
                containerClass="col pl-0"
                :asset="selectedAsset"
                :activeTab="activeTab"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <asset-image-modal
      :as="showCloneCreateModal"
      @closeModal="() => (showCloneCreateModal = false)"
      @resetAndSelect="resetAndSelect"
      :asset="selectedAsset"
      ref="imageModal"
      :selected-site-id="selectedSiteId"
    />
  </div>
</template>

<script>
import AssetsService from '@services/AssetsService'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseTag from '@atoms/fields/base-tag.vue'
import BaseAsset from '@atoms/fields/base-asset'
import Pagination from '@atoms/pagination/pagination'
import AssetDetails from '@molecules/media-library/asset-details'
import AssetSearch from '@molecules/media-library/asset-search'
import AssetImageModal from '@molecules/media-library/asset-image-modal.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox'

const GLOBAL_TAB = 'global'
const SITE_SPECIFIC_TAB = 'specific'
const STANDARDISED_LOGO_TAB = 'standardised'

export default {
  data() {
    return {
      searchFilter: {},
      assetCount: null,
      assets: [],
      selectedAsset: null,
      limit: 40,
      loading: false,
      activePage: 1,
      selectedSiteId: null,
      selectedMarketId: null,
      showCloneCreateModal: false,
      activeTab: 'global',
      filterWatermarked: false
    }
  },
  components: {
    BaseInput,
    BaseSelect,
    Pagination,
    BaseTag,
    BaseAsset,
    AssetDetails,
    AssetSearch,
    AssetImageModal,
    BaseCheckbox
  },
  mounted() {
    document.getElementById('modal') && document.getElementById('modal').appendChild(this.$el)
  },
  watch: {
    activeTab: function() {
      this.$nextTick(() => {
        this.activePage = 1
        this.filterWatermarked = false
        this.refreshAssets()
      })
    },
    filterWatermarked: function() {
      this.refreshAssets()
    }
  },
  computed: {
    isStandardised() {
      return this.$store.state.media.standardised
    },
    preSelectedImage() {
      return this.$store.state.media.selectedAsset
    },
    buttonText() {
      return this.preSelectedImage ? 'Update image' : 'Insert image'
    },
    visible() {
      return this.$store.state.media.visibility
    },
    siteSpecific() {
      return this.$store.state.media.siteSpecific
    },
    storeSiteId() {
      return this.$store.state.media.siteId
    },
    storeMarketId() {
      return this.$store.state.media.marketId
    },
    pages() {
      return Math.ceil(this.assetCount / this.limit)
    }
  },
  methods: {
    async show(assetId) {
      if (assetId) {
        const id = Object.keys(assetId).length ? assetId.id : assetId
        const existing = await AssetsService.getOne(id)
        if (existing) {
          this.selectedAsset = existing.data.result[0]
        }
      }
      if (this.selectedAsset?.site_id) {
        this.activeTab = SITE_SPECIFIC_TAB
      } else if (this.selectedAsset?.standardised === 1 || this.isStandardised) {
        this.activeTab = STANDARDISED_LOGO_TAB
      } else {
        this.activeTab = GLOBAL_TAB
      }

      this.selectedSiteId = this.selectedAsset?.site_id || null
      this.selectedMarketId = this.selectedAsset?.market_id || null

      // preselect based on store settings
      if (!this.selectedSiteId) {
        this.selectedSiteId = this.storeSiteId
        this.selectedMarketId = this.storeMarketId
      }
      this.$store.dispatch('media/setVisibility', true)

      this.refreshAssets()
      // }
    },
    resetAndSelect(asset) {
      this.selectedAsset = asset
      this.selectedMarketId = asset.market_id
      this.selectedSiteId = asset.site_id
      this.activePage = 1
      if (asset.site_id) {
        this.activeTab = SITE_SPECIFIC_TAB
      } else if (Number(asset.standardised) === 1) {
        this.activeTab = STANDARDISED_LOGO_TAB
      } else {
        this.activeTab = GLOBAL_TAB
      }

      this.refreshAssets()
    },
    paginateClick(page) {
      this.activePage = page
      this.refreshAssets()
    },
    cloneImage() {
      let clonedAsset = { ...this.selectedAsset }
      if (this.siteSpecific) {
        clonedAsset = {
          ...clonedAsset,
          ...{
            site_id: this.selectedSiteId,
            market_id: this.selectedMarketId,
            filename: clonedAsset.raw_filename
          }
        }
      }
      this.$refs.imageModal.shouldClone = true
      this.$refs.imageModal.show(clonedAsset)
    },
    openImageModal() {
      this.$refs.imageModal.show({
        src: null,
        alt: '',
        title: '',
        filename: null,
        site_id: this.selectedSiteId,
        market_id: this.selectedMarketId,
        url: null,
        watermarked: false
      })
    },
    searchAssets(filter) {
      this.searchFilter = Object.assign({}, this.searchFilter, filter)
      this.activePage = 1
      this.refreshAssets()
    },
    checkboxClicked(value) {
      this.watermarked = Boolean(value)
      this.checkboxIsClicked = true
      this.refreshAssets()
    },
    async refreshAssets() {
      this.value = true
      this.loading = true
      let data = {
        with: ['tags', 'site', 'market'].join(','),
        page: this.activePage,
        limit: this.limit,
        orderBy: 'id',
        site_id: null,
        market_id: null,
        ascending: 0,
        standardised: 0,
        is_not_null: []
      }

      if (this.filterWatermarked) {
        data.watermarked = 1
      }

      if (this.selectedAsset) {
        // only preSelect asset if tabs match (based on site id)

        let tabsMatch = false

        if (Number(this.selectedAsset?.standardised) === 1 || this.isStandardised) {
          tabsMatch = this.activeTab === STANDARDISED_LOGO_TAB
        } else if (this.selectedAsset?.site_id) {
          tabsMatch = this.activeTab === SITE_SPECIFIC_TAB
        } else {
          tabsMatch = this.activeTab === GLOBAL_TAB
        }

        if (tabsMatch && this.activePage === 1) {
          data['pre_select'] = this.selectedAsset.id
        }
      }

      if (this.activeTab === SITE_SPECIFIC_TAB) {
        if (this.selectedSiteId) {
          data.site_id = this.selectedSiteId
        } else {
          data['is_not_null'].push('site_id')
        }
        if (this.selectedMarketId) {
          data.market_id = this.selectedMarketId
        } else {
          data['is_not_null'].push('market_id')
        }
      } else {
        data['is_null'] = ['site_id', 'market_id']

        if (this.activeTab === 'standardised') {
          data.standardised = 1
        }
      }

      if (this.searchFilter) {
        data = Object.assign({}, this.searchFilter, data)
      }

      await AssetsService.get(data)
        .then(response => {
          this.assets = response.data.result
          this.assetCount = response.data.count
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    // select
    selectAsset(asset) {
      this.selectedAsset = asset
    },
    // inserts into froala editor or media field
    async addAsset(asset) {
      console.log({ asset })
      const imgData = {
        id: asset.id,
        // width: `${asset.width}px`,
        // height: `${asset.height}px`,
        width: asset.width,
        height: asset.height,
        alt: asset.alt,
        title: asset.title,
        url: asset.url
      }

      // always update the title & alt
      if (asset.site_id) {
        await AssetsService.update(asset.id, { title: asset.title, alt: asset.alt })
      }
      this.$emit('update:modelValue', imgData)
      this.close()
    },
    isEditor() {
      return window.activeEditor !== null && window.activeEditor !== undefined
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.flex-x5 > * {
  flex-basis: 12.3%;
}

ul.loading {
  align-content: center;
  justify-content: center;
  min-height: 500px;
}

.asset-list li {
  padding: 2px;
}

.asset-list li.selected img {
  border: #2196f3 solid 2px;
}

.modal-xlarge .modal-dialog {
  max-width: 1300px;
  z-index: 1030;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
