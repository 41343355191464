<template>
  <div>
    <div v-bind:class="{ 'modal-body': inModal, 'card-body': inCard }">
      <form class="form-style" v-on:submit.prevent="validateBeforeSubmit" v-on:change="somethingChanged">
        <div class="d-flex flex-wrap justify-content-between">
          <div class="flex-grow-1 mt-2" v-if="inCard">
            <h4 class="d-inline mb-0">{{ $route.meta.name }}</h4>
          </div>

          <div
            class="pr-0 d-flex flex-nowrap justify-content-between"
            v-if="checkIfEditCard"
            v-bind:class="{ 'card-footer pb-0': inCard, 'modal-footer': inModal }"
          >
            <div @click="openModalInformation()" v-if="confluenceId" class="btn btn-outline-info btn-md ml-2">
              <i class="uil uil-info-circle"></i>
              Help
            </div>

            <button
              v-if="showRemove && onRemove && methodAllowed('delete', path)"
              type="button"
              class="btn btn-danger ml-2"
              v-bind:class="{ loading: isLoading }"
              v-on:click.stop="validateBeforeDelete"
              :disabled="showColaborativeNotification === true || isDisabled"
            >
              <i class="uil uil-trash pr-2"></i>
              Delete
            </button>

            <button
              v-if="
                customCreateActionButton.action && shouldShowAccordingToRoles(customCreateActionButton.roles)
              "
              type="button"
              class="btn btn-info ml-2"
              v-on:click.stop="customCreateActionButton.action"
              v-bind:class="{ loading: isLoading }"
            >
              <i :class="customCreateActionButton.icon"></i>
              {{ customCreateActionButton.title }}
            </button>

            <button
              v-if="showSubmit && methodAllowed('put', path)"
              class="btn btn-primary ml-2"
              :disabled="showColaborativeNotification === true || isDisabled"
              v-bind:class="{ loading: isLoading }"
            >
              <i class="uil uil-check"></i>
              {{ submitLabel }}
            </button>
          </div>
        </div>

        <div class="mb-2 mt-2">
          <colaborative-edit-notification
            v-if="showColaborativeNotification"
            :route_path="$route.fullPath"
            :username="username"
          />
        </div>

        <dynamic-fields
          ref="form"
          v-bind:class="{ 'modal-form-two-column': twoColumnLayout }"
          :form-type="formType"
          :formFields="formFields"
          :filter="filter"
          :disabled="isDisabled"
          v-if="object"
          :object="object"
          :fieldClass="fieldClass"
          @notifyExtraFields="notifyExtraFields"
          :closeOnDestroy="closeOnDestroy"
          :parameters="parameters"
        />

        <div v-if="extraFields.length > 0">
          <hr />

          <div class="d-flex">
            <label class="text-muted">Extra Fields</label>

            <span
              data-tooltip="These are extra fields added from the Affiliate page."
              data-tooltip-color="danger"
              data-tooltip-position="right center"
              class="ml-2 mb-2"
            >
              <i class="uil uil-info-circle"></i>
            </span>
          </div>

          <dynamic-fields
            :siteSpecific="true"
            ref="extra_fields"
            :form-type="formType"
            :disabled="isDisabled"
            :formFields="extraFields"
            :filter="filter"
            v-if="extraFields.length > 0"
            :object="extraFieldsObject"
            :closeOnDestroy="closeOnDestroy"
            :parameters="parameters"
          />
        </div>

        <button type="submit" hidden />
      </form>
    </div>

    <div
      class="text-right"
      v-if="!checkIfEditCard"
      v-bind:class="{ 'card-footer': inCard, 'modal-footer': inModal }"
    >
      <button
        v-if="showRemove && onRemove && methodAllowed('delete', path)"
        type="button"
        class="btn btn-danger mr-3"
        v-bind:class="{ loading: isLoading }"
        v-on:click.stop="validateBeforeDelete"
        :disabled="showColaborativeNotification === true || isDisabled"
      >
        <i class="uil uil-trash pr-2"></i>
        Delete
      </button>

      <button
        v-if="customCreateActionButton.action && shouldShowAccordingToRoles(customCreateActionButton.roles)"
        type="button"
        class="btn btn-info mr-3"
        v-on:click.stop="customCreateActionButton.action"
        v-bind:class="{ loading: isLoading }"
        :disabled="showColaborativeNotification === true || isDisabled"
      >
        <i :class="customCreateActionButton.icon"></i>
        {{ customCreateActionButton.title }}
      </button>

      <button
        @click="validateBeforeSubmit"
        v-if="showSubmit && methodAllowed('put', path)"
        class="btn btn-primary"
        :disabled="showColaborativeNotification === true || isDisabled"
        v-bind:class="{ loading: isLoading }"
      >
        <i class="uil uil-check"></i>
        {{ submitLabel }}
      </button>
    </div>

    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import _ from 'lodash'
import patterns from '@constants/regex-patterns'
import InformationModal from '@molecules/cms/modals/information-modal'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification'
import DynamicFormHelper from '@mixins/DynamicFormHelper'
export default {
  components: {
    InformationModal,
    ColaborativeEditNotification
  },
  mixins: [DynamicFormHelper],
  data() {
    return {
      isDisabled: false,
      isLoading: false,
      confluenceId: 0,
      showColaborativeNotification: false
    }
  },
  computed: {
    visibleFields() {
      return this.formFields.filter(field => {
        return this.canShow(field.name, field.only_edit)
      })
    },
    checkIfEditCard() {
      return this.formType === 'edit' && this.inCard && !this.inModal
    }
  },
  props: {
    isCentered: {
      type: Boolean,
      default: () => true
    },
    inModal: {
      type: Boolean,
      default: () => false
    },
    inCard: {
      type: Boolean,
      default: () => false
    },
    fieldClass: {
      type: String
    },
    actionButtonsTop: {
      type: Boolean,
      default: () => false
    },
    showSubmit: {
      type: Boolean,
      default: () => true
    },
    formType: {
      required: true,
      type: String
    },
    onSubmit: {
      required: false,
      type: [Function]
    },
    onRemove: {
      required: false,
      type: Function
    },
    object: {
      required: true
    },
    extraFieldsObject: {
      default: () => null
    },
    twoColumnLayout: {
      default: () => false,
      type: Boolean
    },
    filter: {
      type: Array,
      required: false
    },
    submitLabel: {
      type: String,
      default: () => 'Submit'
    },
    path: {
      type: String
    },
    // passed to base-model-select for site_id / market_id filters
    parameters: {
      type: Object,
      default: () => {}
    },
    formFields: {
      type: Array,
      required: true,
      default: () => []
    },
    extraFields: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false,
      required: false
    },
    customCreateActionButton: {
      type: Object,
      default: () => {
        return {
          action: null,
          title: 'Primary',
          icon: 'uil uil-share-alt',
          roles: []
        }
      }
    },
    showRemove: {
      type: Boolean,
      default: () => true
    },
    removeRoles: {
      type: Array,
      default: () => []
    },
    fixedButtons: {
      type: Boolean,
      default: () => false
    },
    closeOnDestroy: {
      type: Boolean,
      default: () => true
    },
    pusherModel: {
      type: String,
      default: () => {
        return 'default'
      }
    }
  },
  created() {
    patterns.dynamic_form.some(
      function(item) {
        let match = this.$route.path.match(item.regex)
        if (match && match.length > 0 && match[0] === this.$route.path) {
          this.confluenceId = item.id
          return true
        }
      }.bind(this)
    )
  },
  beforeMount() {
    this.isDisabled = this.disabled
    // need to be set to null by default, otherwise it will show values from other components
    this.$store.dispatch('media/setSiteAndMarket', {
      site_id: null,
      market_id: null
    })
  },
  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    getValue(field) {
      if (field.type === 'label') {
        return this.findInObject(field.value_key.split('.'), this.object)
      }

      // object is empty (create) and field has a default value
      if (Object.entries(this.object).length === 0 && field.create_defaults) {
        return field.create_defaults
      }

      // value should be retreive from a specific key
      if (field.value_key) {
        const valueKeys = field.value_key.split('.')
        const value = this.findInObject(valueKeys, this.object)
        const trackBy = field.trackBy ? field.trackBy : 'id'
        const selectLabel = field.selectLabel ? field.selectLabel : 'name'

        // build an object that is useable by the multi-select component
        let valueObject = {}
        valueObject[trackBy] = this.object[field.name]
        valueObject[selectLabel] = value

        return valueObject
      }

      return this.object[field.name]
    },
    syncValues(field, value, key = 'id') {
      if (value) {
        if (key === 'tag-input') {
          // one item (list-one)
          this.object[field] = value
        } else if (Array.isArray(value)) {
          this.object[field] = value.map(item => item[key])
        } else if (_.isObject(value)) {
          // one item (list-one)
          this.object[field] = value[key]
        } else {
          this.object[field] = value
        }
      } else if (value === null) {
        // this is used for removing image from dropzone, this nulls the logo_field_id
        this.object[field] = null
      }
    },
    process(object) {
      return new Promise(resolve => resolve(object))
    },
    canShow(field, edit_only) {
      if (this.formType !== 'edit' && edit_only) {
        return false
      }
      return _.indexOf(this.filter, field) === -1
    },
    disableEdit(disable) {
      this.isDisabled = disable

      if (this.$refs.form) {
        this.$refs.form.disableEdit(disable)
      }

      if (this.$refs.extra_fields) {
        this.$refs.extra_fields.disableEdit(disable)
      }
    },
    validateBeforeDelete() {
      if (this.showColaborativeNotification) {
        return
      }
      this.onRemove()
    },
    validateBeforeSubmit() {
      if (this.showColaborativeNotification) {
        return
      }

      if (this.$refs.form && Object.hasOwn(this.$refs.form.object, 'extra_fields')) {
        delete this.$refs.form.object.extra_fields
      }

      if (this.extraFields.length > 0) {
        this.$emit('updateExtraFields', this.$refs.extra_fields.object)
      }

      this.$nextTick(() => {
        this.removeBeforeUnloadEvent()
        this.isLoading = true
        this.onSubmit()
      })
    },
    notifyFields(ref) {
      this.$refs.form.$refs[ref] && this.$refs.form.$refs[ref][0].clearAndEnable()
      if (this.$refs.extra_fields) {
        this.$refs.extra_fields[ref][0].clearAndEnable()
      }
    },
    notifyExtraFields(method) {
      this.$emit('notifyExtraFields', method)
    },
    shouldShowAccordingToRoles(roles) {
      return roles.length == 0 ? true : this.hasRoles(roles)
    },
    loading(loading) {
      this.isLoading = loading
    }
  }
}
</script>
