<template>
  <div class="">
    <sidebar-filter ref="sidebar" :fromSchema="false" />
    <breadcrumb-edit ref="edit" />
  </div>
</template>

<script>
import SidebarFilter from '@molecules/cms/sites/sidebar-filter.vue'
import BreadcrumbEdit from '@organisms/cms/sites/breadcrumb/edit.vue'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      showBulkEdit: false,
    }
  },
  components: { SidebarFilter, BreadcrumbEdit },
}
</script>
