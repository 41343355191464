<template>
  <div>
    <data-table
      model="widgets/odds/odds"
      service-file="WidgetOddsService"
      path="hercules/widgets/odds?with=odds_type,odds_sub_type,sport"
      :rowActionButtons="rowActions"
      :show-edit-modal="false"
      :columns="columns()"
      :column-templates="columnTemplates"
      :applyTableSize="false"
      class="widgets-table"
    />
    <preview-widget-modal
      ref="modal"
      :title="'Preview Odds Widget'"
      v-if="showModal"
      @closeModal="showModal = false"
    />
  </div>
</template>

<script>
import BasicOptions from '@mixins/BasicOptions'
import TableColumnTemplate from '@constants/table-column-template'
import PreviewWidgetModal from '@molecules/widgets/preview-widget-modal.vue'
import SportsService from '@services/SportsService'

export default {
  components: { PreviewWidgetModal },
  data() {
    return {
      loading: false,
      rowActions: [
        {
          action: this.copyToken,
          title: 'Copy Token',
          icon: 'uil uil-copy',
          class: 'btn-info',
        },
        {
          action: this.previewWidget,
          title: 'Preview',
          icon: 'uil uil-eye',
          class: `btn-secondary`,
        },
      ],
      sports: [],
      columnTemplates: {},
      showModal: false,
    }
  },
  created() {
    this.setTitle()
    this.getSports()
  },
  methods: {
    previewWidget(row) {
      const env = this.$store.state.app.engimaEnv[this.$store.state.app.environmentName] || ''
      let oddsType = row.odds_type.name
      oddsType ? (oddsType = oddsType.toLowerCase()) : (oddsType = '')
      this.showModal = true
      this.$nextTick(() => {
        this.$refs.modal.show(
          `https://${env}sportswidget.gigmedia.com/${oddsType}?token=${row.token}`,
          row.affiliate_id
        )
      })
    },
    copyToken(row) {
      navigator.clipboard
        .writeText(row.token)
        .then(() => {
          this.showSuccessMessage('Token copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
    getSports() {
      SportsService.get()
        .then((response) => {
          this.sports = response.data.result
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    columns() {
      return [
        { field: 'site_name', type: 'text', label: 'Site', filterable: true },
        {
          field: 'odds_type.name',
          type: 'text',
          label: 'Type',
          filterable: true,
          template: TableColumnTemplate.ODDS_STATUS,
        },
        {
          field: 'odds_sub_type.name',
          type: 'text',
          label: 'Sub type',
          filterable: true,
          template: TableColumnTemplate.ODDS_STATUS,
        },
        { field: 'title', type: 'text', filterable: true },
        {
          field: 'sport_id',
          label: 'Sport',
          type: 'text',
          class: 'col-sports',
          search_key: 'sport_id',
          advancedFilter: false,
          path: 'hercules/sports/sports?active=1',
          optionLabelKey: 'name',
          showModalAdvanced: false,
          basicFilterType: 'select',
          sortable: false,
        },
        {
          field: 'details_label',
          type: 'text',
          label: 'Details',
          filterable: true,
          exportable: false,
          template: TableColumnTemplate.BADGES,
        },
        Object.assign({}, BasicOptions.booleanColumn('geo_located'), {
          template: TableColumnTemplate.BOOLEAN,
          label: 'Geo targeted',
        }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
  watch: {
    sports() {
      const obj = this
      this.columnTemplates = Object.assign({
        sport_id: function (row) {
          const sport = obj.sports ? obj.sports.filter((sport) => sport.id === row.sport_id)[0] : null
          return sport ? sport.name : ''
        },
      })
    },
  },
}
</script>
