<template>
  <div class="item" ref="scroll-item" :value="source" @click="onClick(source)" :class="computeClass">
    <div class="id text-truncate" slot="reference">
      {{
        prettifyLabels(
          Object.hasOwn(source, optionLabelKey)
            ? (extraOptionLabelKey ? source[extraOptionLabelKey] + ' > ' : '') + source[optionLabelKey]
            : source.name
        )
      }}
    </div>
  </div>
</template>

<script>
import mixins from '../../../mixins/ChildToParent'

export default {
  name: 'item',
  mixins: [mixins],
  inject: ['onChangeItem'],
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    dataFromParent: { type: String | Number },
    trackBy: String,
    pointer: { type: Number | String, default: 0 },
    optionLabelKey: String,
    extraOptionLabelKey: String,
  },
  computed: {
    computeClass(source) {
      return {
        active:
          typeof source === 'object' && source !== null
            ? this.dataFromParent === source[this.trackBy]
            : this.dataFromParent === source,
        'active-item':
          (typeof source === 'object' && source !== null
            ? this.pointer === source[this.trackBy]
            : this.pointer === source) &&
          (typeof source === 'object' && source !== null
            ? this.dataFromParent !== source[this.trackBy]
            : this.dataFromParent !== source),
        'active-item-remove':
          (typeof source === 'object' && source !== null
            ? this.pointer === source[this.trackBy]
            : this.pointer === source) &&
          (typeof source === 'object' && source !== null
            ? this.dataFromParent === source[this.trackBy]
            : this.dataFromParent === source),
      }
    },
  },
  methods: {
    prettifyLabels(label) {
      return this.$prettyLabels(label)
    },
    onClick(source) {
      this.onChangeItem(source)
    },
  },
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  flex-direction: column;
  padding: 1em;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
    color: #17182f;
  }
}

.active-item::after,
.active-item-remove:after {
  position: absolute;
  right: 10px;
}

.active,
.active-item {
  background-color: #f6f6fb !important;
  color: #17182f !important;
}
</style>
