<template>
  <div>
    <h2>Languages</h2>

    <div class="d-flex mt-4" v-if="!isLoading && !hasData">
      <div class="card col-12 col-xl-4">
        <div class="card-body">
          <div class="text-center">
            <i class="uil uil-setting hero-icon"></i>

            <h5>No link logic has been configured for this site yet</h5>

            <p>
              Start by adding rules for a
              <strong>language</strong>
            </p>

            <button class="btn btn-primary" @click="showLanguageModal">
              Configure a language
              <i class="uil uil-rocket ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-3" v-if="hasData">
      <div class="d-flex flex-column border p-3 px-3 rounded">
        <div class="d-flex">
          <span v-if="data">
            <button
              class="btn btn-secondary mr-3"
              :class="activeIndex === index && 'btn-info'"
              v-for="(record, index) in data"
              :key="index"
              @click="() => (activeIndex = index)"
            >
              {{ record.language.name }}
            </button>
          </span>

          <div class="d-flex pl-3 border-left">
            <button v-if="hasData" class="btn btn-danger" @click="removeLanguage">
              <i class="uil uil-trash-alt"></i>
            </button>

            <button class="btn btn-warning ml-2" @click="showLanguageModal">
              <i class="uil uil-plus"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <a
          :href="`/affiliates/${this.$route.params.id}/location-groups`"
          target="_blank"
          class="btn btn-secondary"
        >
          Manage location groups
          <i class="uil uil-external-link-alt ml-2"></i>
        </a>

        <button class="btn btn-primary ml-3" :class="isLoading && 'loading'" @click="save">
          Save
          <i class="uil uil-save ml-2"></i>
        </button>
      </div>
    </div>

    <div v-if="hasData">
      <h3 class="mb-3">Rules for language</h3>

      <div v-if="activeData && countries && markets && groups">
        <!-- <div v-if="data && countries && markets"> -->
        <draggable v-model="activeData.rows" element="div" handle=".row-drag">
          <template #item="{ element, index }">
            <affiliate-link-logic-row
              :countries="countries"
              :groups="groups"
              :markets="markets"
              :key="index"
              :index="index"
              :row="element"
              :rows="activeData.rows"
              @deleteRow="deleteRow(index)"
            />
          </template>
        </draggable>
        <!-- </div> -->

        <div>
          <button @click="addRow" class="btn btn-secondary">
            Add a new row
            <i class="uil uil-corner-right-down ml-2"></i>
          </button>
        </div>
      </div>
    </div>

    <affiliate-link-logic-add-modal ref="addModal" @languageAdded="addLanguage" />
  </div>
</template>

<script>
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseSelectJoostWip from '@/components/fields/base-select-joost-wip'
import AffiliateLinkLogicRow from '@pages/dms/affiliate-link-logic/affiliate-link-logic-row.vue'
import AffiliateLinkLogicAddModal from '@pages/dms/affiliate-link-logic/affiliate-link-logic-add-modal.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import Draggable from 'vuedraggable'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

export default {
  components: {
    BaseCheckbox,
    Draggable,
    BaseSelectJoostWip,
    AffiliateLinkLogicAddModal,
    AffiliateLinkLogicRow,
  },
  mounted() {
    this.setTitle(`${this.$store.state.editPage.model.website}: Link Logic`)
  },
  data() {
    return {
      data: [],
      activeIndex: 0,
      groups: [],
      markets: null,
      countries: null,
      isLoading: true,
    }
  },
  computed: {
    activeData: {
      get() {
        if (this.data === null) {
          return null
        }
        return this.data[this.activeIndex]
      },
      set(value) {
        this.data[this.activeIndex] = value
      },
    },
    hasData() {
      if (this.data === null || this.data.length === 0) {
        return false
      }
      return true
    },
  },
  beforeMount() {
    this.getData()
    this.getGroups()
    this.getMarkets()
    this.getCountries()
  },
  methods: {
    getMarkets() {
      this.$http
        .get(`hercules/globals/markets`, {
          params: { orderBy: 'label', ascending: 1 },
        })
        .then((response) => {
          if (response.data.result) {
            this.markets = response.data.result
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    getCountries() {
      this.$http
        .get(`hercules/globals/countries`, {
          params: { orderBy: 'name', ascending: 1, is_group: 0, hide_children: 0, with_no_assets: 1 },
        })
        .then((response) => {
          if (response.data.result) {
            this.countries = response.data.result
          } else {
            this.showErrorMessage('Not found')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    getGroups() {
      this.$http
        .get(`hercules/operators/affiliate-location-groups`, {
          params: { orderBy: 'name', ascending: 1, affiliate_id: this.$route.params.id, with: 'locations' },
        })
        .then((response) => {
          if (response.data.result) {
            this.groups = response.data.result
          }
        })
    },
    getData() {
      this.$http
        .get(`hercules/operators/affiliate-link-logics`, {
          params: {
            affiliate_id: this.$route.params.id,
            with: ['language', 'rows', 'rows.country', 'rows.rules.market'].join(','),
          },
        })
        .then((response) => {
          if (response.data.result.length > 0) {
            this.data = this.mapData(response.data.result)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async save() {
      this.isLoading = true
      await this.$http
        .put(`hercules/operators/affiliate-link-logics`, {
          records: this.data,
          affiliate_id: this.$route.params.id,
        })
        .then((response) => {
          this.showSuccessMessage('Rules saved')
          if (response.data.result.length > 0) {
            this.data = this.mapData(response.data.result)
          } else {
            this.data = []
          }
        })
        .finally(() => {
          this.isLoading = false
        })
        .catch(this.showUnknownErrorMessage)
    },

    showLanguageModal() {
      this.$refs.addModal.show(this.$route.params.id)
    },
    async removeLanguage() {
      const language = this.activeData.language.name
      const onlyHasOneRecord = this.data.length === 1
      const message = onlyHasOneRecord
        ? 'After deleting, the data will be saved automatically'
        : 'After deleting, you still need to save the page'
      if (await confirmDelete(`Are you sure want to delete ${language}?`, message, 'Yes')) {
        this.data.splice(this.activeIndex, 1)
        this.activeIndex = 0
        if (onlyHasOneRecord) {
          this.save()
        }
      }
    },
    addRow() {
      this.activeData.rows.push({
        affiliate_link_logic_id: this.data.id,
        country_id: null,
        affiliate_location_group_id: null,
        country: null,
        rules: [{ based_on_row: 0, affiliate_link_logic_row_id: null, market_id: null, market: null }],
        added: true,
      })
    },
    addLanguage(language) {
      if (this.data === null) {
        this.data = []
      }
      this.data.push(language)
      this.activeIndex = this.data.length - 1
      this.addRow()
    },
    deleteRow(index) {
      if (this.activeData.rows[index].added) {
        this.activeData.rows.splice(index, 1)
        return
      }
      this.activeData.rows[index].removed = !this.activeData.rows[index].removed
    },
    mapData(data) {
      data.forEach((item) => {
        item.rows.forEach((row) => {
          row.removed = false
          row.added = false
        })
      })

      return data
    },
  },
}
</script>
