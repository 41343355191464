import DevopsBackups from '@pages/devops/backups'
import DevopsCloudflare from '@pages/devops/cloudflare'
import DevopsSync from '@pages/devops/sync'
import SiteConfigs from '@pages/devops/site-configs.vue'

export default [
  {
    path: '/devops-backups',
    component: DevopsBackups,
    meta: {
      parent: 'DevOps',
      auth: true,
      name: 'Backups',
      permission: 'view.devops/backups'
    }
  },
  {
    path: '/devops-cloudflare',
    component: DevopsCloudflare,
    meta: {
      parent: 'DevOps',
      auth: true,
      name: 'Cloudflare cache',
      permission: 'view.devops/cloudflare'
    }
  },
  {
    path: '/site-configurations',
    component: SiteConfigs,
    meta: {
      parent: 'DevOps',
      auth: true,
      permission: 'view.sites/configs',
      name: 'Site Configurations'
    }
  },
  {
    path: '/devops-sync',
    component: DevopsSync,
    meta: {
      parent: 'DevOps',
      auth: true,
      permission: 'view.devops/brew',
      name: 'Sync'
    }
  }
]
