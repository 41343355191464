<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close" v-if="show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clone menu</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <dynamic-form
          :inModal="true"
          form-type="edit"
          v-if="object"
          ref="form"
          :formFields="structure.list"
          :path="structure.config.url"
          :structure="structure"
          :object="object"
          :on-submit="submit"
          submit-label="Clone"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MenuService from '@services/MenusService'
import DynamicForm from '@/components/fields/DynamicForm'
import MenuClone from '@models/MenuDataClone'
export default {
  components: { DynamicForm },
  data() {
    return {
      loading: false,
      object: null,
      structure: {},
      config: MenuClone.getConfig(),
    }
  },
  methods: {
    show(object) {
      this.structure = MenuClone.getStructure(object)
      this.object = {
        site_id: object.site_id,
        source_menu_id: object.id,
        source_market_id: object.market_id,
        title: object.title,
        short_code: object.short_code,
      }
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
    },
    submit() {
      MenuService.clone(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Menu cloned')
            this.$emit('refresh')
            this.close()
          }
          this.$refs.form.loading(false)
        })
        .catch(() => {
          this.showUnknownErrorMessage
          this.$refs.form.loading(false)
        })
    },
  },
}
</script>
