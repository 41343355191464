<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-20">
        <base-select
          :loadOnMount="false"
          optionLabelKey="name"
          ref="selectTagsType"
          v-model="tagsType"
          :customOptions="tagsTypeOptions"
          class="pr-2 pl-2 mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select'

export default {
  components: { BaseSelect },
  computed: {
    filters() {
      return {
        tags_type: this.tagsType,
      }
    }
  },
  data() {
    return {
      tagsType: {id: 'brands', name: 'Brands'},
      tagsTypeOptions: [
        { id: 'brands', name: 'Brands' },
        { id: 'channels', name: 'Channels' },
        { id: 'countries', name: 'Countries' },
        { id: 'devices', name: 'Devices' },
        { id: 'page-types', name: 'Page Types' },
        { id: 'positions', name: 'Positions' },
        { id: 'sources', name: 'Sources' },
        { id: 'units', name: 'Units' },
        { id: 'verticals', name: 'Verticals' },
        { id: 'websites', name: 'Websites' },
      ],
    }
  },
  methods: {
    getFilter() {
      return {
        tags_type: this.tagsType,
      }
    }
  },
  watch: {
    filters() {
      this.$emit('filterChanged', this.getFilter())
    }
  },
}
</script>
