<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h3>Custom Hreflangs</h3>
            <span class="text-muted d-flex align-self-center">
              <i class="uil uil-info-circle mr-2"></i>Changes made here are saved automatically
            </span>
            <div class="text-right d-flex align-items-center">
              <base-checkbox
                label="Combined"
                @update:modelValue="pageGroupCombinedChange"
                :modelValue="page.page_hreflang_combined"
                class="mr-3 mb-0"
                :readOnly="readOnly"
                tooltip="Combine normal hreflang group items with custom items"
                v-bind:class="{ loading: isLoading }"
              />
              <model-select
                :parameters="{
                  market_id: page.market_id,
                  site_id: page.site_id,
                  where_key_not: page.id,
                  status: 'active',
                  bypass_market_restrictions: true,
                }"
                :showLabel="false"
                modal-size-class="modal-lg3"
                model="page"
                ref="modelSelect"
                @valuePicked="pageSelected"
                :custom-column-templates="modelSelectColumnTemplates()"
                :custom-columns="modelSelectCustomColumns()"
              />
            </div>
          </div>
          <div class="card-body" id="seo-page-groups-body">
            <data-table
              ref="table"
              model="cms/PageCustomHreflang"
              service-file="PageCustomHreflangService"
              :enableDataExport="false"
              :path="path()"
              :showEditModal="false"
              modal-class="modal-center"
              :createDefaults="{ source_page_id: this.page.id, site_id: this.page.site_id }"
              :columnTemplates="columnTemplates()"
              :show-open-new-tab-button="true"
              :showCardHeader="false"
              :columns="columns()"
              :showCreate="false"
              :customRowClick="rowClick"
              :defaultLimit="999999"
              :enableVisibleCustomColumns="true"
              @updated-date="$emit('updated-date')"
              stateName="custom-hreflang-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="jsx">
import PageCustomHreflangService from '@services/PageCustomHreflangService'
import ModelSelect from '@molecules/fields/model-select/base-model-select'
import PagesService from '@services/PagesService'
import PageStatusBadge from '@atoms/table/cell/pages-status.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import DataTable from '@templates/table/table.vue'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
    }
  },
  components: {
    BaseCheckbox,
    PageStatusBadge,
    DataTable,
    ModelSelect,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    readOnly: {
      default: false,
    },
  },
  updated() {
    this.$emit('updated-date')
  },
  methods: {
    pageSelected(value) {
      const data = {
        source_page_id: this.page.id,
        target_page_id: value,
        site_id: this.page.site_id,
      }
      PageCustomHreflangService.post(data)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Custom hreflang added')

            this.$refs.table.search()
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    async pageGroupCombinedChange(value) {
      if (this.readOnly) return
      this.isLoading = true
      await PagesService.update({ id: this.page.id, page_hreflang_combined: value })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Hreflang group settings updated')
            this.page.page_hreflang_combined = response.data.result.page_hreflang_combined
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.isLoading = false
        })
    },
    path() {
      return `hercules/sites/page-custom-hreflangs?source_page_id=${this.page.id}&with=${[
        'child_page',
        'child_page.template',
        'child_page.market',
        'country',
      ].join(',')}&bypass_market_restrictions=true`
    },
    columns() {
      return [
        { field: 'title', type: 'text', filterable: false, sortable: false, clickable: false },
        { field: 'path', type: 'text', filterable: false, sortable: false, clickable: false },
        { field: 'language', type: 'text', label: 'Language', filterable: false, sortable: false },
        { field: 'region', type: 'text', label: 'Region code', filterable: false, sortable: false },
        {
          field: 'created_at',
          type: 'text',
          filterable: false,
          sortable: false,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          title: function (row) {
            return row.child_page.title
          },
        },
        {
          path: function (row) {
            return row.child_page.path
          },
        },
        {
          language: function (row) {
            return row.child_page.market.language_code
          },
        },
        {
          region: function (row) {
            return row.child_page.region_code
          },
        }
      )
    },
    modelSelectCustomColumns() {
      return [{ field: 'region_code', type: 'text', label: 'Region code', filterable: true }]
    },
    modelSelectColumnTemplates() {
      return {
        market: function (row) {
          return row.market ? row.market.label : ''
        },
        status: function (row) {
          return <PageStatusBadge page={row} minimal={true} />
        },
      }
    },
    rowClick(event, row) {
      window.open(`/site-pages/${row.target_page_id}`, '_blank')
    },
  },
}
</script>
