<template>
  <div v-if="migratedFromObject.length">
    <div class="d-flex align-items-center">
      <h4 class="mb-0 mr-3">Migrated from</h4>
      <button class="btn btn-success" @click="showAllinTable">Show all in table</button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" v-for="(header, key) in headers" :key="key">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(migratedToAccount, key) in migratedFromObject"
          :key="key"
          scope="row"
          @click="goToAccount(migratedToAccount.id)"
        >
          <td>{{ migratedToAccount.id }}</td>
          <td v-html="scrapable(migratedToAccount.operator.platform.scrapable)"></td>
          <td>{{ migratedToAccount.operator.platform.name }}</td>
          <td>{{ migratedToAccount.operator.name }}</td>
          <td>{{ migratedToAccount.username }}</td>
          <td><AccountStatus :row="migratedToAccount" :status="migratedToAccount.status" /></td>
          <td>{{ migratedToAccount.account_manager_name }}</td>
          <td>
            <div class="switch clickable">
              <input
                type="checkbox"
                :id="switchId(migratedToAccount.id)"
                v-model="migratedToAccount.active"
                disabled
              />
              <label :for="switchId"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import AccountStatus from '@atoms/table/cell/account-status.vue'
import Api from '@services/Api'

export default {
  components: { AccountStatus },
  mounted() {
    this.migratedFromIds()
  },
  data() {
    return {
      headers: [
        'ID',
        'Scrapable',
        'Platform',
        'Operator',
        'Username',
        'Status',
        'Account Manager Name',
        'Enabled',
      ],
      migratedFromObject: {},
      migratedIds: [],
    }
  },
  props: {
    object: {
      type: [Array, Object],
    },
  },
  methods: {
    migratedFromIds() {
      if (!this.object.migrated_from_ids || this.object.migrated_from_ids.length === 0) return

      Promise.all(
        this.object?.migrated_from_ids.map((id) =>
          Api()
            .get(`koala/v1/accounts/${id}`)
            .then((response) => response.data.result[0])
            .catch((error) => {
              console.error('Failed to fetch account for ID:', id, error)
              return null
            })
        )
      )
        .then((results) => {
          if (results.length > 0) {
            results.forEach((result) => this.migratedIds.push(result.id))
          }
          this.migratedFromObject = results.filter((result) => result != null)
        })
        .catch((error) => {
          this.showErrorMessage('Failed to process all migrated accounts')
          console.error('Overall error in fetching accounts:', error)
        })
    },
    showAllinTable() {
      let routeData = this.$router.resolve({
        path: '/koala-accounts',
        query: {
          ids: this.migratedIds.join(','),
        },
      })
      window.open(routeData.href, '_blank')
    },
    goToAccount(id) {
      let routeData = this.$router.resolve({
        path: '/koala-accounts',
        query: {
          ids: id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    switchId(id) {
      return `switch-${id}`
    },
    scrapable(scrapable) {
      if (scrapable) {
        return `<span class="badge badge-success"><i class="uil uil-check"></i></span>`
      } else {
        return `<span class="badge badge-light"><i class="uil uil-times"></i></span>`
      }
    },
  },
}
</script>
<style scoped>
.account-status-badge {
  display: inline;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
