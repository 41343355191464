import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { mergeAttributes, Node, findChildrenInRange, Tracker } from '@tiptap/core'
import CustomFigureTemplate from './CustomFigureTemplate.vue'
import { imageStyles } from '@/lib/tiptap/constants.js'
/* eslint-disable no-unused-vars */

const getResizedAttribute = (element, name) => {
  if (element.hasAttribute(name)) {
    return element.getAttribute(name)
  } else if (element.hasAttribute(`data-${name}`)) {
    return element.getAttribute(`data-${name}`)
  }
  return element.style[name]?.replace('px', '')
}

export default Node.create({
  name: 'figure',
  // inline: true,
  marks: 'link',
  group: 'block',
  content: 'text*',
  // draggable: true,
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: null,
        parseHTML: (element) => {
          return Object.keys(imageStyles).find((style) => element.classList.contains(style))
        },
        renderHTML: (attributes) => {
          return {
            class: attributes.class,
          }
        },
      },
      dir: {
        default: null,
        parseHTML: (element) => element.getAttribute('dir'),
        renderHTML: (attributes) => {
          if (attributes.dir === null || attributes.dir === 'ltr') {
            return {}
          }
          return { dir: attributes.dir }
        },
      },
      style: {
        parseHTML: (element) => {
          return Object.keys(imageStyles).find((style) => element.classList.contains(style))
        },
        renderHTML: (attributes) => {
          return {
            class: attributes.style,
          }
        },
      },
      src: {
        default: null,
        parseHTML: (element) => element.querySelector('img')?.getAttribute('src'),
      },
      resizedWidth: {
        parseHTML: (element) => {
          return getResizedAttribute(element.querySelector('img'), 'width')
        },
        renderHTML: (attributes) => {
          return {
            width: attributes.resizedWidth,
          }
        },
      },
      originalWidth: {
        parseHTML: (element) => element.querySelector('img')?.getAttribute('data-width'),
        renderHTML: (attributes) => {
          return {
            'data-width': attributes.originalWidth,
          }
        },
      },
      alt: {
        parseHTML: (element) => element.querySelector('img')?.getAttribute('alt'),
        renderHTML: (attributes) => {
          return {
            alt: attributes.alt,
          }
        },
      },
      title: {
        parseHTML: (element) => element.querySelector('img')?.getAttribute('title'),
        renderHTML: (attributes) => {
          return {
            title: attributes.title,
          }
        },
      },
      href: {
        parseHTML: (element) => {
          return element.querySelector('a')?.getAttribute('href')
        },
        renderHTML: (attributes) => {
          return {
            href: attributes.href,
          }
        },
      },
      target: {
        parseHTML: (element) => {
          return element.querySelector('a')?.getAttribute('target')
        },
        renderHTML: (attributes) => {
          return {
            target: attributes.target,
          }
        },
      },
      rel: {
        parseHTML: (element) => {
          return element.querySelector('a')?.getAttribute('rel')
        },
        renderHTML: (attributes) => {
          return {
            rel: attributes.rel,
          }
        },
      },
      pageId: {
        parseHTML: (element) => {
          return element.querySelector('a')?.getAttribute('data-page-id')
        },
        renderHTML: (attributes) => {
          return {
            'data-page-id': attributes.pageId,
          }
        },
      },
      label: {
        parseHTML: (element) => {
          return element.querySelector('a')?.getAttribute('label')
        },
        renderHTML: (attributes) => {
          return {
            label: attributes.label,
          }
        },
      },
      originalHeight: {
        parseHTML: (element) => {
          return element.querySelector('img')?.getAttribute('data-height')
        },
        renderHTML: (attributes) => {
          return {
            'data-height': attributes.originalHeight,
          }
        },
      },
      resizedHeight: {
        parseHTML: (element) => {
          return getResizedAttribute(element.querySelector('img'), 'height')
        },
        renderHTML: (attributes) => {
          return {
            height: attributes.resizedHeight,
          }
        },
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'figure',
        contentElement: 'figcaption',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    if (HTMLAttributes.href) {
      const {
        class: extraClass,
        dir,
        href,
        target,
        rel,
        'data-page-id': pageId,
        label,
        ...rest
      } = HTMLAttributes
      return [
        'figure',
        { dir, class: extraClass },
        [
          'a',
          mergeAttributes({
            href,
            target,
            rel,
            'data-page-id': pageId,
            label,
          }),
          ['img', mergeAttributes(rest, { draggable: false, contenteditable: false })],
        ],
        ['figcaption', 0],
      ]
    } else {
      const { dir, class: extraClass, ...rest } = HTMLAttributes

      return [
        'figure',
        { dir, class: extraClass },
        ['img', mergeAttributes(rest, { draggable: false, contenteditable: false })],
        ['figcaption', 0],
      ]
    }
  },

  addCommands() {
    return {
      figureToImage:
        () =>
        ({ tr, commands, editor, chain }) => {
          const { doc, selection } = tr
          const { node, from, to } = selection

          const figure = findChildrenInRange(doc, { from, to }, (node) => node.type.name === 'figure').find(
            ({ node, pos }) => {
              node.pos = pos
              return node
            }
          )

          if (!Object.keys(figure).length) {
            return false
          }

          const { href, target, rel, pageId, alt, title, src, id, resizedHeight, resizedWidth, dir } =
            figure.node.attrs

          const attributes = {
            src,
            id,
            alt,
            title,
            resizedHeight,
            resizedWidth,
            dir,
          }
          const linkAttributes = {
            href,
            target,
            rel,
            pageId,
          }

          return chain()
            .deleteNode('figure')
            .insertContent({
              type: 'paragraph',
            })
            .setNodeSelection(figure.pos)
            .insertContent({
              type: 'image',
              attrs: attributes,
            })
            .setNodeSelection(figure.pos)
            .setLink(linkAttributes)
            .setNodeSelection(figure.pos + 1)
            .run(tr)
        },
      figureToLink:
        (attributes) =>
        ({ tr, commands }) => {
          const { doc, selection } = tr
          const { node, from, to } = selection

          const figure = findChildrenInRange(doc, { from, to }, (node) => node.type.name === 'figure')

          if (!figure.length) {
            return false
          }
          let attrs = {}

          attrs = node ? node.attrs : this.editor.getAttributes('figure')
          if (attributes.rel) {
            let { pageId, label, ...rest } = attrs
            attrs = rest
          } else {
            let { rel, target, ...rest } = attrs
            attrs = rest
          }

          attrs = Object.assign(attrs, attributes)

          const tracker = new Tracker(tr)

          return commands.forEach(figure, ({ node, pos }) => {
            const mapResult = tracker.map(pos)

            if (mapResult.deleted) {
              return false
            }

            const range = {
              from: mapResult.position,
              to: mapResult.position + node.nodeSize,
            }
            return commands.insertContentAt(range, {
              type: this.name,
              attrs,
              content: [{ type: 'text', text: node ? node.textContent : 'Your caption' }],
            })
          })
        },
      unsetFigureLink:
        () =>
        ({ tr, commands, state }) => {
          const { doc, selection } = state
          const { from, to, node } = selection

          const figure = findChildrenInRange(doc, { from, to }, (node) => node.type.name === 'figure').find(
            ({ node, pos }) => {
              node.pos = pos
              return node
            }
          )

          const { src, resizedWidth, alt, title } = node?.attrs
            ? node.attrs
            : this.editor.getAttributes('figure')

          commands.setNodeSelection(figure.pos)
          return commands.insertContent({
            type: 'figure',
            attrs: { src, resizedWidth, alt, title },
            content: [{ type: 'text', text: 'Your caption' }],
          })
        },
      updateFigureAttribute:
        (attribute) =>
        ({ commands, state }) => {
          const { doc, selection } = state
          const { from, to } = selection

          const figure = findChildrenInRange(doc, { from, to }, (node) => node.type.name === 'figure').find(
            ({ node, pos }) => {
              node.pos = pos
              return node
            }
          )

          commands.setNodeSelection(figure.pos)
          return commands.insertContent({
            type: 'figure',
            attrs: { ...figure.node.attrs, ...attribute },
            content: [{ type: 'text', text: figure ? figure.node?.textContent : 'Your caption' }],
          })
        },
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(CustomFigureTemplate)
  },
})
