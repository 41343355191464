<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-sites` }"
            data-tooltip="Back to sites"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ $capitalize(site.name) }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(site.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link
        tag="li"
        :to="{ path: `/site-sites/${site.id}` }"
        exact
        v-if="hasPermission('view.sites/sites')"
      >
        <a>General</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/site-sites/${site.id}/colors` }"
        v-if="hasPermission('view.sites/colors')"
        exact
      >
        <a>Module Colors</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/site-sites/${site.id}/module-styles` }"
        v-if="hasPermission('view.sites/styles')"
        exact
      >
        <a>Module Styles</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/site-sites/${site.id}/categories` }"
        v-if="hasPermission('view.sites/categories')"
        exact
      >
        <a>Categories</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/site-sites/${site.id}/markets` }"
        v-if="hasPermission('view.sites/site-markets')"
        exact
      >
        <a>Markets</a>
      </router-link>
    </ul>
    <router-view />
  </div>
</template>

<script>
import SitesService from '@services/SitesService'

export default {
  mounted() {
    if (this.isIdMismatch) {
      this.getSiteLanguage()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
    this.title = `${this.site?.name}`
  },
  data() {
    return {
      title: '',
    }
  },
  computed: {
    site() {
      return this.$store.state.editPage.model
    },
    isIdMismatch() {
      return Number(this.$route.params['id']) !== this.site?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    getSiteLanguage() {
      SitesService.getOne(this.$route.params['id'], { with: 'affiliate' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Site Language not found') >= 0) {
                this.$router.push('/site-markets')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = `${this.site.name}`
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
