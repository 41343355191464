<template>
  <base-rich-text :value="value" :siteId="1" :marketId="25" label="Test Label" />
</template>

<script>
import BaseRichText from '@atoms/fields/base-rich-text'

export default {
  components: { BaseRichText },
  mounted() {},
  data() {
    return {
      value: null
    }
  }
}
</script>
