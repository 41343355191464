<script>
import ModuleValue from '@constants/module-value'

export default {
  computed: {
    moduleStyles() {
      return this.$store.getters['cms/getStyleByModuleId'](this.pageModule.module.id)
    },
    moduleTitle() {
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return this.pageModule.template_block_name
      } else {
        return this.pageModule.title
      }
    },
    backgroundColors() {
      return this.$store.state.cms.colors
    },
    selectedBackgroundColor() {
      if (this.pageModule.background_color_id) {
        const match = this.$store.state.cms.colors.find(
          color => this.pageModule.background_color_id === color.id
        )
        if (match) {
          return match.hex_code
        }
      }
      return null
    },
    showValueFromTemplateBadge() {
      return false
    },
    canEdit() {
      if (
        [ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK, ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET].includes(
          this.pageModule.value_type
        )
      ) {
        return false
      }

      return this.moduleOwnerType !== ModuleValue.OWNER_TYPE_TEMPLATE
    },
    moduleName() {
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return this.pageModule.template_block_name
      }
      return this.pageModule.module.label
    },
    preview() {
      // prefilled module name
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET) {
        return this.pageModule.prefilled_module_name
      }

      // template block
      if (
        this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK &&
        this.pageModule.template_block_description
      ) {
        return this.pageModule.template_block_description
      }
      if (!this.canEdit && !this.isAnchorModule) {
        return 'No preview available'
      }

      let title = ''
      let heading = this.pageModule.title_tag
      let content = this.pageModule.title

      switch (this.pageModule.module.name) {
        case 'content':
          title = heading ? heading.toUpperCase() : 'Title'
          return `${title}: ${content || 'Title is empty'}`
        case 'top_list':
          title = this.findFieldByName('top_list_title')
          return `Title: ${title.value || 'Title is empty'}`
        case 'cards':
          return `Label: ${this.pageModule.module_value_fields[0].value}`
        case 'archive':
          var model = this.findFieldByName('archive_model_type')
          return `Model type: ${model.value || 'No model selected'}`
        case 'counter':
          title = this.findFieldByName('counter_title')
          return `Title: ${title.value || 'Title is empty'}`
        case 'anchor':
          title = heading ? heading.toUpperCase() : 'Title'
          return `${title}: ${content || 'Title is empty'}`
        case 'menu':
          var menuField = this.findFieldByName('menu_id')
          if (menuField.value_label) {
            return `Label: ${menuField.value_label}`
          }
        // eslint-disable-next-line no-fallthrough
        default:
          return 'No preview available'
      }
    }
  },
  props: {
    allowModuleDelete: {},
    openModuleValues: {},
    moduleOwnerType: {},
    pageModule: {
      type: Object,
      required: true
    },
    siteId: {
      required: true
    },
    affiliateId: {
      required: true
    },
    marketId: {},
    index: {}
  },
  methods: {
    isFieldHidden(name, moduleField) {
      if (name === 'top_list' && moduleField.field.name === 'top_list_enable_sorting') {
        return this.siteId === 3 ? false : true
      } else {
        return false
      }
    },
    editableLabel() {
      if (
        [ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET, ModuleValue.VALUE_TYPE_PREFILLED_MODULE].includes(
          this.pageModule.value_type
        )
      ) {
        return 'Prefilled module'
      }
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return 'Template block'
      }
      return 'Template module'
    },
    editableLink() {
      if (
        [ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET, ModuleValue.VALUE_TYPE_PREFILLED_MODULE].includes(
          this.pageModule.value_type
        )
      ) {
        return `/site-prefilled-modules/${this.pageModule.prefilled_module_id}#markets`
      }
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return `/site-templates/${this.pageModule.template_id}/blocks?child=${this.pageModule.template_block_id}`
      }
      return 'Template module'
    },
    editableClass() {
      if (
        [ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET, ModuleValue.VALUE_TYPE_PREFILLED_MODULE].includes(
          this.pageModule.value_type
        )
      ) {
        return 'bg-purple-75'
      }
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return 'bg-orange-75'
      }
      return 'bg-black-25'
    },
    setVisibility(event) {
      // dont toggle module visibility if user is clicking on the module action buttons
      if (event.target.classList.contains('btn') || event.target.parentNode?.classList.contains('btn')) {
        return false
      }
      if (this.canEdit && this.moduleOwnerType !== ModuleValue.OWNER_TYPE_PREFILLED_MODULE_MARKET) {
        this.pageModule.visible = !this.pageModule.visible
        this.$forceUpdate()
      }
    },
    deleteModule(event) {
      // prevent events fired after tabbing through the form fields and clicking enter/space from a tabbed click
      if (event.x === 0 && event.y === 0) {
        return false
      }
      this.$emit('delete', this.pageModule.id)
    },
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    }
  }
}
</script>

