<template>
  <div :class="[defaultInputClass ? 'form-group' : '', customClass]" :hidden="type == 'hidden'">
    <label v-if="showLabel">{{ $prettyLabels(label) }}</label>

    <label class="text-muted ml-1 clickable" v-if="labelAction" @click="labelAction">
      {{ labelActionTitle }}
    </label>

    <span v-if="required">&nbsp;*&nbsp;</span>

    <span
      class="text-muted ml-2 clickable reset-placeholder"
      v-if="showPlaceholderReset"
      @click="$emit('update:modelValue', placeholder)"
    >
      reset to placeholder
    </span>

    <input
      v-bind="$attrs"
      :name="label && slugify(label)"
      @keydown.enter="keydownEnter"
      v-model="data"
      class="form-control"
      v-bind:class="{
        'text-muted': showPlaceholderAsValue && (modelValue === placeholder || !modelValue),
        noArrows: hideNumberSpinner,
        loading: loading,
        'pr-4': clearInput,
      }"
      :placeholder="placeholder"
      :type="type"
      ref="input"
      :required="required"
      :readonly="readOnly"
      :disabled="readOnly"
    />

    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    hideNumberSpinner: {
      type: Boolean,
      default: () => false,
    },
    hidePlaceholderReset: {
      type: Boolean,
      default: () => false,
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    defaultInputClass: {
      type: Boolean,
      default: () => true,
    },
    showPlaceholderAsValue: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      default: () => false,
    },
    placeholder: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    labelActionTitle: {
      type: String,
    },
    labelAction: {
      type: Function,
    },
    helpText: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      default: () => 'text',
    },
    autoFocus: {
      default: () => false,
    },
    readOnly: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    valueModifier: {
      type: Function,
    },
    field: {
      required: false,
      default: () => null,
    },
    customClass: {
      required: false,
      default: () => '',
    },
    autoFocusDelay: {
      default: 300,
    },
    keydownEnter: {
      default() {
        return () => true
      },
      required: false,
    },
    clearInput: {
      default: () => false,
      required: false,
    },
    valueIsObject: {
      default: () => false,
      required: false,
    },
  },
  computed: {
    showPlaceholderReset() {
      return (
        this.showPlaceholderAsValue &&
        this.modelValue !== this.placeholder &&
        this.modelValue &&
        !this.hidePlaceholderReset
      )
    },
    data: {
      get: function () {
        if (this.showPlaceholderAsValue && !this.modelValue) {
          return this.placeholder
        }
        //This one is needed to show the username value on edit elias campaign
        if (!this.modelValue && (this.field?.disabled || this.valueIsObject) && this.field?.value_key) {
          let path = this.field.value_key.split('.')
          return path.reduce(function (result, currentKey) {
            if (result) {
              return result[currentKey]
            }
          }, this.$parent.object)
        }
        return this.modelValue
      },
      set: function (value) {
        let modifiedValue = value
        if (this.showPlaceholderAsValue) {
          modifiedValue = value ? value : this.placeholder
        } else if (this.valueModifier) {
          modifiedValue = this.valueModifier(value)
        }
        if (modifiedValue === undefined) {
          modifiedValue = ''
        }
        this.$emit('update:modelValue', modifiedValue, this.field)
      },
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.setFocused()
    }
  },
  methods: {
    setFocused() {
      setTimeout(() => {
        if (this.$refs && Object.hasOwn(this.$refs, 'input')) {
          this.$nextTick(this.$refs.input.focus())
        }
      }, this.autoFocusDelay)
    },
  },
}
</script>

<style scoped>
input[type='number'].noArrows::-webkit-inner-spin-button,
input[type='number'].noArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
