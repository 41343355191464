<template>
  <div>
    <data-table
      model="koala/PlatformReports"
      service-file="koala/PlatformReportsService"
      :path="path()"
      permissionPath="koala/v1/reports"
      :columns="columns()"
      :column-templates="columnTemplates()"
      :create-defaults="createDefaults()"
      @bulkClickByIds="bulkClickByIds"
      :enableBulkSelect="true"
      ref="table"
      :tableLimits="[10, 50, 100]"
    />

    <dynamic-bulk-edit
      v-if="showBulkEdit"
      ref="bulkEdit"
      model="koala/PlatformReports"
      service-file="koala/PlatformReportsService"
      @closed="() => (this.showBulkEdit = false)"
      @refresh="$refs.table.search()"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import DynamicBulkEdit from '@molecules/koala/dynamic-bulk-edit.vue'
import BasicOptions from '@mixins/BasicOptions'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      showBulkEdit: false
    }
  },
  components: { DynamicBulkEdit },
  methods: {
    columns() {
      return [
        Object.assign({}, BasicOptions.booleanColumn('platform.scrapable'), {
          search_key: 'platform_scrapable',
          template: TableColumnTemplate.BOOLEAN,
          label: 'Platform Scrapable',
          customOptions: [
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' }
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          clearable: true
        }),
        {
          label: 'Platform',
          field: 'platform',
          type: 'text',
          filterable: true,
          class: 'col-platforms',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          sortable: true,
          order_key: 'platform.name',
          searchBy: 'name'
        },
        {
          field: 'name',
          type: 'text',
          search_key: 'name',
          filterable: true,
          advancedFilter: true,
          sortable: true,
          order_key: 'name',
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false
        },
        {
          field: 'stats_per_report',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: false,
          clearable: true,
          customOptions: [
            { label: 'All', id: null },
            { label: 'All in one', id: 'AllInOne' },
            { label: 'Daily', id: 'Daily' },
            { label: 'Monthly', id: 'Monthly' }
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          sortable: false
        },
        { field: 'updated', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    columnTemplates() {
      return Object.assign({
        platform: function(row) {
          return row.platform.name
        }
      })
    },
    createDefaults() {
      return {
        default: false,
        has_campaigns: false,
        month_to_date: false,
        auto_create_account_reports: false
      }
    },
    bulkClickByIds(ids, filteredResults, filters, resultCount) {
      this.showBulkEdit = true
      this.$nextTick(() =>
        filteredResults
          ? this.$refs.bulkEdit.showByFilters(filters, resultCount)
          : this.$refs.bulkEdit.showByIds(ids)
      )
    },
    bulkClickByFilters(filters, resultCount) {
      this.showBulkEdit = true
      this.$nextTick(() => this.$refs.bulkEdit.showByFilters(filters, resultCount))
    },
    path() {
      return `koala/v1/reports`
    }
  }
}
</script>
