import confluenceIds from '@constants/confluence-ids'
const patterns = {
  dynamic_form: [
    {
      regex: /^\/site-sites\/(\d+)/g,
      id: confluenceIds.sites.general.id
    },
    {
      regex: /^\/site-menus\/(\d+)/g,
      id: confluenceIds.menus.general.id
    },
    {
      regex: /^\/site-banners\/(\d+)/g,
      id: confluenceIds.banners.general.id
    },
    {
      regex: /^\/site-authors\/(\d+)/g,
      id: confluenceIds.authors.general.id
    },
    {
      regex: /^\/site-templates\/(\d+)/g,
      id: confluenceIds.page_types.general.id
    }
  ],
  table_actions: [
    {
      regex: /^\/site-pages/g,
      path: '/site-pages',
      id: confluenceIds.pages.top.id
    },
    {
      regex: /^\/site-sites/g,
      path: '/site-sites',
      id: confluenceIds.sites.top.id
    },
    {
      regex: /^\/site-sites\/(\d+)\/colors/g,
      id: confluenceIds.sites.module_colors.id
    },
    {
      regex: /^\/site-sites\/(\d+)\/module-styles/g,
      id: confluenceIds.sites.module_styles.id
    },
    {
      regex: /^\/site-sites\/(\d+)\/categories/g,
      id: confluenceIds.sites.categories.id
    },
    {
      regex: /^\/site-sites\/(\d+)\/markets/g,
      id: confluenceIds.sites.markets.id
    },
    {
      regex: /^\/site-menus/g,
      id: confluenceIds.menus.top.id
    },
    {
      regex: /^\/site-banners\/(\d+)\/items/g,
      id: confluenceIds.banners.items.id
    },
    {
      regex: /^\/site-banners/g,
      id: confluenceIds.banners.top.id
    },
    {
      regex: /^\/site-authors/g,
      path: '/site-authors',
      id: confluenceIds.authors.top.id
    },
    {
      regex: /^\/site-authors\/(\d+)\/pages/g,
      id: confluenceIds.authors.pages.id
    },
    {
      regex: /^\/site-redirects/g,
      id: confluenceIds.redirects.id
    },
    {
      regex: /^\/site-templates/g,
      id: confluenceIds.page_types.top.id
    },
    {
      regex: /^\/site-templates\/(\d+)\/blocks/g,
      id: confluenceIds.page_types.blocks.id
    },
    {
      regex: /^\/site-templates\/(\d+)\/page-styles/g,
      id: confluenceIds.page_types.page_styles.id
    },
    {
      regex: /^\/site-templates\/(\d+)\/extra-fields/g,
      id: confluenceIds.page_types.extra_fields.id
    },
    {
      regex: /^\/user-comments/g,
      id: confluenceIds.user_comments.id
    }
  ],
  table: {
    regex: /^\/reports/g,
    path: '/reports',
    id: confluenceIds.reports.id
  },
  sections: {
    regex: /^\/site-pages\/(\d+)/g,
    id: confluenceIds.pages.sections.id
  }
}

export default patterns
