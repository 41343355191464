<template>
  <div>
    <div class="mb-2 mt-2">
      <colaborative-edit-notification
        v-if="showColaborativeNotification"
        :route_path="$route.fullPath"
        :username="username"
      />
    </div>
    <edit-section-modules
      v-if="template"
      :sections="template.sections"
      moduleOwnerType="template"
      :siteId="template.id"
      :loading="loading"
      :showSave="true"
      :moduleOwnerId="$route.params.id"
      @save="update"
      v-bind:tooltip="2405826817"
      :showColaborativeNotification="showColaborativeNotification"
    />
  </div>
</template>
<script>
import TemplatesService from '@services/TemplatesService'
import EditSectionModules from '@templates/cms/section/section.vue'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification'

export default {
  data() {
    return {
      template: null,
      loading: false,
      modules: [],
      showColaborativeNotification: false,
      modelName: 'sites-page_type_sections',
      username: '',
    }
  },
  components: {
    EditSectionModules,
    ColaborativeEditNotification,
  },
  created() {
    this.getSiteTemplate()
    let pusherChannel = window.pusher.subscribe('updateModelChannel')

    pusherChannel.bind('pusher:subscription_succeeded', function () {})

    pusherChannel.bind('updateModel', (message) => {
      if (
        message.modelName === this.modelName &&
        message.modelId === Number(this.template.id) &&
        message.pusherToken !== this.$store.state.app.uniqueKey
      ) {
        this.showColaborativeNotification = true
        this.username = message.username
      }
    })
  },
  methods: {
    getSiteTemplate() {
      TemplatesService.getOne(this.$route.params.id, {
        with: ['sections', 'sections.enabled_modules', 'blocks'].join(),
      })
        .then((response) => {
          if (response.data.result.length === 0) {
            this.$router.push('/site-pages')
          } else {
            this.template = response.data.result[0]
            this.setTitle(`${this.template.name} || Sections`)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    async update() {
      if (this.showColaborativeNotification) {
        return
      }
      this.loading = true
      delete this.template.enabled_sections
      await TemplatesService.update(this.template)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.template = response.data.result
            this.showSuccessMessage('Template sections saved')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
