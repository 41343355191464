<template>
  <div>
    <data-table
      model="Bonus"
      service-file="BonusesService"
      :serviceParams="[operator_id]"
      :path="path()"
      ref="list"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :rowActionButtons="rowActions"
      :column-templates="columnTemplates()"
    />
    <type-edit ref="typesModal" model="bonus" :showWageringFields="true" />
    <market-clone ref="marketCloneModal" v-on:refresh="refresh" />
  </div>
</template>

<script>
import TypeEdit from '@molecules/dms/bonus/type-edit'
import MarketClone from '@molecules/dms/operator/markets-data/clone-modal'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  components: { TypeEdit, MarketClone },
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      operator_id: this.$route.params['id'],
      rowActions: [
        {
          action: this.primaryCustomAction,
          title: 'Bonuses',
          icon: 'uil uil-medal',
          class: 'btn-info',
        },
        {
          action: this.cloneAction,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'bg-orange-75',
        },
      ],
    }
  },
  mounted() {
    if (this.$route.query['bonus_type_id'] && this.$route.query['type'] == 1) {
      this.$refs.typesModal.show(this.$route.query['bonus_id'], this.$route.query['bonus_type_id'])
    }
  },
  methods: {
    columns() {
      return [
        { field: 'type', type: 'text', filterable: true },
        { field: 'market_label', type: 'text', filterable: true, label: 'Market' },
        { field: 'min_deposit', type: 'text', filterable: true },
        { field: 'min_withdrawal', type: 'text', filterable: true },
        { field: 'max_withdrawal', type: 'text', filterable: true },
        { field: 'currencies', type: 'number', filterable: true },
        { field: 'rating', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    primaryCustomAction(row) {
      this.$refs.typesModal.show(row.id)
    },
    cloneAction(row) {
      this.$refs.marketCloneModal.show(row)
    },
    path() {
      return `hercules/operators/bonuses?with=market,currencies&operator_id=${this.$route.params['id']}`
    },
    createDefaults() {
      return {
        operator_id: this.$route.params['id'],
        rating: 4,
      }
    },
    refresh() {
      this.$refs.list.search()
    },
    columnTemplates() {
      return Object.assign({
        currencies: function (row) {
          if (row.currencies && row.currencies.length) {
            return row.currencies.length
          }
          return ''
        },
      })
    },
  },
}
</script>
