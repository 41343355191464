<template>
  <data-table
    model="cms/BannerItem"
    service-file="cms/BannerItemsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    ignore-row-click-target="base-list-image-column"
    pusherModel="sites-banner_items"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import BasicOptions from '@mixins/BasicOptions'

export default {
  mounted() {
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.title}`)
  },
  data() {
    return {
      title: `Banner Items`,
    }
  },
  methods: {
    columns() {
      return [
        { field: 'image_title', type: 'text', label: 'Title', filterable: true },
        { field: 'link', type: 'text', filterable: true },
        {
          field: 'image.url',
          type: 'text',
          filterable: false,
          label: 'Preview',
          template: TableColumnTemplate.IMAGE,
        },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/sites/banner-items?parent_id=${this.$route.params['id']}`
    },
    createDefaults() {
      return {
        banner_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
