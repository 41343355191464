<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-8">
      <div class="card-header">
        <h3 class="card-title">Sync data from production to test environment</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <base-select
              v-if="domains"
              label="Live site"
              v-model="site"
              @update:modelValue="changeSite"
              :customOptions="domains"
            />
          </div>
          <div class="col-6">
            <base-select
              v-if="site && environments"
              label="Target environment"
              v-model="environment"
              :customOptions="environments"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <sync-table
              :data="[
                {
                  label: 'Database',
                  value: 'database',
                  tooltip: 'Does not impact files or themes.'
                },
                {
                  label: 'Files',
                  value: 'skipDbAndThemes',
                  enableBadge: true,
                  badgeColor: 'badge-danger',
                  helpText: 'Destructive',
                  tooltip: 'Old files are deleted. Does not impact database or themes.'
                },
                {
                  label: 'Database + Files',
                  value: 'skipThemes',
                  enableBadge: true,
                  badgeColor: 'badge-danger',
                  helpText: 'Destructive',
                  tooltip: 'Syncs database and files. Old files are deleted.'
                },
                {
                  label: 'Database + Files',
                  value: 'nonDestructive',
                  enableBadge: true,
                  badgeColor: 'badge-success',
                  helpText: 'Non destructive',
                  tooltip: 'Does not impact themes. Old files are retained.'
                },
                {
                  label: 'Database + Files + Themes',
                  value: 'hardCopyThemes',
                  enableBadge: true,
                  badgeColor: 'badge-danger',
                  helpText: 'Destructive',
                  tooltip: 'Parent and child themes are hard copied. Old files are deleted.'
                }
              ]"
              v-model="method"
              :modelValue="method"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-primary float-right mr-1"
          v-bind:class="{ loading: loading }"
          :disabled="!site || !environment"
          @click="sync()"
        >
          Sync
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BrewService from '@/services/devops/BrewService'
import BaseSelect from '@/components/fields/base-select'
import SyncTable from '@atoms/devops/sync-table.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'

const confirmMessage = async (title, content, actionButtonTitle, actionButtonIconClass) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle, actionButtonIconClass })
}

export default {
  data() {
    return {
      domains: [],
      site: null,
      environments: [],
      environment: null,
      method: 'database',
      loading: false
    }
  },
  components: { ConfirmDialog, BaseSelect, SyncTable },
  mounted() {
    this.getDomains()
  },
  methods: {
    changeSite() {
      this.environment = null
      this.getEnvironments()
    },
    getDomains() {
      BrewService.getDomains()
        .then(response => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            if (response.data.result) {
              this.domains = response.data.result
            }
          }
        })
        .catch(() => {
          this.showErrorMessage('Sites not found')
        })
    },
    getEnvironments() {
      BrewService.getEnvironments({ domain: this.site })
        .then(response => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            if (response.data.result) {
              if (Array.isArray(response.data.result)) {
                this.environments = response.data.result
              } else {
                this.environments = []
              }
            }
          }
        })
        .catch(() => {
          this.showErrorMessage('Environments not found')
        })
    },
    async sync() {
      if (await confirmMessage('Warning', 'Are you sure want to Sync this environment?', 'Yes', '')) {
        this.loading = true
        let body = {
          domain: this.site,
          target_env: this.environment
        }
        if (this.method != 'database') {
          body = {
            domain: this.site,
            target_env: this.environment,
            method: this.method
          }
        }

        await BrewService.sync(this.method == 'database' ? 'db' : 'files', body)
          .then(response => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              if (response.data.result) {
                this.showSuccessMessage('Syncing environment.')
              }
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
