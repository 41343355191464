import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/widgets/odds?id=${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/widgets/odds`, data)
  },
  update(data) {
    return Api().put(`hercules/widgets/odds/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/widgets/odds/${row.id}`)
  }
}
