<template>
  <data-table
    model="AffiliateGameExtraField"
    service-file="AffiliateGameExtraFieldsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    ref="list"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Extra Game fields`
    )
  },
  methods: {
    path() {
      return `hercules/games/extra-fields?affiliate_id=${this.$route.params['id']}`
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'help_text', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        affiliate_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
