<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="edit"
      :inCard="true"
      :formFields="structure.list"
      :structure="structure"
      :sportsBookFields="sportsBookFields"
      :path="config.url"
      :object="object"
      :on-submit="submit"
      :on-remove="remove"
      :sportsBookFieldValues="sportsBookFieldValues"
    />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import DynamicForm from '@/components/fields/DynamicForm'
import Casino from '@/models/Operator'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import OperatorsService from '@services/OperatorsService'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { MultiSelect, DynamicForm, ConfirmDialog },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Casino.getConfig(),
      sportsBookFields: Casino.getSportsBookFields(),
      sportsBookFieldValues: [],
      bettingAppFields: []
    }
  },
  mounted() {
    this.getOperator()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} General`)
  },
  methods: {
    getOperator() {
      OperatorsService.getOne(this.$route.params['id'], {
        with: [
          'game_types',
          'software',
          'selling_points',
          'languages',
          'countries',
          'restricted_countries',
          'restricted_vip_countries',
          'blacklisted_countries',
          'support_types',
          'game_categories',
          'currencies',
          'bettingTypes',
          'sportCategories',
          'oddsTypes',
          'betting_apps'
        ].join(',')
      }).then(response => {
        this.object = {
          ...response.data.result[0],
        };
        this.getStructure()
        this.getSportBookFieldValues()
      })
    },
    getSportBookFieldValues() {
      let sportsBookFields = []
      sportsBookFields['bet_builder'] = this.object.bet_builder
      sportsBookFields['live_streaming'] = this.object.live_streaming
      sportsBookFields['cash_out'] = this.object.cash_out
      sportsBookFields['in_play_betting'] = this.object.in_play_betting
      sportsBookFields['acca_insurance'] = this.object.acca_insurance
      sportsBookFields['sport_categories'] = this.object.sport_categories
        ? this.object.sport_categories.map((object) => object.id)
        : []
      sportsBookFields['betting_types'] = this.object.betting_types
        ? this.object.betting_types.map((object) => object.id)
        : []
      sportsBookFields['odds_types'] = this.object.odds_types
        ? this.object.odds_types.map((object) => object.name)
        : []
      this.sportsBookFieldValues = sportsBookFields
    },
    getStructure() {
      this.structure = Casino.getStructure()
    },
    submit() {
      const self = this
      this.object = {
        ...this.object,
        ...this.sportsBookFieldValues,
      }

      delete this.object.status_changed_by
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return OperatorsService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getOperator()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure want to delete this item?')) {
        OperatorsService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/operators')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
