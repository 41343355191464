<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          label="Site"
          v-model="siteId"
          :clearable="false"
          @update:modelValue="changeSiteId"
          path="hercules/sites/sites?orderBy=name&ascending=1"
          ref="siteSelect"
          option-label-key="name"
          :select-first-after-fetch="!(cacheSettings && this.cacheSettings.site_id)"
          class="pr-2 pl-2 mb-0"
        />
      </div>
      <div class="w-50">
        <base-select
          label="Market"
          :clearable="true"
          v-model="marketId"
          ref="marketSelect"
          placeholder="Choose market"
          :loadOnMount="false"
          :select-first-if-one-result="true"
          :path="`hercules/sites/markets/by-site/${siteId}?fields_only[]=id,&fields_only[]=label`"
          class="pr-2 pl-2 mb-0"
          @update:modelValue="changeMarketId"
        />
      </div>
      <div class="w-50">
        <base-select
          label="Page type"
          v-model="templateId"
          ref="templateSelect"
          placeholder="Choose page type"
          :loadOnMount="false"
          :select-first-if-one-result="true"
          optionLabelKey="name"
          :path="
            `hercules/sites/templates?site_id=${siteId}&orderBy=name&ascending=1&market_id=${marketId}&with_page_count=1&fields_only[]=id,&fields_only[]=name`
          "
          class="pr-2 pl-2 mb-0"
          :clearable="true"
          @update:modelValue="changeTemplateId"
          :pageCount="true"
        />
      </div>
      <div class="w-50">
        <base-select
          label="Category"
          v-model="categories"
          ref="categorySelect"
          placeholder="Choose category"
          :loadOnMount="false"
          :select-first-if-one-result="true"
          optionLabelKey="name"
          :path="
            `hercules/sites/categories?site_id=${siteId}&orderBy=name&ascending=1&market_id=${marketId}&page_type_id=${templateId}&fields_only[]=id,&fields_only[]=name`
          "
          class="pr-2 pl-2 mb-0"
          :clearable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select'

export default {
  components: { BaseSelect },
  mounted() {
    if (this.cacheConfig) {
      this.siteId = this.cacheSettings.site_id && this.cacheSettings.site_id
      this.marketId = this.cacheSettings.market_id && this.cacheSettings.market_id
      this.templateId = this.cacheSettings.template_id && this.cacheSettings.template_id
      this.categories = this.cacheSettings.categories && this.cacheSettings.categories

      this.$nextTick(() => {
        this.$refs.marketSelect.getOptions()
        this.$refs.templateSelect.getOptions()
        this.$refs.categorySelect.getOptions()
      })
    }
  },
  data() {
    return {
      cacheSettings: this.$cookies.get('pages_filter'),
      siteId: null,
      marketId: null,
      templateId: null,
      categories: null,
      collapsed: false
    }
  },
  computed: {
    cacheConfig() {
      return this.cacheSettings && this.cacheSettings.site_id
    },
    filters() {
      return {
        template_id: this.templateId,
        market_id: this.marketId,
        site_id: this.siteId,
        categories: this.categories
      }
    }
  },
  watch: {
    filters() {
      this.$emit('filterChanged', this.getFilter())
    }
  },
  methods: {
    changeSiteId() {
      this.marketId = null
      this.templateId = null
      this.$nextTick(() => {
        this.$refs.marketSelect.getOptions()
      })
      this.changeMarketId()
    },
    changeMarketId() {
      this.$nextTick(() => {
        this.$refs.templateSelect.loading = true
        this.$refs.templateSelect.getOptions()
      })
      this.changeTemplateId()
    },
    changeTemplateId() {
      this.categories = null
      this.$nextTick(() => {
        this.$refs.categorySelect.getOptions()
      })
    },
    collapseClick() {
      this.collapsed = !this.collapsed
    },
    getFilter() {
      let filter = {
        template_id: this.templateId,
        market_id: this.marketId,
        site_id: this.siteId,
        categories: this.categories
      }

      this.$cookies.set('pages_filter', Object.assign({}, this.cacheSettings, filter))
      return filter
    }
  }
}
</script>

<style scoped>
.type > li.active {
  margin-top: 0 !important;
}
</style>
