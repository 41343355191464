import Api from '../Api'

const buildQueryString = (filters) => {
  return Object.keys(filters)
    .map(key => key + '=' + filters[key])
    .join('&')
}

export default {
  getOne(id, data) {
    return Api()
      .get(`koala/v1/accounts/${id}`, {
        params: data
      })
      .then(response => {
        const initialAccountData = response.data.result[0]

        if (initialAccountData && initialAccountData.migrated_to_id) {
          return Api()
            .get(`koala/v1/accounts/${initialAccountData.migrated_to_id}`)
            .then(migratedResponse => {
              const migratedAccountData = migratedResponse.data.result[0]

              initialAccountData.operators = migratedAccountData.operator.id
              initialAccountData.accounts = migratedAccountData.id
              
              return Promise.resolve({ data: { result: [initialAccountData] } })
            })
            .catch(error => {
              console.error('Error fetching migrated account details:', error)
              throw error
            })
        } else {
          return Promise.resolve({ data: { result: [initialAccountData] } })
        }
      })
      .catch(error => {
        console.error('Error fetching initial account details:', error)
        throw error
      })
  },

  get() {
    return Api().get(`koala/v1/accounts`)
  },
  post(data) {
    const {
      account_manager_id,
      account_manager_name,
      active,
      business_unit,
      currency,
      notes,
      operator,
      migrated_to_id,
      username,
      proxy,
      status
    } = data
    const isActive = !!active
    let payload = {
      account_manager_id: account_manager_id == null ? -1 : account_manager_id,
      account_manager_name: account_manager_id == null ? 'UNSET' : account_manager_name,
      active: isActive,
      business_unit,
      currency,
      notes,
      operator,
      status,
      username,
      proxy
    }
    if (status.includes('Migrated') || status.includes('Merged')) {
      payload.migrated_to_id = migrated_to_id
    }
    return Api().post(`koala/v1/accounts`, payload)
  },

  remove(data) {
    return Api().delete(`koala/v1/accounts/${data.id}`, data)
  },
  update(data) {
    const {
      id,
      account_manager_id,
      account_manager_name,
      active,
      currency,
      notes,
      username,
      proxy,
      status,
      migrated_to_id,
    } = data;
  
    const isMigratedOrMerged = typeof status === 'string' && (status.includes('Migrated') || status.includes('Merged'));
  
    let payload = {
      account_manager_id: account_manager_id == null ? -1 : account_manager_id,
      account_manager_name: account_manager_id == null ? 'UNSET' : account_manager_name,
      active: !!active,
      currency,
      notes,
      status,
      username,
      proxy
    };
  
    if (isMigratedOrMerged) {
      payload.migrated_to_id = migrated_to_id;
    }
  
    return Api().put(`koala/v1/accounts/${id}`, payload);
  },
  
  patch(data) {
    return Api().patch(`koala/v1/accounts/${data.id}`, { active: data.active })
  },
  // credentials
  getCredentials(id) {
    return Api().get(`koala/v1/accounts/${id}/credentials`)
  },
  bulkByIds(ids, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    return Api().patch(`koala/v1/accounts?${buildQueryString({ ids: ids })}`, changes)
  },
  bulkByFilters(filters, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    return Api().patch(`koala/v1/accounts?${buildQueryString(filters)}`, changes)
  },
  prepareTheDataForBulkEdit(changes, extraData) {

    //Remove operator from payload
    delete changes.operators;
    if (changes.active === null) {
      changes.active = false
    }
    if (changes.account_manager_id === null) {
      changes.account_manager_id = -1
      changes.account_manager_name = 'UNSET'
    }
    if (changes.account_manager_id) {
      if (Object.hasOwn(extraData, 'users') && extraData.users[changes.account_manager_id]) {
        changes.account_manager_name = extraData.users[changes.account_manager_id]
      }
    }
    
    if (changes.proxy === null) {
      changes.proxy = '00000000-0000-0000-0000-000000000000'
    }
  },
}
