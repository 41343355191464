<template>
  <div class="card">
    <div class="card-header justify-content-between border-bottom align-items-center mb-4">
      <h2>Home Page Performance Score</h2>
      <div class="tabs d-flex">
        <button class="second-button d-flex align-items-center border-right-0" :style="{borderRadius: '4px 0px 0px 4px'}" @click="activeTab = 'mobile'" :class="{'active': activeTab === 'mobile'}">
          Mobile
        </button>
        <button class="second-button d-flex align-items-center border-left-0" :style="{borderRadius: '0px 4px 4px 0px'}" @click="activeTab = 'desktop'" :class="{'active': activeTab === 'desktop'}">
          Desktop
        </button>
      </div>
      <div class="w-10 d-flex flex-column align-items-end">
        <!-- <button class="btn btn-secondary d-flex align-items-center">Origin</button> -->
      </div>

      <div class="right-element top-0 right-0 position-absolute m-3">
                <div class="badge bg-white-75">BETA</div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="activeTab === 'desktop'">
        <div class="w-100 d-flex flex-column rounded-3 pb-4 bg-white" style="border-color: #E2E8F0;"> 
          <div class="w-100 d-flex align-items-center justify-content-between pb-4"> 
              <h4 class="text-color">Diagnose performance issues</h4>
              <a :href="page_speed_url" target="_blank" rel="noopener noreferrer" class="btn btn-secondary d-flex align-items-center" role="button">Go to PageSpeed Insights</a>
              <div class="d-flex align-items-center justify-content-center gap-3">
                  <p class="text-color">Last updated: {{ lastUpdated }}</p>
              </div>
          </div>
          <div v-for="(issue, index) in desktopData.performanceIssues" :key="index" class="w-100 d-flex flex-column justify-content-between mb-2" style="border-color: #E2E8F0;">
            
              <div class="d-flex align-items-center">
                  <div class="me-2 d-flex flex-row align-items-center justify-content-between pr-4" style="width: 16.666667%;">
                      <p class="text-dark font-weight-semi-bold" style="font-size: 1rem;">{{ issue.name }}</p>
                      <span class="ms-3 font-weight-bold" style="font-size: 1.25rem;"><strong>{{ truncateNum(issue.score) }}</strong></span>
                  </div>
                  <div class="ms-2 rounded-pill" :style="{backgroundColor: getBackgroundScoreColor(issue.score) }" style="width: 83.333333%; height: 1rem;">
                      <div class="h-100 rounded-pill" :style="{width: issue.score + '%', backgroundColor: issue.score !== 'N/A' ? getScoreColor(issue.score) : '#DADCE0' }"></div>
                  </div>
              </div>
          </div>
      </div>
      <!-- <div class="pb-4 pt-4 rounded-3 bg-white"> -->
      <!-- <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <h4 class="text-color">Core Web Vitals assessment:</h4>
        </div>
      </div> -->
      <!-- <div class="row">
        <div v-for="(vital, index) in desktopData.coreWebVitals" :key="index" class="col-sm-6 d-flex flex-column mb-4">
          <div class="d-flex align-items-center">
            <p class="text-dark font-weight-semi-bold">{{ formatVitalName(vital.name) }}</p>
          </div>
          <div class="d-flex align-items-center" style="position: relative; left: vital.percentile + '%';">
            <p class="font-weight-bold mb-3" :style="{color: vital.percentile !== 'N/A' ? '#22C55E' : '#DADCE0', left: isNaN(formatMetricValue(vital.name, vital.percentile)) ? 75 + '%' : 76 + '%', position: 'relative'}">{{ formatMetricValue(vital.name, vital.percentile) }}</p>
          </div>
          <div :style="{backgroundColor: vital.percentile !== 'N/A' ? '#22C55E' : '#DADCE0'}" style="height: 4px; border-radius: 32px; position: relative;">
            <template v-for="(distribution, dIndex) in vital.distributions">
              <div :key="dIndex" :style="{height: '100%', backgroundColor: getDistributionColor(dIndex), width: distribution.proportion + '%', position: 'absolute', left: calculateLeftPosition(vital.distributions, dIndex)}"></div>
            </template>
            <img :style="{position: 'absolute', bottom: 0}" class="absolute" src="../../../../../src/styles/libs/gig-ui-kit-v2/core/misc/point.png" style="left: 76%">
          </div>
        </div>
      </div> -->
    <!-- </div> -->
      </div>
      <!-- {{ desktopData }} -->
      <div v-if="activeTab === 'mobile'">
      <div class="w-100 d-flex flex-column rounded-3 pb-4 bg-white" style="border-color: #E2E8F0;"> 
          <div class="w-100 d-flex align-items-center justify-content-between pb-4"> 
              <h4 class="text-color">Diagnose performance issues</h4>
              <a :href="page_speed_url" target="_blank" rel="noopener noreferrer" class="btn btn-secondary d-flex align-items-center" role="button">Go to PageSpeed Insights</a>
              <div class="d-flex align-items-center justify-content-center gap-3">
                  <p class="text-color">Last updated: {{ lastUpdated }}</p>
              </div>
          </div>
          <div v-for="(issue, index) in mobileData.performanceIssues" :key="index" class="w-100 d-flex flex-column justify-content-between mb-2" style="border-color: #E2E8F0;">
              <div class="d-flex align-items-center">
                  <div class="me-2 d-flex flex-row align-items-center justify-content-between pr-4" style="width: 16.666667%;">
                      <p class="text-dark font-weight-semi-bold" style="font-size: 1rem;">{{ issue.name }}</p>
                      <span class="ms-3 font-weight-bold" style="font-size: 1.25rem;"><strong>{{ truncateNum(issue.score) }}</strong></span>
                  </div>
                  <div class="ms-2 rounded-pill" :style="{backgroundColor: getBackgroundScoreColor(issue.score) }" style="width: 83.333333%; height: 1rem;">
                      <div class="h-100 rounded-pill" :style="{width: issue.score + '%', backgroundColor: issue.score !== 'N/A' ? getScoreColor(issue.score) : '#DADCE0' }"></div>
                  </div>
              </div>
          </div>
      </div>
      <!-- <div class="pb-4 pt-4 rounded-3 bg-white"> -->
      <!-- <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <h4 class="text-color">Core Web Vitals assessment:</h4>
        </div>
      </div> -->
      <!-- <div class="row">
        <div v-for="(vital, index) in mobileData.coreWebVitals" :key="index" class="col-sm-6 d-flex flex-column mb-4">
          <div class="d-flex align-items-center">
            <p class="text-dark font-weight-semi-bold">{{ formatVitalName(vital.name) }}</p>
          </div>
          <div class="d-flex align-items-center" style="position: relative; left: vital.percentile + '%';">
            <p class="font-weight-bold mb-3" :style="{color: vital.percentile !== 'N/A' ? '#22C55E' : '#DADCE0', left: isNaN(formatMetricValue(vital.name, vital.percentile)) ? 75 + '%' : 76 + '%', position: 'relative'}">{{ formatMetricValue(vital.name, vital.percentile) }}</p>
          </div>
          <div :style="{backgroundColor: vital.percentile !== 'N/A' ? '#22C55E' : '#DADCE0'}" style="height: 4px; border-radius: 32px; position: relative;">
            <template v-for="(distribution, dIndex) in vital.distributions">
              <div :key="dIndex" :style="{height: '100%', backgroundColor: getDistributionColor(dIndex), width: distribution.proportion + '%', position: 'absolute', left: calculateLeftPosition(vital.distributions, dIndex)}"></div>
            </template>
            <img :style="{position: 'absolute', bottom: 0}" class="absolute" src="../../../../../src/styles/libs/gig-ui-kit-v2/core/misc/point.png" style="left: 76%">
          </div>
        </div>
      </div> -->
    <!-- </div> -->
      </div>
      
      <!-- <div class="w-100 pt-4 d-flex flex-column bg-white" style="border-color: #E2E8F0;"> 
            <div class="w-100 d-flex flex-column justify-content-between rounded-3 p-3 bg-secondary opacity-75">
                <div class="row">
                    <div class="col-4 d-flex p-2 font-weight-semi-bold">
                        <i class="uil uil-calendar-alt mr-2"></i> 
                        <p>Latest 28-day collection period</p>
                    </div>
                    <div class="col-4 d-flex p-2 font-weight-semi-bold">
                        <i class="uil uil-desktop mr-2"></i> 
                        <p>Various mobile devices</p>
                    </div>
                    <div class="col-4 d-flex p-2 font-weight-semi-bold">
                        <i class="uil uil-ellipsis-h mr-2"></i> 
                        <p>Many samples (Chrome UX Report)</p>
                    </div>
                    <div class="col-4 d-flex p-2 font-weight-semi-bold">
                        <i class="uil uil-stopwatch mr-2"></i> 
                        <p>Full visit durations</p>
                    </div>
                    <div class="col-4 d-flex p-2 font-weight-semi-bold">
                        <i class="uil uil-wifi-slash mr-2"></i> 
                        <p>Various network connections</p>
                    </div>
                    <div class="col-4 d-flex p-2 font-weight-semi-bold">
                        <i class="uil uil-file-alt mr-2"></i> 
                        <p>All Chrome Versions</p>
                    </div>
                </div>
            </div>
        </div> -->
      </div>
  </div>
</template>

<style scoped>
p {
  margin: 0;
}
button{
  height: 18px;
}
.second-button{
  height: 34px;
  font-weight: 600;
    color: #17182F;
    text-align: center;
    background-color: transparent;
    border: 1px solid gray;
    padding: 14px 24px;
  /* border: 5px solid red; */
}
.second-button.active{
  background-color: #1642A8;
  color: white;
}
h4{
  margin-bottom: 0;
}
</style>

<script>
import GooglePageSpeedInsightService from '@services/GooglePageSpeedInsightService'

export default {
  name: 'PageSpeedInsights',
  data() {
  return {
    desktopData: {
        performanceIssues: [
          { name: 'Performance', score: 'N/A' },
          { name: 'Accessibility', score: 'N/A' },
          { name: 'Best Practices', score: 'N/A' },
          { name: 'SEO', score: 'N/A' }
        ],
        coreWebVitals: [
          { name: 'CUMULATIVE_LAYOUT_SHIFT_SCORE', percentile: 'N/A', distributions: [] },
          { name: 'EXPERIMENTAL_TIME_TO_FIRST_BYTE', percentile: 'N/A', distributions: [] },
          { name: 'FIRST_CONTENTFUL_PAINT_MS', percentile: 'N/A', distributions: [] },
          { name: 'FIRST_INPUT_DELAY_MS', percentile: 'N/A', distributions: [] },
          { name: 'INTERACTION_TO_NEXT_PAINT', percentile: 'N/A', distributions: [] },
          { name: 'LARGEST_CONTENTFUL_PAINT_MS', percentile: 'N/A', distributions: [] }
        ],
      },
    mobileData: {
      performanceIssues: [
        { name: 'Performance', score: 'N/A' },
        { name: 'Accessibility', score: 'N/A' },
        { name: 'Best Practices', score: 'N/A' },
        { name: 'SEO', score: 'N/A' }
      ],
      coreWebVitals: [
        { name: 'CUMULATIVE_LAYOUT_SHIFT_SCORE', percentile: 'N/A', distributions: [] },
        { name: 'EXPERIMENTAL_TIME_TO_FIRST_BYTE', percentile: 'N/A', distributions: [] },
        { name: 'FIRST_CONTENTFUL_PAINT_MS', percentile: 'N/A', distributions: [] },
        { name: 'FIRST_INPUT_DELAY_MS', percentile: 'N/A', distributions: [] },
        { name: 'INTERACTION_TO_NEXT_PAINT', percentile: 'N/A', distributions: [] },
        { name: 'LARGEST_CONTENTFUL_PAINT_MS', percentile: 'N/A', distributions: [] }
      ],
      },
    activeTab: 'mobile',
    lastUpdated: 'N/A',
    page_speed_url: '',
  };
},
  created() {
    this.getGooglePageSpeedInsightsData();
  },
  methods: {
    formatIssueName(key) {
    if (key === 'best-practices') return 'Best Practices';
    if (key === 'seo') return 'SEO';

    return key.split('-')
              .map(part => part.charAt(0).toUpperCase() + part.slice(1))
              .join(' ');
  },

  getGooglePageSpeedInsightsData() {
    GooglePageSpeedInsightService.getOne(this.$route.params['id'])
      .then(response => {
        const results = response.data.result;
        const latestData = results.reduce((prev, current) => {
          return (prev.created_at > current.created_at) ? prev : current;
        }, results[0]); 

        const site_url = results.length > 0 ? results[0].site_url : '';

        this.page_speed_url = 'https://developers.google.com/speed/pagespeed/insights/?url=' + site_url;
        this.lastUpdated = latestData ? new Date(latestData.created_at).toLocaleString() : 'N/A';

        if (latestData) {
          const parsedData = JSON.parse(latestData.data);
          console.log(JSON.parse(latestData.data));

          ['desktop', 'mobile'].forEach((type) => {
            if (parsedData[type]) {
              this[`${type}Data`].performanceIssues = parsedData[type].lightHouseData.lightHouseResultsData && Object.keys(parsedData[type].lightHouseData.lightHouseResultsData).length > 0
                ? Object.entries(parsedData[type].lightHouseData.lightHouseResultsData).map(([key, value]) => ({
                    name: this.formatIssueName(key),
                    score: value.score,
                  }))
                : this[`${type}Data`].performanceIssues;

              this[`${type}Data`].coreWebVitals = parsedData[type].lightHouseData.loadingExperienceMetricsData && Object.keys(parsedData[type].lightHouseData.loadingExperienceMetricsData).length > 0
                ? Object.values(parsedData[type].lightHouseData.loadingExperienceMetricsData).map(vital => ({
                    ...vital,
                    distributions: vital.distribution ? this.calculateDistributions(vital.distribution) : [],
                  }))
                : this[`${type}Data`].coreWebVitals;
            }
          });
        } else {
          console.log("No latest data available");
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  },
  calculateDistributions(distributions) {
      return distributions.map(distribution => ({
        ...distribution,
        proportion: Math.round(distribution.proportion * 100),
      }));  
    },
  getDistributionColor(dIndex) {
    const colors = ['#22C55E', // Green
                    '#F59E0B', // Yellow
                    '#EF4444']; // Red
    return colors[dIndex]; 
  },
  formatMetricValue(metricName, value) {
    if (value === 'N/A') return 'N/A'; 

    const metricsInSeconds = ['FIRST_CONTENTFUL_PAINT_MS', 'LARGEST_CONTENTFUL_PAINT_MS', 'EXPERIMENTAL_TIME_TO_FIRST_BYTE'];
    const metricsInMilliseconds = ['FIRST_INPUT_DELAY_MS', 'INTERACTION_TO_NEXT_PAINT'];

    let formattedValue = value;

    if (metricsInSeconds.includes(metricName)) {
      // console.log(metricName, value);
      formattedValue = (value / 1000).toFixed(1) + ' s';
    } else if (metricsInMilliseconds.includes(metricName)) {
      // console.log(metricName, value);

      formattedValue += ' ms';
    }else{
      formattedValue = (value * 0.01);
    }

    return formattedValue;
  },
  truncateNum(num){
        if(this.isText(num)){
          return 'N/A';
        }else{
          return Math.trunc(num);
        }
  },
  isText(variable) {
          return typeof variable === 'string';
  },
  formatVitalName(name) {
    let formattedName = name.replace('_MS', '');
    formattedName = formattedName.replace(/_/g, ' ');
    formattedName = formattedName
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return formattedName;
  },
    calculateLeftPosition(distributions, dIndex) {
      // console.log(distributions, dIndex);
      let left = 0;
      for (let i = 0; i < dIndex; i++) {
        left += distributions[i].proportion;
      }
      return left + '%';
    },
    getScoreColor(score) {
      if (score >= 90) return '#4ade80';
      if (score >= 50) return '#facc15';
      return '#f87171';
    },
    getBackgroundScoreColor(score) {
      if (score < 50) return '#FECACA';
      if (score < 90) return '#FEF3C7';
      return '#BBF7D0';
    },
    getVitalColor(percentile) {
      if (percentile < 50) return '#22C55E';
      if (percentile < 90) return '#F59E0B';
      return '#EF4444';
    },
  }
}
</script>
