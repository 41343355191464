<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close" v-if="show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add link logic</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <dynamic-form
          :inModal="true"
          v-if="object"
          ref="form"
          formType="create"
          :formFields="structure.list"
          :path="structure.config.url"
          :structure="structure"
          :object="object"
          :on-submit="submit"
          submit-label="Add"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DynamicForm from '@/components/fields/DynamicForm'
import AffiliateLinkLogicModel from '@models/AffliateLinkLogic'

export default {
  components: { DynamicForm },
  data() {
    return {
      loading: false,
      object: null,
      structure: {},
      config: AffiliateLinkLogicModel.getConfig(),
    }
  },
  methods: {
    show(affiliateId) {
      this.structure = AffiliateLinkLogicModel.getStructure()
      this.object = {
        affiliate_id: parseInt(affiliateId),
      }
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
    },
    submit() {
      this.$http
        .post('hercules/operators/affiliate-link-logics', this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Link logic for language created')
            this.$emit('languageAdded', response.data.result)
            this.close()
          }
          this.$refs.form.loading(false)
        })
        .catch(() => {
          this.showUnknownErrorMessage
          this.$refs.form.loading(false)
        })
    },
  },
}
</script>
