<template>
  <div>
    <div class="d-grid mb-3">
      <div v-for="(option, key) in getAdvancedActions()" :key="key" class="card card-clickable">
        <div class="card-body text-center pt-3 pr-3" @click="option.action">
          <div class="text-right mb-3">
            <span class="btn btn-outline-info btn-sm" @click.stop="openModalInformation(option.key)">
              <i class="uil uil-info-circle"></i>Help
            </span>
          </div>
          <span class="btn btn-lg icon-button rounded-pill pointer-events-none" :class="option.color">
            <i :class="option.icon"></i>
          </span>
          <span class="h3 d-block mt-3">{{ option.name }}</span>
          <p class="additional">{{ option.description }}</p>
        </div>
      </div>
    </div>
    <div class="card" v-if="hasPermission('misc.sites/advanced-page-settings')">
      <div class="card-header d-flex flex-wrap justify-content-between">
        <h3 class="flex-grow-1">Raw JavaScript injection</h3>
        <div>
          <span @click="openModalInformation('script')" class="btn btn-outline-info btn-md">
            <i class="uil uil-info-circle"></i>Help
          </span>
        </div>
      </div>
      <div class="card-body multi-column-form" v-if="page">
        <div class="row">
          <div class="col">
            <base-checkbox
              label="Raw JavaScript Active"
              v-model="page.page_raw_js_active"
              :value="page.page_raw_js_active"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-code-text
              label="Raw JavaScsript"
              :code="page.raw_js"
              language="javascript"
              @change="(value) => (page.raw_js = value)"
            />
          </div>
        </div>
      </div>
    </div>
    <page-type-change-modal ref="pageTypeChangeModal" v-on="$attrs" @refresh="$emit('refresh')" />
    <page-clone-modal ref="pageCloneModal" v-on="$attrs" />
    <page-path-change-modal ref="pagePathChangeModal" v-on="$attrs" @refresh="$emit('refresh')" />
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCodeText from '@atoms/fields/base-code-text.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'

import PageTypeChangeModal from '@molecules/cms/pages/type-change-modal.vue'
import PagePathChangeModal from '@molecules/cms/pages/path-change-modal.vue'
import PageCloneModal from '@molecules/cms/pages/clone-modal.vue'

import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import PagesService from '@services/PagesService'
import InformationModal from '@molecules/cms/modals/information-modal'
import confluenceIds from '@constants/confluence-ids'

const confirmDelete = async (title, content, actionButtonTitle, actionButtonIconClass) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle, actionButtonIconClass })
}

export default {
  components: {
    BaseInput,
    BaseCheckbox,
    BaseCodeText,
    PageTypeChangeModal,
    PagePathChangeModal,
    PageCloneModal,
    InformationModal,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.validateJSON(this.page.json_schema)
  },
  data() {
    return {
      jsonValidated: null,
      confluenceId: 0,
    }
  },
  computed: {
    url() {
      return `https://${this.page.site_name}/${this.page.path}`
    },
    charCountTitle() {
      return `${this.page.meta_title.length} / 60`
    },
    charCountDescription() {
      return this.page.meta_description ? `${this.page.meta_description.length} / 160` : 0
    },
  },
  methods: {
    openModalInformation(key) {
      switch (key) {
        case 'page-type':
          this.confluenceId = confluenceIds.pages.advanced.change_type_page.id
          break
        case 'clone':
          this.confluenceId = confluenceIds.pages.advanced.clone_page.id
          break
        case 'delete':
          this.confluenceId = confluenceIds.pages.advanced.delete_page.id
          break
        case 'script':
          this.confluenceId = confluenceIds.pages.advanced.raw_jw_inj.id
          break
      }
      setTimeout(
        function () {
          this.$refs.informationModal?.show()
        }.bind(this),
        200
      )
    },
    getAdvancedActions() {
      return [
        {
          name: 'Change page type',
          key: 'page-type',
          icon: 'uil uil-edit',
          color: 'bg-green',
          description: 'Without losing existing modules',
          action: () => this.$refs.pageTypeChangeModal?.show(this.page),
        },
        {
          name: 'Change page path',
          key: 'page-path',
          icon: 'uil uil-link',
          color: 'bg-purple',
          description: 'Also updates internal links to this page',
          action: () => this.$refs.pagePathChangeModal?.show(this.page),
        },
        {
          name: 'Clone page',
          key: 'clone',
          icon: 'uil uil-copy',
          color: 'bg-yellow-75',
          description: 'To any site or market',
          action: () => this.$refs.pageCloneModal?.show(this.page),
        },
        {
          name: 'Delete page',
          key: 'delete',
          icon: 'uil uil-trash',
          color: 'bg-red-75',
          description: 'Wipe off the face of the earth',
          action: this.remove,
        },
      ]
    },
    validateJSON(value) {
      try {
        this.page.json_schema = value
        if (this.page.json_schema.length > 0) {
          JSON.parse(this.page.json_schema)
          this.jsonValidated = true
        } else {
          this.jsonValidated = null
        }
      } catch (e) {
        this.jsonValidated = false
      }
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure want to delete this page?', 'Delete page')) {
        PagesService.remove(this.page)
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.$emit('page-advanced-event', true)
              this.showSuccessMessage(`Page removed`)
              this.$router.push('/site-pages')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
