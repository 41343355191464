<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="edit"
      :formFields="structure.list"
      :inCard="true"
      :structure="structure"
      :path="config.url"
      :object="object"
      :on-submit="submit"
    />
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import Teams from '@models/sport/Teams'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import SportsTeamService from '@services/SportsTeamService'

export default {
  components: { DynamicForm, ConfirmDialog },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Teams.getConfig(),
    }
  },
  mounted() {
    this.getTeam()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} General`)
  },

  methods: {
    getTeam() {
      SportsTeamService.getOne(this.$parent.$parent.sportsTeam.id).then((response) => {
        this.object = response.data.result[0]
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = Teams.getStructure()
    },
    submit() {
      const self = this
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return SportsTeamService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getTeam()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
  },
}
</script>
