<template>
  <data-table
    model="Game"
    service-file="GamesService"
    path="hercules/games/games?with=software"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :csvColumnTemplates="csvColumnTemplates()"
    :create-defaults="createDefaults()"
    :show-edit-modal="false"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'providers', label: 'Provider', type: 'text', filterable: true },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          sortable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE,
        },
        {
          field: 'iframe_is_valid',
          label: 'Iframe valid',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: false,
          customOptions: [
            { label: 'Valid', id: 1 },
            { label: 'Not Valid', id: 0 },
            { label: 'Not verified', id: 2 },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
        },
        { field: 'iframe_validation_date', type: 'csv', label: 'Iframe Validation Date' },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    csvColumnTemplates() {
      return Object.assign({
        iframe_is_valid: function (row) {
          return row.iframe_is_valid === null ? 'Not Verified' : row.iframe_status_message.split(':')[1]
        },
        iframe_validation_date: function (row) {
          return row.iframe_is_valid === null
            ? 'Not Verified'
            : row.iframe_status_message
            ? row.iframe_status_message.split(':')[0]
            : row.iframe_status_message.split(':')[1]
        },
      })
    },
    columnTemplates() {
      return Object.assign({
        providers: function (row) {
          return row.software.name
        },
        iframe_is_valid: function (row) {
          if (row.iframe_is_valid === null) {
            return (
              <span data-tooltip="Not verified">
                <i class="uil uil-question-circle text-dark"></i>
              </span>
            )
          }
          let message = row.iframe_status_message
          return row.iframe_is_valid ? (
            <span data-tooltip={message}>
              <i class="uil uil-check-circle text-success"></i>
            </span>
          ) : (
            <span data-tooltip={message}>
              <i class="uil uil-ban text-danger"></i>
            </span>
          )
        },
      })
    },
    createDefaults() {
      return {
        enabled: 0,
      }
    },
  },
}
</script>
