/* eslint-disable no-unused-vars */
export default function lastActiveNodes(state, typesOrGroup) {
  const { from, to } = state.selection
  let types

  if (typeof typesOrGroup === 'string') {
    types = Object.entries(state.schema.nodes)
      .filter(([name, nodeType]) => nodeType.groups.includes(typesOrGroup))
      .map(([name, nodeType]) => ({ type: nodeType }))
  } else {
    types = typesOrGroup
    for (const item of types) {
      item.type = item.type ? state.schema.nodes[item.type] : null
    }
  }

  let lastNode = null
  let lastMatchedType = null
  const matchedTypes = new Set()
  const notFoundTypes = new Set(types)

  state.doc.nodesBetween(from, to, (node, pos, parent) => {
    if (notFoundTypes.size == 0) return false
    if (!node.isText) {
      const matchedType = types
        .filter(item => {
          if (!item.type) return true
          return node.type.name === item.type.name
        })
        .find(item => {
          if (!item.attributes) return true
          return Object.keys(item.attributes).every(key => node.attrs[key] === item.attributes[key])
        })
      if (matchedType) {
        if (lastMatchedType && lastNode && lastNode !== parent) {
          matchedTypes.add(lastMatchedType)
          notFoundTypes.delete(lastMatchedType)
        }
        lastNode = node
        lastMatchedType = matchedType
      }
    }
  })

  if (lastMatchedType) {
    matchedTypes.add(lastMatchedType)
  }
  return Array.from(matchedTypes).map(item => item.type?.name)
}
