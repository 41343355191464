<template>
  <div class="modal" ref="modal" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg2">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Bulk add <strong>{{ operatorName }}</strong> to toplists
          </h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <base-input v-model="position" :required="true" type="number" min="1" label="Toplist position" />
          <multi-select-modal
            :draggable="false"
            tableClass="table table-sm"
            :value="value"
            @valuePicked="valuePicked"
            :parameters="{}"
            type="top_list"
            customLabel="Top lists"
          />
        </div>
        <div class="modal-footer d-flex py-3">
          <button class="btn btn-secondary" :class="{ loading: loading }" @click="close()">Cancel</button>
          <button class="btn btn-primary ml-2" :class="{ loading: loading }" @click="submit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import TopListsService from '@services/TopListsService'
import MultiSelectModal from '@molecules/fields/page-multi-select/multi-select-modal.vue'

export default {
  components: { BaseInput, MultiSelectModal },
  data() {
    return {
      loading: false,
      operator: null,
      value: '',
      position: '',
    }
  },
  computed: {
    operatorName() {
      return this.operator ? this.operator.name : ''
    },
  },
  methods: {
    submit() {
      this.loading = true
      const intPosition = parseInt(this.position)
      if (isNaN(intPosition) || intPosition <= 0 || intPosition != this.position) {
        this.showErrorMessages('Position is required and must be whole number greater than 0')
        this.loading = false
        return
      }
      const data = {
        position: intPosition,
        ids: this.value.split(','),
        operator_id: this.operator.id,
      }
      TopListsService.bulkAddOperator(data)
        .then((response) => {
          if (response.data.messages) {
            this.loading = false
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage(`The operator ${this.operatorName} was added to the selected toplists`)
            this.$emit('refresh')
            this.close()
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    show(operator) {
      document.getElementById('modal').appendChild(this.$refs.modal)
      this.operator = operator
      this.$refs.modal.classList.add('show')
    },
    valuePicked(value) {
      this.value = value
    },
    close() {
      this.loading = false
      this.value = ''
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    columns() {
      return [
        { field: 'label', type: 'text', label: 'Label', filterable: true },
        { field: 'site_name', type: 'text', label: 'Site', filterable: true },
        { field: 'market_name', type: 'text', label: 'Market', filterable: true },
      ]
    },
    columnTemplates() {
      return Object.assign({
        market_name: function (row) {
          return row.market ? row.market.label : ''
        },
      })
    },
  },
}
</script>
