<template>
  <data-table
    model="cms/Template"
    service-file="TemplatesService"
    path="hercules/sites/templates?with=enabled_sections,site"
    :columns="columns()"
    :show-edit-modal="false"
    :column-templates="columnTemplates()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import BasicOptions from '@mixins/BasicOptions'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'site', type: 'text', label: 'Site', filterable: true },
        { field: 'sections', type: 'number', label: 'Sections enabled', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          sections: function(row) {
            if (row.enabled_sections) {
              return row.enabled_sections.map(() => '■').join(' ')
            }
            return ''
          }
        },
        {
          site: function(row) {
            return row.site.name
          }
        }
      )
    }
  }
}
</script>
