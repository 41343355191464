import AffiliateList from '@pages/dms/affiliates/affiliates.vue'
import Affiliate from '@pages/dms/affiliates/edit/edit.vue'
import AffiliateEditForm from '@pages/dms/affiliates/edit/form.vue'
import AffiliateHome from '@pages/dms/affiliates/home/home.vue'
import AffiliateGameExtraFields from '@pages/dms/affiliates/game-extra-fields/game-extra-fields.vue'
import AffiliateMarketData from '@pages/dms/affiliates/markets-data/markets-data.vue'
import AffiliateLinkedMarkets from '@pages/dms/affiliates/markets-data/linked-markets.vue'
import AffiliateLinkLogic from '@pages/dms/affiliate-link-logic/affiliate-link-logic.vue'
import AffiliateLocationGroups from '@pages/dms/affiliate-location-groups/affiliate-location-groups.vue'
import AffiliateOperatorExtraFields from '@pages/dms/affiliates/operator-extra-fields/operator-extra-fields.vue'
import AffiliateOperators from '@pages/dms/affiliates/operators/operators.vue'
import AffiliateGames from '@pages/dms/affiliates/games/games.vue'
import AffiliateLinks from '@pages/dms/affiliates/affiliate-links.vue'
import AffiliateGranularTrackingLinks from '@pages/dms/affiliates/granular-tracking-links/granular-tracking-links.vue'
import Ribbons from '@pages/dms/affiliates/ribbons/ribbons.vue'
import CryptoExchangeList from '@pages/dms/crypto-exchanges/crypto-exchanges.vue'
import CryptoExchange from '@pages/dms/crypto-exchanges/edit/edit'
import CryptoExchangeHome from '@pages/dms/crypto-exchanges/edit/form.vue'
import CryptoWallet from '@pages/dms/crypto-wallets/crypto-wallets.vue'
import CryptoWalletEdit from '@pages/dms/crypto-wallets/edit/edit.vue'
import CryptoWalletHome from '@pages/dms/crypto-wallets/edit/form.vue'

import OperatorList from '@pages/dms/operators/operators.vue'
import Operator from '@pages/dms/operators/edit/edit.vue'
import OperatorHome from '@pages/dms/operators/edit/form.vue'
import OperatorMarketsData from '@pages/dms/operators/markets-data/markets-data.vue'
import OperatorWagering from '@pages/dms/operators/wagering/wagering.vue'
import OperatorSitesData from '@pages/dms/operators/sites-data/sites-data.vue'
import OperatorToplists from '@pages/dms/operators/toplists.vue'

import GamesList from '@pages/dms/games/games.vue'
import Game from '@pages/dms/games/edit/edit.vue'
import GamesHome from '@pages/dms/games/edit/form.vue'
import GamesMarkets from '@pages/dms/games/markets-data/markets-data.vue'
import GamesSites from '@pages/dms/games/sites-data/sites-data.vue'
import GamesScreenshots from '@pages/dms/games/screenshots-data/screenshots-data.vue'

import TopListLists from '@pages/dms/toplists/toplists.vue'
import TopList from '@pages/dms/toplists/edit/edit.vue'
import TopListGeneral from '@pages/dms/toplists/edit/form.vue'
import TopListItems from '@pages/dms/toplists/items/edit-wrapper.vue'
import TopListPages from '@pages/dms/toplists/edit/pages.vue'

import TranslationsList from '@pages/dms/translations/translations.vue'

import PrefilledToplists from '@pages/dms/prefilled-toplists/prefilled-toplists.vue'
import PrefilledTopList from '@pages/dms/prefilled-toplists/edit/edit.vue'
import PrefilledTopListGeneral from '@pages/dms/prefilled-toplists/edit/form.vue'
import PrefilledTopListPages from '@pages/dms/prefilled-toplists/edit/pages.vue'
import PrefilledTopListPrefilled from '@pages/dms/prefilled-toplists/edit/prefilled-toplists.vue'

export default [
  {
    path: '/affiliates',
    component: AffiliateList,
    name: 'Affiliate Sites Settings',
    meta: {
      auth: true,
      parent: 'Data Management',
      permission: 'view.operators/affiliates',
      name: 'Affiliate Sites Settings',
    },
  },
  {
    path: '/tracking-links',
    component: AffiliateLinks,
    meta: {
      auth: true,
      parent: 'Data Management',
      permission: 'view.operators/affiliate-links',
      name: 'Tracking links',
    },
  },
  {
    path: '/affiliates/:id',
    component: Affiliate,
    children: [
      {
        path: '', // Default path for "Home"
        component: AffiliateHome, // Changed to AffiliateHome component
        meta: { parent: 'Data Management', name: 'Home' },
      },
      {
        path: 'edit', // New path for "Edit"
        component: AffiliateEditForm, // Existing AffiliateEdit component
        meta: { parent: 'Data Management', name: 'General Settings', permission: 'view.operators/affiliates' },
        permission: 'view.operators/affiliates'
      },
      {
        path: 'operators',
        component: AffiliateOperators,
        meta: { parent: 'Data Management', name: 'Operator Sites Data' },
      },
      {
        path: 'games',
        component: AffiliateGames,
        meta: { parent: 'Data Management', name: 'Games Sites Data' },
      },
      {
        path: 'markets-data',
        component: AffiliateMarketData,
        meta: { parent: 'Data Management', name: 'Markets Data' },
      },
      {
        path: 'link-logic',
        component: AffiliateLinkLogic,
        meta: { parent: 'Data Management', name: 'Link Logic' },
      },
      {
        path: 'location-groups',
        component: AffiliateLocationGroups,
        meta: { parent: 'Data Management', name: 'Location Groups' },
      },
      {
        path: 'linked-markets',
        component: AffiliateLinkedMarkets,
        meta: { parent: 'Data Management', name: 'Linked Markets' },
      },
      {
        path: 'extra-fields-operators',
        component: AffiliateOperatorExtraFields,
        meta: { parent: 'Data Management', name: 'Extra Operator fields' },
      },
      // {
      //   path: 'extra-fields-affiliates',
      //   component: AffiliateExtraFields,
      //   meta: { parent: 'Data Management', name: 'Extra Affiliate fields'}
      // },
      {
        path: 'extra-fields-games',
        component: AffiliateGameExtraFields,
        meta: { parent: 'Data Management', name: 'Extra Game fields' },
      },
      {
        path: 'granular-tracking-links',
        component: AffiliateGranularTrackingLinks,
        meta: { parent: 'Data Management', name: 'Granular tracking links' },
      },
      {
        path: 'ribbons',
        component: Ribbons,
        meta: { parent: 'Data Management', name: 'Ribbons', permission: 'view.globals/ribbons' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      permission: 'view.operators/affiliates',
      name: 'Affiliate Sites Settings',
    },
  },
  {
    path: '/operators',
    component: OperatorList,
    name: 'Operators',
    meta: {
      auth: true,
      parent: 'Data Management',
      permission: 'view.operators/operators',
      name: 'Operators',
    },
  },
  {
    path: '/operators/:id',
    component: Operator,
    name: 'Operators Edit',
    children: [
      {
        path: '',
        component: OperatorHome,
        meta: {
          parent: 'Data Management',
          auth: true,
          permission: 'view.operators/operators',
          name: 'General',
        },
      },
      {
        path: 'wagering',
        component: OperatorWagering,
        meta: { parent: 'Data Management', name: 'Wagering', permission: 'view.operators/generic-bonuses' },
      },
      {
        path: 'markets-data',
        component: OperatorMarketsData,
        meta: { parent: 'Data Management', name: 'Markets data', permission: 'view.operators/bonuses' },
      },
      {
        path: 'sites-data',
        component: OperatorSitesData,
        meta: {
          parent: 'Data Management',
          name: 'Sites data',
          permission: 'view.operators/affiliate-operators',
        },
      },
      {
        path: 'toplists',
        component: OperatorToplists,
        meta: { parent: 'Data Management', name: 'Toplists', permission: 'view.toplists/lists' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Operators',
    },
  },
  {
    path: '/games',
    component: GamesList,
    name: 'Games',
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Games',
      permission: 'view.games/games',
    },
  },
  {
    path: '/games/:id',
    component: Game,
    children: [
      {
        path: '',
        component: GamesHome,
        meta: { parent: 'Data Management', name: 'Games', permission: 'view.games/games' },
      },
      {
        path: 'markets',
        component: GamesMarkets,
        meta: { parent: 'Data Management', name: 'Markets', permission: 'view.games/games' },
      },
      {
        path: 'sites',
        component: GamesSites,
        meta: { parent: 'Data Management', name: 'Sites', permission: 'view.games/games' },
      },
      {
        path: 'screenshots',
        component: GamesScreenshots,
        meta: { parent: 'Data Management', name: 'Screenshots', permission: 'view.games/games' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Games',
      permission: 'view.games/games',
    },
  },
  {
    path: '/toplists',
    component: TopListLists,
    name: 'Toplists',
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Toplists',
      permission: 'view.toplists/lists',
    },
  },
  {
    path: '/toplists/:id',
    component: TopList,
    children: [
      {
        path: '',
        component: TopListGeneral,
        meta: { parent: 'Data Management', name: 'General', permission: 'view.toplists/lists' },
      },
      {
        path: 'items',
        component: TopListItems,
        meta: { parent: 'Data Management', name: 'Items', permission: 'view.toplists/lists' },
      },
      {
        path: 'pages',
        component: TopListPages,
        meta: { parent: 'Data Management', name: 'Pages', permission: 'view.toplists/lists' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Toplists',
      permission: 'view.toplists/lists',
    },
  },
  {
    path: '/translations',
    component: TranslationsList,
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Translations',
      permission: 'view.translations/translations',
    },
  },
  {
    path: '/crypto-wallets',
    component: CryptoWallet,
    name: 'Crypto Wallets',
    meta: {
      parent: 'Data Management',
      auth: true,
      name: 'Crypto Wallets',
      permission: 'view.cryptos/wallets',
    },
  },
  {
    path: '/crypto-wallets/:id',
    component: CryptoWalletEdit,
    children: [
      {
        path: '',
        component: CryptoWalletHome,
        meta: {
          parent: 'Data Management',
          auth: true,
          permission: 'view.cryptos/wallets',
          name: 'Crypto Wallets',
        },
      },
    ],
    meta: {
      parent: 'Data Management',
      auth: true,
      name: 'Crypto Wallets',
      permission: 'view.cryptos/wallets',
    },
  },
  {
    path: '/affiliate-link-logic',
    name: 'Affiliate link logic',
    component: AffiliateLinkLogic,
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Affiliate Link Logic',
      permission: 'view.cryptos/exchanges',
    },
  },
  {
    path: '/crypto-exchanges',
    name: 'Crypto Exchanges',
    component: CryptoExchangeList,
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Crypto Exchanges',
      permission: 'view.cryptos/exchanges',
    },
  },
  {
    path: '/crypto-exchanges/:id',
    component: CryptoExchange,
    children: [
      {
        path: '',
        component: CryptoExchangeHome,
        meta: {
          parent: 'Data Management',
          auth: true,
          permission: 'view.cryptos/exchanges',
          name: 'Exchanges',
          breadCrumb: 'Crypto Exchanges',
        },
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Crypto Exchange',
      permission: 'view.cryptos/exchanges',
    },
  },
  {
    path: '/prefilled-toplists',
    component: PrefilledToplists,
    name: 'Prefilled Toplists',
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Prefilled Toplists',
      permission: 'view.toplists/lists',
    },
  },
  {
    path: '/prefilled-toplists/:id',
    component: PrefilledTopList,
    children: [
      {
        path: '',
        component: PrefilledTopListGeneral,
        meta: { parent: 'Data Management', name: 'General', permission: 'view.toplists/lists' },
      },
      {
        path: 'items',
        component: TopListItems,
        meta: { parent: 'Data Management', name: 'Items', permission: 'view.toplists/lists' },
      },
      {
        path: 'pages',
        component: PrefilledTopListPages,
        meta: { parent: 'Data Management', name: 'Pages', permission: 'view.toplists/lists' },
      },
      {
        path: 'prefilled',
        component: PrefilledTopListPrefilled,
        meta: { parent: 'Data Management', name: 'Toplists', permission: 'view.toplists/lists' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Prefilled Toplists',
      permission: 'view.toplists/lists',
    },
  },
]
