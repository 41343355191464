<template>
  <div>
    <CoreWebVitals/>
    <PageSpeedInsights/>
  </div>
</template>

<script>
import PageSpeedInsights from '@atoms/misc/page-speed-insights.vue'
import CoreWebVitals from '@atoms/misc/core-web-vitals.vue'

export default {
  components:{
    PageSpeedInsights,
    CoreWebVitals
  }
}
</script>
