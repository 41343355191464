import axios from 'axios'
import { store } from '@store/index'

axios.defaults.baseURL = process.env.VUE_APP_SERVER_SIDE
axios.defaults.headers['pusherToken'] = store.state.app.uniqueKey
axios.defaults.withCredentials = true

export default {
  root: process.env.VUE_APP_SERVER_SIDE,
}
