<template>
<div class="modal" ref="modalMonitoring" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title">Migrated or Merged Accounts</h3>
            <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
            <MigrationFromIdsTable v-if="localObject.migrated_from_ids" :object="localObject"/>
            <MigrationToTable :object="localObject"/>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import MigrationToTable from './migration-to-table.vue'
import MigrationFromIdsTable from './migration-from-ids-table.vue'
export default {
    components: { MigrationToTable, MigrationFromIdsTable },
    props: {
        object: {
            type: [Array, Object],
        }
    },
    data() {
        return {
            loading: false,
            localObject: {}
        }
    },
    watch: {
        object: {
            handler(newVal) {
                this.localObject = { ...newVal };
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        show() {
            this.$refs.modalMonitoring.classList.add('show')
        },
        close() {
            this.$refs.modalMonitoring.classList.remove('show')
            this.localObject = {}
        },
    }
}
</script>
<style scoped>
.modal-dialog {
    max-width: fit-content !important;
}
</style>