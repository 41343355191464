<template>
    <div class="progress-circle" title="The performance score is derived from web vitals: CLS, FCP, LCP, TTFB, and INP, weighted by importance. Scores are based on how well each metric meets 'good' vs. 'poor' thresholds, averaged to reflect overall site performance.">
      <svg viewBox="0 0 36 36" class="circular-chart" :style="{ maxWidth: maxWidth + 'px', maxHeight: maxHeight + 'px' }">
        <path class="circle-bg"
          :style="{ stroke: backgroundColor, fill: circleFillColor }"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path class="circle"
          :stroke-dasharray="circumference + ',' + circumference"
          :style="{ strokeDashoffset: offset, stroke: progressColor }"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage" :style="{ fill: textColor }"> {{ isValidNumber ? `${value}` : 'No Data' }}</text>
      </svg>
    </div>
  </template>
  
  
  <script>
  export default {
    name: 'ProgressCircle',
    props: {
      value: {
        type: Number,
        required: true,
      },
      maxWidth: {
        type: Number,
        default: 48,
      },
      maxHeight: {
        type: Number,
        default: 48,
      },
    },
    computed: {
      isValidNumber() {
        return !isNaN(parseFloat(this.value)) && isFinite(this.value);
        },
      circumference() {
        return 2 * Math.PI * 15.9155;
      },
      offset() {
        return this.isValidNumber ? this.circumference * (1 - this.value / 100) : this.circumference;
        // return this.circumference * (1 - this.value / 100);
      },
      progressColor() {
        if (!this.isValidNumber) return '#ECECEC';
        if (this.value >= 90) return '#4caf50'; // Green
        if (this.value >= 50) return '#ff9800'; // Orange
        return '#f44336'; // Red
      },
      backgroundColor() {
        // Lighter versions of the colors
        if (!this.isValidNumber) return '#ECECEC';
        if (this.value >= 90) return '#EAF9ED'; // Light Green
        if (this.value >= 50) return '#FEF4E8'; // Light Orange
        return '#FEE7E9'; // Light Red
      },
      textColor() {
        // Use the same color as the progress for consistency
        return !this.isValidNumber ? '#838383' : this.progressColor;
      },
      circleFillColor() {
      // Define the logic for the circle's fill color dynamically
      // This example uses a lighter version of the progressColor for illustration
      // Adjust the logic as per your requirement
      if (!this.isValidNumber) return '#ECECEC';
      if (this.value >= 90) return '#e8f5e9'; // Lighter Green
      if (this.value >= 50) return '#fff3e0'; // Lighter Orange
      return '#ffebee'; // Lighter Red
    },
    },
  };
  </script>
  
  <style scoped>
  .progress-circle svg{
    width: 100%
  }
  .circular-chart {
    display: block;
    max-width: 48px;
    max-height: 48px;
  }
  .circle-bg {
    fill: none;
    stroke-width: 3.8;
  }
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }
  .percentage {
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }
  </style>
  