<template>
  <div class="row pt-1">
    <div class="col mx-auto py-5">
      <div class="text-center">
        <img src="hercules_logo.svg" alt="Hercules" height="120px" />
        <h1 class="mt-5">Your new password</h1>
      </div>
      <div class="card mx-auto" style="max-width: 450px">
        <div class="card-body">
          <div class="form-group">
            <label class="text-muted">Password</label>
            <input
              disabled
              type="text"
              id="password"
              autofocus="true"
              name="password"
              class="form-control"
              v-model="password"
              required
              :readonly="done"
            />
          </div>
          <button
            v-if="!done"
            type="submit"
            class="btn btn-block btn-info"
            v-bind:class="{ loading: isLoading }"
            @click="copyValue($attrs.value)"
          >
            <!-- v-clipboard:copy="password"
            v-clipboard:success="clipboardClicked" -->
            Copy new password
          </button>
        </div>
      </div>
      <p class="text-center">
        <router-link :to="{ path: '/login' }" class="text-bluegrey-1">Login to your account</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import AuthService from '@services/AuthService'

export default {
  data() {
    return {
      password: null,
      done: false,
      isLoading: false,
    }
  },
  created() {
    this.$parent.$parent.showSidebar = false
    this.$parent.$parent.showNavbar = false
    this.setTitle()
  },
  methods: {
    copyValue(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.showSuccessMessage('Password copied to your clipboard')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy', err)
        })
    },
  },
  mounted() {
    AuthService.resetPassword(this.$route.query.token)
      .then((response) => {
        if (response.data.success) {
          this.password = response.data.result.password
        }
      })
      .catch(() => {
        this.showErrorMessage('Token not found or no longer active')
        this.$router.push('/login')
      })
  },
}
</script>
