<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-sections` }"
            data-tooltip="Back to sections"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ $capitalize(section.name) }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(section.updated_at) }}</span>
          <span class="badge bg-azure ml-2">{{ section.site.name }}</span>
          <span class="badge bg-green ml-2" v-if="section.active">active</span>
          <span class="badge bg-black-25 ml-2" v-if="!section.active">inactive</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/site-sections/${section.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-sections/${section.id}/extra-fields` }" exact>
        <a>Extra fields</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/site-sections/${section.id}/styles` }"
        v-if="section.type == 'market'"
        exact
      >
        <a>Section Styles</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import SectionsService from '@services/SectionsService'

export default {
  created() {
    if (this.isIdMismatch) {
      this.getSection()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
    this.title = this.section?.name
  },
  data() {
    return {
      title: '',
    }
  },
  computed: {
    section() {
      return this.$store.state.editPage.model
    },
    isIdMismatch() {
      return Number(this.$route.params['id']) !== this.section?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    getSection() {
      SectionsService.getOne(this.$route.params['id'], { with: 'language,site' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Section not found') >= 0) {
                this.$router.push('/site-sections')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = this.section.name
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
