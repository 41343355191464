<template>
  <div class="embedded-sidebar col-lg-4 mb-3 form-group">
    <label v-if="emptyFilters">{{ noFiltersMessage }}</label>

    <div v-for="(filter, name) in filters" v-bind:key="name">
      <label>{{ filterLabel(name) }}</label>

      <span class="text-muted ml-1 clickable" v-if="showClear(name)" @click="clearFilters(name)">
        {{ clearText(name) }}
      </span>

      <input
        type="text"
        :ref="name"
        class="form-control form-control-md mb-2"
        placeholder="Search Filter"
        @input="searchFilter($event, name)"
      />

      <ul class="entity-tree multi row align-content-start w-100 filter-container mr-0 ml-0">
        <li class="col-lg-6 pl-0 pr-0" v-for="(item, key) in filteredItems(filter)" v-bind:key="item.id">
          <div class="checkbox">
            <input :id="item.id" type="checkbox" v-model="item.checked" />

            <label class="pl-1" :for="item.name"></label>
          </div>

          <span @click="togglefilter(name, key)">{{ $prettyLabels(item.name) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FilterService from '@services/FilterService'
import CategoryService from '@services/CategoryService'
import PageService from '@services/PagesService'

export default {
  computed: {
    emptyFilters() {
      return Object.keys(this.filters).length === 0
    },
  },
  data() {
    return {
      pageFilters: {},
      filters: {},
      filtersMap: {},
      noFiltersMessage: '',
    }
  },
  created() {
    CategoryService.get({
      site_id: this.parameters.site_id,
      market_id: this.parameters.market_id,
      page_type_id: this.pageTypeId,
    }).then((response) => {
      if (response.data.result && response.data.result.length > 0) {
        this.filtersMap = Object.assign({}, this.filtersMap, { ['page_categories']: 'categories' })

        response.data.result.forEach((item) => {
          item.checked = false
          item.show = true
        })
        this.filters = Object.assign({}, this.filters, {
          ['page_categories']: response.data.result,
        })
      }
    })

    this.getService().then((response) => {
      if (response.data.messages) {
        this.noFiltersMessage = response.data.messages
      } else {
        Object.entries(response.data.result.filter).map(([key]) => {
          this.filtersMap = Object.assign({}, this.filtersMap, { [key]: response.data.result.filter[key] })
          PageService.getFilters(Object.assign({}, this.parameters, { filter_name: key })).then((filter) => {
            if (filter.data.messages) {
              this.showErrorMessages(filter.data.messages)
            } else {
              filter.data.result.forEach((item) => {
                item.checked = false
                item.show = true
              })
              this.filters = Object.assign({}, this.filters, { [key]: filter.data.result })
            }
          })
        })
      }
    })
  },
  props: {
    parameters: {
      type: Object,
      default: () => {},
    },
    pageTypeId: {
      type: [Number, String],
    },
    type: {
      type: String,
    },
  },
  methods: {
    filteredItems(filter) {
      return filter.filter((n) => n.show)
    },
    filterLabel(name) {
      const filterName = this.capitalize(this.$prettyLabels(name))
      return name === 'page_categories' ? filterName : this.capitalize(this.type) + ' ' + filterName
    },
    resetFilter(name) {
      this.$refs[name][0].value = ''
    },
    getService() {
      switch (this.parameters.type) {
        case 'game':
          return FilterService.getGameFilters()
        case 'operator':
          return FilterService.getOperatorFilters()
      }
      return new Promise((resolve) => {
        resolve({ data: { messages: `No Filters Available` } })
      })
    },
    searchFilter(event, name) {
      this.filters[name].filter((item) => {
        if (item.name.toLowerCase().includes(event.target.value.toLowerCase())) {
          item.show = true
          return
        }

        item.show = false
      })

      this.$forceUpdate()
    },
    showClear(name) {
      return this.filters[name].filter((item) => item.checked).length > 0
    },
    displayShowAll(name) {
      return this.filters[name].filter((item) => !item.show).length > 0
    },
    clearText(name) {
      const selectedFilters = this.filters[name].filter((item) => item.checked).length
      if (selectedFilters > 0) {
        return 'Clear (' + selectedFilters + ')'
      }
      return 'Clear'
    },
    clearFilters(name) {
      this.filters[name].forEach((item) => (item.checked = false))
      this.$emit('filterChanged', this.getFilters())
    },
    togglefilter(filter, id) {
      this.filters[filter][id].checked = !this.filters[filter][id].checked
      this.$emit('filterChanged', this.getFilters())
    },
    getFilters() {
      let filtersObj = {}
      Object.entries(this.filters).map(([key]) => {
        Object.assign(filtersObj, {
          [this.filtersMap[key]]: this.filters[key]
            .filter((filter) => filter.checked)
            .map((filter) => filter.id),
        })
      })
      return filtersObj
    },
  },
}
</script>

<style scoped>
.embedded-sidebar li .checkbox:not(.plain) {
  left: 10px;
}

.filter-container {
  max-height: 192px;
  overflow-y: auto;
}

.show-more {
  cursor: pointer;
}
</style>
