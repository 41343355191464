<template>
  <base-rich-text
    :modelValue="findFieldByName('content_value').value"
    height="120"
    :toolbarInline="true"
    @valueChanged="contentChanged"
  />
</template>
<script>
import BaseRichText from '@atoms/fields/base-rich-text'

export default {
  components: { BaseRichText },
  methods: {
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    },
    contentChanged(value) {
      this.findFieldByName('content_value').value = value
      this.findFieldByName('content_value').updated = true
    }
  },
  props: {
    fieldSpecificAttributes: {},
    pageModule: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
.content-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  width: auto !important;
  height: auto !important;
}
</style>
