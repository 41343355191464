const state = {
  isLoading: true,
  linkText: '',
  linkExternalURL: null,
  tipTapTableSelectedHeader: null,
  tippyTargetIsText: false,
  clickedOnBlockquote: false,
  selectedText: '',
  linkNewTab: false,
  linkNoFollow: false,
  linkInternal: true,
  isImageSelected: false,
  linkPage: null,
  disableMerge: false,
  isTableSelected: false,
  affiliateOperatorId: null,
  trackingLink: null
}

const getters = {
  getNotifications: state => {
    return state.notifications
  },
  getCustomAnchorAttributes: (state, getters) => {
    const { hrefValue, targetValue, relValue } = getters.getLinkPreview
    return {
      href: hrefValue,
      target: targetValue,
      rel: relValue
    }
  },
  getLinkPreview: (state, getters) => {
    if (state.isLoading) return ''

    const targetValue = state.linkNewTab ? '_blank' : ''
    let relValue = state.linkNoFollow ? 'nofollow' : ''

    if (state.linkNewTab) {
      relValue = 'noopener noreferrer'
    }
    if (state.linkNewTab && state.linkNoFollow) {
      relValue = 'noopener noreferrer nofollow'
    }
    let hrefValue = ''

    const content = state.isImageSelected
      ? '[image]'
      : state.selectedText && state.selectedText.replaceAll(/\u200b/g, '')

    if (!state.linkInternal) {
      hrefValue = state.linkExternalURL
    } else if (state.linkPage) {
      hrefValue = getters.getHref
    } else if (state.trackingLink) {
      hrefValue = getters.getTrackingLinkHref
    } else if (!state.trackingLink) {
      hrefValue = state.linkExternalURL
    }

    return { hrefValue, targetValue, relValue, content }
  },
  getHref: state => {
    if (state.linkInternal && state.linkPage) {
      let internalHref = `/${state.linkPage.path}`
      if (state.linkPage.site_market && state.linkPage.site_market.path_prefix) {
        internalHref = `/${state.linkPage.site_market.path_prefix}${internalHref}`
      }

      if (internalHref.startsWith('//')) {
        internalHref = internalHref.substring(1)
      }
      if (internalHref.endsWith('//')) {
        internalHref = internalHref.substring(0, internalHref.length - 1)
      }
      return internalHref
    }
    return state.externalURL
  },
  getTrackingLinkHref: state => {
    if (state.trackingLink) {
      let internalHref = `${state.trackingLink.link}`
      return internalHref
    }
    return state.externalURL
  }
}

const actions = {}

const mutations = {
  setAffiliateOperatorId(state, value) {
    state.affiliateOperatorId = value
  },
  setIsTableSelected(state, value) {
    state.isTableSelected = value
  },
  setIsImageSelected(state, value) {
    state.isImageSelected = value
  },
  setClickedOnBlockquote(state, value) {
    state.clickedOnBlockquote = value
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setLinkInternal(state, value) {
    state.linkInternal = value
  },
  setLinkText(state, value) {
    state.linkText = value
  },
  setLinkExternalURL(state, value) {
    state.linkExternalURL = value
  },
  setSelectedText(state, value) {
    state.selectedText = value
  },
  setTableSelectedHeader(state, value) {
    state.tipTapTableSelectedHeader = value
  },
  setLinkNewTab(state, value) {
    state.linkNewTab = value
  },
  setLinkNoFollow(state, value) {
    state.linkNoFollow = value
  },
  setLinkPage(state, value) {
    state.linkPage = value
  },
  setTrackingLink(state, value) {
    state.trackingLink = value
  },
  setClearLinkModal(state) {
    state.linkText = ''
    state.linkExternalURL = null
    state.linkNewTab = false
    state.linkNoFollow = false
    state.linkInternal = true
    state.linkPage = null
    state.trackingLink = null
  },
  setDisableMerge(state, value) {
    state.disableMerge = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
