<template>
  <div v-if="siteMarket">
    <div class="row mb-1">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-markets` }"
            data-tooltip="Back to site markets"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ siteMarket.market.label }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(siteMarket.updated_at) }}</span>
          <span class="badge bg-azure ml-2">{{ siteMarket.site.name }}</span>
          <span class="badge bg-green ml-2" v-if="siteMarket.active">active</span>
          <span class="badge bg-black-25 ml-2" v-if="!siteMarket.active">inactive</span>
          <span class="badge bg-purple ml-2" v-if="siteMarket.path_prefix"
            >/{{ siteMarket.path_prefix }}</span
          >
        </div>
      </div>
    </div>
    <edit-section-modules
      :sections="siteMarket.sections"
      :siteId="siteMarket.site_id"
      :affiliateId="siteMarket.site.affiliate_id"
      :marketId="siteMarket.market_id"
      :moduleOwnerId="$route.params.id"
      :moduleOwnerType="getModuleOwnerType()"
      :showSave="true"
      :loading="loading"
      v-bind:tooltip="2405826817"
      @save="update"
    />
  </div>
</template>
<script>
import SiteMarketsService from '@services/SiteMarketsService'
import EditSectionModules from '@templates/cms/section/section.vue'
import ModuleValue from '@constants/module-value'

export default {
  data() {
    return {
      siteMarket: null,
      loading: false,
      modules: [],
    }
  },
  components: {
    EditSectionModules,
  },
  created() {
    this.getSiteLanguage()
  },
  methods: {
    getModuleOwnerType() {
      return ModuleValue.OWNER_TYPE_MARKET
    },
    getSiteLanguage() {
      SiteMarketsService.getOne(this.$route.params.id, {
        with: ['sections', 'sections.enabled_modules', 'market', 'site', 'sections.style'].join(),
      })
        .then((response) => {
          if (response.data.result.length === 0) {
            this.$router.push('/site-markets')
          } else {
            this.siteMarket = response.data.result[0]
            this.setTitle(`${this.siteMarket.market.label}`)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    async update() {
      this.loading = true
      await SiteMarketsService.update(this.siteMarket)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.getSiteLanguage()
            this.showSuccessMessage('Market saved')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
