<template>
  <div>
    <data-table
      model="GameSiteData"
      service-file="AffiliateGameService"
      :serviceParams="[game_id]"
      :path="path()"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :column-templates="columnTemplates()"
    />
  </div>
</template>

<script lang="jsx">
import StatusColumn from '@/components/table/status-column'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      game_id: this.$route.params['id'],
    }
  },
  mounted() {},
  methods: {
    columns() {
      return [
        { field: 'market', type: 'text', filterable: true },
        { field: 'site_name', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'status', type: 'text', filterable: true },
      ]
    },
    path() {
      return `hercules/games/affiliate-games?game_id=${this.$route.params['id']}&with=market,affiliate`
    },
    columnTemplates() {
      return Object.assign({
        market: function (row) {
          if (row.market) {
            return row.market.label
          }
        },
        status: function (row) {
          return <StatusColumn status={row.status} />
        },
      })
    },
    createDefaults() {
      return {
        game_id: this.$route.params['id'],
      }
    },
  },
}
</script>
