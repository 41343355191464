<template>
  <data-table
    model="cms/ExtraField"
    service-file="SiteExtraFieldsService"
    :createDefaults="{ owner_type: 'template', owner_id: $route.params.id }"
    :path="path()"
    :columns="columns()"
    pusherModel="sites-extra_fields"
  />
</template>

<script>

import TableColumnTemplate from '@constants/table-column-template'
import BasicOptions from '@mixins/BasicOptions'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_code', type: 'text', filterable: true },
        { field: 'help_text', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'required', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn('required'), {template: TableColumnTemplate.BOOLEAN}),
        { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    path() {
      return `hercules/sites/extra-fields?owner_type=template&owner_id=${this.$route.params.id}`
    }
  }
}
</script>
