<template>
  <div>
    <data-table
      model="cms/AutogeneratedContent"
      service-file="AutogeneratedContentService"
      :path="path"
      ref="list"
      :columns="columns()"
      :rowActionButtons="rowActions"
      :create-defaults="createDefaults()"
    />
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import confluenceIds from '@constants/confluence-ids'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  data() {
    return {
      confluenceId: 0,
      rowActions: [
        {
          action: this.variablesAction,
          title: 'Variables',
          icon: 'uil uil-info-circle',
          class: 'btn-secondary',
        },
      ],
    }
  },
  components: { InformationModal },
  created() {
    this.confluenceId = confluenceIds.page_types.autogenerated_content.id
    this.setTitle()
  },
  computed: {
    path() {
      return `hercules/sites/autogenerated-content?page_type_id=${this.$route.params.id}&site_id=${this.$parent.$parent.template.site_id}`
    },
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'key', type: 'text', filterable: true },
        { field: 'value', type: 'html', filterable: true, template: TableColumnTemplate.TEXT_TRUNCATE },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        site_id: this.$parent.$parent.template.site_id,
        page_type_id: parseInt(this.$route.params['id']),
      }
    },
    variablesAction() {
      this.$refs.informationModal.show()
    },
  },
}
</script>
