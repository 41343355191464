<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3>Module details</h3>
          </div>
          <div class="card-body">
            <module-list :module-fields="module.module_fields" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModulesService from '@services/ModulesService'
import BaseInput from '@atoms/fields/base-input.vue'
import ModuleList from '@atoms/cms/modules/module-list.vue'

export default {
  components: { BaseInput, ModuleList },
  data() {
    return {
      module: {}
    }
  },
  created() {
    this.getModule()
  },
  methods: {
    getModule() {
      ModulesService.getOne(this.$route.params.id, {
        with: 'module_fields,module_fields.field,module_fields.children'
      })
        .then(response => {
          this.module = response.data.result[0]
        })
        .catch(this.showUnknownErrorMessage)
    }
  }
}
</script>
