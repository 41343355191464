import WidgetToplist from '@pages/widgets/toplist/toplist.vue'
import WidgetToplistEdit from '@pages/widgets/toplist/toplist-edit.vue'
import WidgetToplistGeneral from '@pages/widgets/toplist/toplist-general.vue'
import WidgetToplistItems from '@pages/widgets/toplist/toplist-items.vue'
import WidgetToplistGeoItems from '@pages/widgets/toplist/toplist-geo-items.vue'

import WidgetOdds from '@pages/widgets/odds/odds.vue'
import WidgetOddsEdit from '@pages/widgets/odds/odds-edit.vue'
import WidgetOddsGeneral from '@pages/widgets/odds/odds-general.vue'
import WidgetOddsToplists from '@pages/widgets/odds/odds-toplists.vue'


import WidgetSports from '@pages/widgets/sports/sports.vue'

export default [
    {
        path: '/site-widgets-toplist',
        component: WidgetToplist,
        name: 'Widget Toplist',
        meta: {
          auth: true,
          parent: 'Widgets',
          name: 'Widget Toplist',
          permission: 'view.widgets/toplist'
        }
      },
      {
        path: '/site-widgets-toplist/:id',
        component: WidgetToplistEdit,
        children: [
          { path: '', component: WidgetToplistGeneral, meta: { parent: 'Widgets',name: 'General',breadCrumb: 'Toplist' } },
          {
            path: '/site-widgets-toplist/:id/items',
            component: WidgetToplistItems,
            meta: { parent: 'Widgets',name: 'Widget toplists', permission: 'view.widgets/toplist',breadCrumb: 'Toplist' }
          },
          {
            path: '/site-widgets-toplist/:id/geo-items',
            component: WidgetToplistGeoItems,
            meta: { parent: 'Widgets',name: 'Widget Geolocated Toplists',breadCrumb: 'Toplist' }
          }
        ],
        meta: {
          auth: true,
          parent: 'Widgets',
          name: 'Widget Toplist'
        }
      },
      {
        path: '/site-widgets-odds',
        component: WidgetOdds,
        name: 'Odds',
        meta: {
          auth: true,
          parent: 'Widgets',
          name: 'Odds Widget',
          permission: 'view.widgets/odds'
        }
      },
      {
        path: '/site-widgets-odds/:id',
        component: WidgetOddsEdit,
        children: [
          { path: '', component: WidgetOddsGeneral, meta: { parent: 'Widgets',name: 'General',breadCrumb: 'Odds'  } },
          {
            path: '/site-widgets-odds/:id/toplists',
            component: WidgetOddsToplists,
            meta: { parent: 'Widgets',name: 'Odds Widget Toplists', permission: 'view.widgets/odds',breadCrumb: 'Odds' }
          }
        ],
        meta: {
          auth: true,
          parent: 'Widgets',
          name: 'Odds',
          permission: 'view.widgets/odds'
        }
      },
      {
        path: '/site-widgets-sports',
        component: WidgetSports,
        meta: {
          auth: true,
          parent: 'Widgets',
          name: 'Sports Widget',
          permission: 'view.widgets/sports'
        }
      },
      
]