<template>
  <data-table
    model="operators/AffiliatePlatformDomain"
    service-file="operators/AffiliatePlatformDomainsService"
    :path="path()"
    :createDefaults="createDefaults()"
    :columns="columns()"
    ref="table"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {}
  },
  components: {},
  methods: {
    columns() {
      return [
        { field: 'domain', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    path() {
      return `hercules/operators/affiliate-platform-domains?platform_id=${this.$route.params['id']}`
    },

    createDefaults() {
      return {
        platform_id: parseInt(this.$route.params['id'])
      }
    }
  }
}
</script>
