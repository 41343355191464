<template>
  <data-table
    model="cms/ModuleStyle"
    service-file="ModuleStylesService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    :column-templates="columnTemplates()"
    :extraDeleteInfo="{
      title: 'Careful, this is a destructive action!',
      message: 'All modules that use this style will be affected.',
    }"
    pusherModel="sites-module_styles"
  />
</template>

<script>
import BasicOptions from '@mixins/BasicOptions'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  mounted() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    path() {
      return `hercules/sites/module-styles?with=site&site_id=${this.$route.params['id']}`
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'module', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn('default'), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign({
        module: function (row) {
          return row?.module.label
        },
      })
    },
    createDefaults() {
      return {
        site_id: parseInt(this.$route.params['id']),
        default: false,
      }
    },
  },
}
</script>
