<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Redirects Upload</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <base-select
            label="Site"
            field="site"
            v-model="siteId"
            path="hercules/sites/sites"
            ref="siteSelect"
            :required="true"
            optionLabelKey="name"
          />
          <base-select
            v-if="siteId"
            label="Market"
            field="market"
            v-model="marketId"
            ref="marketSelect"
            placeholder="Choose market"
            optionLabelKey="label"
            trackBy="short_code"
            :loadOnMount="false"
            :path="`hercules/sites/markets/by-site/${siteId}`"
          />
          <div class="form-group">
            <label>File (.csv)</label>
            <a class="d-inline ml-2 form-text text-muted" href="/site_redirects_example.csv" target="_blank"
              ><small>Example CSV file</small></a
            >
            <input
              type="file"
              ref="file"
              class="form-control-file mt-2"
              accept="text/csv"
              @change="onChange"
            />
          </div>

          <div v-if="csvErrors" class="notification">
            <div class="alert" v-bind:class="messageClass">
              {{ message }}
            </div>

            <table ref="errorList" class="table table-sm errors summary">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(error, index) in csvErrors" :key="index">
                  <td>{{ error.split(':')[0] }}</td>
                  <td style="white-space: normal">{{ error.split(':')[1] }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <a
                      ref="showMore"
                      class="font-italic d-flex align-items-center justify-content-center"
                      v-if="csvErrors.length > 2"
                      @click="showAllErrors"
                      >Show More</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" v-bind:class="{ loading: loading }" @click="close()">
            Cancel
          </button>
          <button class="btn btn-primary" v-bind:class="{ loading: loading }" @click="submit()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RedirectService from '@services/RedirectService'
import BaseSelect from '@/components/fields/base-select'

export default {
  components: { BaseSelect },
  data() {
    return {
      formData: new FormData(),
      csvErrors: null,
      message: null,
      loading: false,
      siteId: null,
      marketId: null,
      file: null,
      isWarning: false,
    }
  },
  computed: {
    messageClass: function () {
      return this.message.includes('Warning') ? 'alert-warning' : 'alert-error'
    },
  },
  watch: {
    siteId: function () {
      this.formData.append('site_id', this.siteId)
      setTimeout(() => {
        this.$refs['marketSelect'].activeOptionLabel = ''
        this.$refs['marketSelect'].getOptions()
      }, 100)
    },
    marketId: function () {
      this.formData.append('market', this.marketId)
    },
    file: function () {
      this.formData.append('file', this.file, this.file && this.file.name)
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
    this.$refs.modal.classList.add('show')
  },
  methods: {
    getError(error) {
      return error.split(':')
    },
    async submit() {
      this.loading = true
      await RedirectService.csv(this.formData)
        .then((response) => {
          if (response.data.messages) {
            this.csvErrors = response.data.messages.splice(1, response.data.messages.length)
            this.message = response.data.messages[0]
            this.$refs.file.value = null
            this.$emit('refresh')
          } else {
            this.showSuccessMessage('Redirects updated')
            this.$emit('refresh')
            this.close()
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.loading = false
      setTimeout(() => {
        this.$parent.showBulkRedirectModal = false
      }, 500)
    },
    onChange() {
      this.file = this.$refs.file.files[0]
    },
    showAllErrors() {
      this.$refs.errorList.classList.add('full')
      this.$refs.showMore.classList.add('d-none')
      this.$refs.showMore.classList.remove('d-flex')
    },
  },
}
</script>

<style scoped>
.errors tbody tr {
  display: none;
}

.errors.summary tbody tr:nth-child(-n + 2),
.errors.summary.full tbody tr:not(:last-child),
.errors.summary tbody tr:last-child {
  display: table-row;
}
</style>
