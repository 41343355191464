<template>
  <data-table
    model="User"
    service-file="UsersService"
    path="backoffice/users"
    :rowActionButtons="rowActions()"
    :column-templates="columnTemplates()"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'email', type: 'text', filterable: true },
        { 
          field: 'roles', 
          type: 'list-many', 
          filterable: true,
          path: 'backoffice/roles',
          basicFilterType: 'select',
          optionLabelKey: 'name',
          trackBy: 'name'
        },
        { 
          field: 'markets',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          sortable: true,
          showModalAdvanced: true,
          path: 'hercules/globals/markets?bypass_market_restrictions=true',
          optionLabelKey: 'label',
          trackBy: 'id',
        },
        { 
          field: 'sites', 
          type: 'text', 
          filterable: true, 
          advancedFilter: true, 
          sortable: true,
          showModalAdvanced: true,
          path: 'hercules/globals/sites?bypass_sites_restrictions=true',
          optionLabelKey: 'name',
          trackBy: 'id',
        },
        {
          field: 'last_active',
          type: 'text',
          filterable: false,
          template: TableColumnTemplate.RELATIVE_DATE
        },
        { field: 'updated_at', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    rowActions() {
      if (this.hasRole('admin') || this.hasRole('user manager')) {
        return [
          {
            action: this.$auth.impersonating() ? null : this.impersonate,
            title: 'Impersonate',
            icon: 'uil uil-user',
            class: 'btn-info'
          }
        ]
      }
      return []
    },
    columnTemplates() {
      return {
        roles: function(row) {
          return row.roles
            .map(role => {
              return role.name
            })
            .join(', ')
        }
      }
    },
    impersonate(row) {
      if (row.id === this.$auth.user().id) {
        this.showErrorMessage('You cannot impersonate yourself')
        return
      }

      if (row.roles.filter(role => role.name === 'admin').length > 0) {
        this.showErrorMessage('You cannot impersonate an other admin')
        return
      }
      this.$auth
        .impersonate({
          url: `backoffice/users/impersonate/${row.id}`,
          redirect: { path: '/' }
        })
        .then(
          () => this.showSuccessMessage(`Impersonating ${row.name}`),
          this.$store.commit('sidebar/setSelectedSidebarItem', 'Home')
        )
        .catch(() => this.showErrorMessage('Failed to impersonate'))
    }
  }
}
</script>
