<template>
  <div :class="wrapperClass" :name="label && label && slugify(label)">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">*</span>
    <div class="d-inline ml-2" v-if="clearable && currentValue && currentValue.length > 0">
      <a class="text-muted" @click="clear">clear</a>
    </div>
    <div class="popover-wrapper date-picker clickable">
      <flat-pickr
        v-bind="$attrs"
        class="form-control"
        v-model="currentValue"
        v-bind:disabled="disabled || (readOnly && formType && formType === 'edit')"
      />
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'

export default {
  components: { FlatPickr },
  mounted() {
    if (this.defaultDate && this.currentValue) {
      this.$emit('update:modelValue', this.currentValue)
    }
  },
  data() {
    return {
      currentValue: this.modelValue ? this.modelValue : this.defaultDate ? this.defaultDate : '',
      isDisabled: this.disabled,
    }
  },
  watch: {
    currentValue: function () {
      this.$emit('update:modelValue', this.currentValue)
      if (this.$refs.validator) {
        this.$refs.validator.validate()
      }
    },
  },
  props: {
    wrapperClass: {
      default: 'form-group',
    },
    helpText: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [String],
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    readOnly: {
      default: () => false,
    },
    formType: {
      default: () => null,
      type: String,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    clearable: {
      default: () => true,
    },
    defaultDate: {
      default: () => '',
    },
  },
  methods: {
    clear() {
      if (!this.disabled) {
        this.currentValue = ''
      }
    },
  },
}
</script>
