<template>
  <data-table
    model="License"
    service-file="LicensesService"
    path="hercules/operators/licenses"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE
        },
        { field: 'updated_at', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    }
  }
}
</script>
