<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Export CSV</h5>

          <button class="close" v-on:click="close"></button>
        </div>

        <div class="modal-body">
          <base-radio
            label="Data to export"
            :data="dataSetOptions"
            v-model="dataSetOption"
            :useNameValue="false"
            :inlineLayout="true"
          />

          <base-input label="Filename" v-model="filename" />

          <div v-if="unexportableColumns.length > 0" class="border p-3">
            <strong>Please note that the following column(s) cannot be exported:</strong>

            <ul class="mt-2">
              <li v-for="(column, key) in unexportableColumns" :key="key">
                {{ column.label || column.field }}
              </li>
            </ul>

            <p class="text-muted mt-2">
              If this is blocking you, contact us on
              <a href="slack://channel?team=T0707SH4P&id=CCFDPGRUL">#gm-alpha-hercules</a>
              and we'll see what we can do :)
            </p>
          </div>
        </div>

        <div
          class="modal-footer d-flex align-items-center"
          :class="{ 'justify-content-between': dataSetOption === 'all' }"
        >
          <div class="text-muted d-flex align-items-center animate fn-fade-in" v-if="dataSetOption === 'all'">
            <i class="uil uil-info-circle"></i>
            <span class="ml-2">Exporting all records can take a few seconds to load</span>
          </div>

          <button class="btn btn-primary" @click="exportCsv" :class="{ loading: loading }">
            Export data
            <i class="uil uil-file-export ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import DynamicForm from '@/components/fields/DynamicForm'
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

export default {
  components: { DynamicForm, BaseRadio, BaseCheckbox, BaseInput },
  data() {
    return {
      loading: false,
      dataSetOption: 'visible',
      filename: null
    }
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    exportAllRows: { type: Boolean, default: true },
    totalRows: {},
    columns: {},
    visibleColumns: {},
    columnTemplates: {
      default: () => {}
    },
    csvColumnTemplates: {
      default: () => {}
    },
    requestMethod: {}
  },
  computed: {
    dataSetOptions() {
      let options = [{ value: 'visible', label: `visible records (${this.rows.length})` }]
      if (this.exportAllRows && !this.$route?.path?.startsWith('/koala')) {
        options.push({ value: 'all', label: `all records (${this.totalRows})` })
      } else if (this.exportAllRows && this.$route?.path?.startsWith('/koala') && this.totalRows <= 10000) {
        options.push({ value: 'all', label: `all records (${this.totalRows})` })
      }
      return options
    },
    unexportableColumns() {
      return this.columns.filter(column => {
        return column.exportable === false && this.visibleColumns.includes(column.field)
      })
    }
  },
  mounted() {
    document.getElementById('modal') && document.getElementById('modal').appendChild(this.$refs.modal)
  },
  methods: {
    getDataSet() {
      return new Promise((resolve, reject) => {
        if (this.dataSetOption === 'visible') {
          resolve(this.rows)
        } else {
          this.requestMethod({ limit: this.totalRows, page: null })
            .then(response => {
              resolve(response.data.result)
            })
            .catch(error => reject(error))
        }
      })
    },
    async exportCsv() {
      this.loading = true

      const dataSet = await this.getDataSet()
      const exportableColumns = this.columns.flatMap(column => {
        if (this.visibleColumns.includes(column.field) && column?.exportable !== false) {
          return column
        }
        return []
      })
      const results = dataSet?.map(row => {
        let dataRow = {}
        exportableColumns.forEach(column => {
          const columnLabel = column.label ? column.label : column.field
          dataRow[columnLabel] = this.getColumnValue(column, row)
        })
        return dataRow
      })

      this.generateCsv(results)
      this.loading = false
      this.close()
    },
    generateCsv(results) {
      const csvExporter = new ExportToCsv({
        useKeysAsHeaders: true,
        filename: this.filename.replace('.csv', '')
      })
      csvExporter.generateCsv(results)
    },
    getColumnValue(column, row) {
      try {
        if (column.field.includes('.')) {
          return this.findInObject(column.field.split('.'), row)
        } else if (Object.hasOwn(this.csvColumnTemplates, column.field)) {
          const templateValue = this.csvColumnTemplates[column.field](row)
          if (typeof templateValue === 'string') {
            return templateValue
          }
        } else if (Object.hasOwn(this.columnTemplates, column.field)) {
          const templateValue = this.columnTemplates[column.field](row)
          if (typeof templateValue === 'string') {
            return templateValue
          }
        } else if (column.template) {
          return this.templateRender(row[column.field], column.template)
        }
        return row[column.field]
      } catch (error) {
        return null
      }
    },
    templateRender(value, template) {
      switch (template) {
        case TableColumnTemplate.PRETTY_LABELS:
          return this.$prettyLabels(value)
        case TableColumnTemplate.RELATIVE_DATE:
        case TableColumnTemplate.DATE_TIME:
        case TableColumnTemplate.FORMATTED_DATE_TIME:
          return moment(value).format('YYYY-MM-DD HH:mm:ss')
        case TableColumnTemplate.IMAGE:
        case TableColumnTemplate.CLIPBOARD:
        case TableColumnTemplate.COLOR:
        case TableColumnTemplate.TEXT_TRUNCATE:
        case TableColumnTemplate.TEXT_TRUNCATE_TOOLTIP:
        case TableColumnTemplate.ODDS_STATUS:
        case TableColumnTemplate.SPORTS_STATUS:
        case TableColumnTemplate.EVENT_STATUS:
        case TableColumnTemplate.BOOLEAN:
        case TableColumnTemplate.STATUS:
        case TableColumnTemplate.TOPLIST:
          return value
        case TableColumnTemplate.BADGES:
        default:
          return null
      }
    },
    show() {
      const prefix = (this.$route && this.$route.meta?.name) || 'hercules'
      this.filename = `${prefix.toLowerCase()}-${moment().format('YYYY-MM-DD-HHmm')}.csv`
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
    }
  }
}
</script>
