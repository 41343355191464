<template>
  <div class="form-group">
    <base-radio
      :modelValue="type"
      @update:modelValue="(option) => typeChange(option)"
      :label="$prettyLabels(label)"
      name="type"
      :data="['page selector', 'manual input']"
      :useNameValue="false"
      :inlineLayout="true"
      :required="required"
    />
    <model-select
      v-if="type === 'page selector'"
      :parameters="{ site_id: siteId, match_market_language: 1 }"
      label="Selected page"
      ref="modelSelect"
      model="page"
      :value="data"
      :value-label="value_label"
      @valuePicked="pageValuePicked"
      :valueLabel="value_label"
      :required="required"
      valueKey="path"
    />
    <base-input
      v-if="type === 'manual input'"
      label="Page path"
      v-model="data"
      :required="required"
      @update:modelValue="updateValue"
    />
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import BaseRadio from '@atoms/fields/base-radio.vue'
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import PagesService from '@services/PagesService'

export default {
  components: {
    BaseRadio,
    ModelSelect,
    BaseInput,
  },
  created() {
    if (this.editForm) {
      this.setDefaultValues()
    }
  },
  updated() {
    if (this.data === null) {
      this.updateValue(this.data)
    }
  },
  data() {
    return {
      value_label: null,
      data: null,
      type: 'page selector',
    }
  },
  props: {
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    value: {
      type: String,
      default: () => null,
    },
    siteId: {
      type: Number,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editForm: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('sync', this.field.name, value)
    },
    typeChange(type) {
      this.data = null
      this.value_label = null
      this.type = type
      this.updateValue(null)
      this.$forceUpdate()
    },
    pageValuePicked(value, valueLabel) {
      this.data = valueLabel
      this.value_label = valueLabel
      this.$forceUpdate()
      this.updateValue(valueLabel)
    },
    setDefaultValues() {
      let path = this.value
      PagesService.get({
        path: path.substring(1),
        site_id: this.siteId,
      })
        .then((response) => {
          if (response.data.result.length === 1) {
            this.type = 'page selector'
            this.data = response.data.result[0].path
            this.value_label = response.data.result[0].path
          } else {
            this.type = 'manual input'
            this.data = path
          }
          this.updateValue(this.data)
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
