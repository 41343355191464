<template>
  <data-table
    model="AffiliateExtraField"
    service-file="AffiliateExtraFieldsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    :customActionButton="{
      action: createTermsAndConditionsExtraFields,
      title: 'Generate T&C fields',
      icon: 'uil uil-refresh',
    }"
    ref="list"
  />
</template>

<script>
import AffiliateExtraFieldsService from '@services/AffiliateExtraFieldsService'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Extra Operator fields`
    )
  },
  methods: {
    path() {
      return `hercules/operators/affiliate-extra-fields?affiliate_id=${this.$route.params['id']}&model_type=operator`
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'help_text', type: 'text', filterable: true },
        { field: 'model_type', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        affiliate_id: parseInt(this.$route.params['id']),
        model_type: 'operator',
      }
    },
    createTermsAndConditionsExtraFields() {
      this.$refs.list.isLoading = true
      this.$refs.list.setCustomActionButtonLoading(true)
      AffiliateExtraFieldsService.generateDefaults(this.$route.params['id'])
        .then((response) => {
          if (response.data.result.length == 0) {
            this.showErrorMessage('No new fields have been created.')
          } else {
            this.showSuccessMessage('New fields have been created.')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.list.isLoading = false
          this.$refs.list.setCustomActionButtonLoading(false)
          this.$refs.list.search()
        })
    },
  },
}
</script>
