<template>
  <div class="modal in" id="sports-add" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="close" @click="cancel"></button>
        </div>
        <div class="modal-body pb-1">
          <p>{{ content }}</p>
          <button
            v-bind:class="[actionButtonWarningClass, loading ? 'loading' : '']"
            @click="deleteEmptyLinks"
            v-if="showActionButton"
          >
            <i :class="actionButtonIconClass"></i>
            {{ actionButtonTitleEmptyLinks }}
          </button>
          <p>
            <span class="font-size-sm"
              >There are {{ emptyLinks }} empty links for this granular tracking type</span
            >
          </p>
          <button :class="actionButtonClass" @click="$close(true)" v-if="showActionButton">
            <i :class="actionButtonIconClass"></i>
            {{ actionButtonTitleAllLinks }}
          </button>
          <p>
            <span class="font-size-sm">There are {{ allLinks }} links for this granular tracking type</span>
          </p>
        </div>
        <div class="modal-footer pt-3">
          <div class="text-right">
            <button class="btn btn-secondary" @click="cancel">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emptyLinks: 0,
      allLinks: 0,
      loading: false,
    }
  },
  methods: {
    cancel() {
      this.$close(false)
    },
    deleteEmptyLinks() {
      this.loading = true
      this.serviceLoader()
        .then((service) => {
          return service.default.removeEmptyLinks(this.granular_id)
        })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Empty links removed')
            this.$close(false)
            this.loading = false
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.serviceLoader()
        .then((service) => {
          return service.default.getStats(this.granular_id)
        })
        .then((response) => {
          let data = response.data.result
          this.emptyLinks = data.empty_links
          this.allLinks = data.all_links
        })
        .catch(this.showUnknownErrorMessage)
    })
  },
  props: {
    showActionButton: {
      default: () => true,
    },
    actionButtonIconClass: {
      default: () => 'uil uil-trash',
    },
    actionButtonClass: {
      default: () => 'btn btn-danger',
    },
    actionButtonWarningClass: {
      default: () => 'btn btn-warning',
    },
    actionButtonTitleEmptyLinks: {
      default: () => 'Delete empty links',
    },
    actionButtonTitleAllLinks: {
      default: () => 'Delete all links',
    },
  },
  computed: {
    serviceLoader() {
      return () => import(`@services/GranularTrackingLinksService`)
    },
  },
}
</script>
