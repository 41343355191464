<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Credentials</h5>
          <button class="close" v-on:click="closeModalAfterSendingMessage"></button>
        </div>
        <div class="modal-body">
          <dynamic-form
            form-type="edit"
            v-if="object"
            ref="form"
            :formFields="structure.list"
            :structure="structure"
            :object="object"
            :on-submit="submit"
            submit-label="Submit"
            :path="path()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import AccountsCredentials from '@models/koala/AccountsCredentials'
import AccountsCredentialsService from '@services/koala/AccountCredentialsService'

export default {
  components: { DynamicForm },
  props: {
    createCredentials: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      object: {},
      structure: {},
      config: AccountsCredentials.getConfig(),
    }
  },
  methods: {
    show() {
      this.structure = AccountsCredentials.getStructure()
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = {}
    },
    closeModalAfterSendingMessage() {
      if (this.$refs && typeof this.$refs.modal !== 'undefined') {
        this.$refs.modal.classList.remove('show')
      }
    },
    submit() {
      if (this.createCredentials) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      AccountsCredentialsService.post(this.object)
        .then((response) => {
          this.$emit('created', response.data)
          this.close()
          this.$refs.form.loading(false)
          this.showSuccessMessage('Account credentials created successfully')
        })
        .catch((error) => {
          this.showErrorMessages(error.message)
          this.$refs.form.loading(false)
        })
    },
    update() {
      AccountsCredentialsService.update(this.object)
        .then((response) => {
          this.$emit('updated', response.data)
          this.close()
          this.$refs.form.loading(false)
          this.showSuccessMessage('Account credentials updated successfully')
        })
        .catch((error) => {
          this.showErrorMessages(error.message)
          this.$refs.form.loading(false)
        })
    },
    path() {
      return `koala/v1/accounts`
    },
  },
}
</script>

<style></style>
