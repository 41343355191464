<template>
  <div class="modal" v-click-outside="close" v-bind:class="{ in: active }">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">{{ actionTitlePrefix }}</h5>
          <button class="close p-0 m-0" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-0" v-bind:class="{ loading: isLoading }">
          <base-radio
            :data="['Tracking Link', 'External']"
            :modelValue="trackingLink ? 'Tracking Link' : 'External'"
            @update:modelValue="handleBaseRadio"
            name="Selected Type"
            label="Type"
            :inlineLayout="true"
            id="pageTypeRadio"
          />

          <base-input
            :required="true"
            v-if="!trackingLink"
            label="Url"
            v-model="externalURL"
            ref="externalUrl"
          />

          <base-select
            v-else
            id="trackingLinks"
            :loadOnMount="false"
            optionLabelKey="name"
            trackBy="id"
            ref="baseSelect"
            v-model="selectTrackingLink"
            :customOptions="trackingLinkDropdown"
            placeholder="Select heading"
            :class="'mb-0 w-100 tiptap-base-select pr-1 mb-1 border-right-light'"
            :allowNullOption="true"
            :removeActiveText="true"
          />

          <base-input
            :required="true"
            v-if="!editor.isActive('image') && !editor.isActive('figure')"
            label="Text"
            v-model="customText"
          />
          <div class="d-flex ">
            <base-checkbox
              name="newtab"
              :switch="true"
              v-model="newTab"
              wrapperClass="mt-4 mb-3 mr-3"
              label="Open in new tab"
            />
            <base-checkbox
              name="nofollow"
              :switch="true"
              v-model="noFollow"
              wrapperClass="mt-4 mb-3"
              label="No Follow"
            />
          </div>
          <hr />
          <div>
            <base-code-text
              label="Preview"
              :code="preview"
              language="text/html"
              :readOnly="true"
              :heightAuto="true"
              :showLineNumber="false"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-info mr-2 ml-2" @click="submit">
            <i class="uil uil-link"></i> {{ actionTitlePrefix }} link
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCodeText from '@atoms/fields/base-code-text.vue'
import AffiliateLinksService from '@services/AffiliateLinksService'
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    BaseInput,
    BaseCodeText,
    BaseSelect
  },
  data() {
    return {
      active: false,
      attributes: { href: null, trackingLinkId: null, noFollow: false, newTab: false, ref: null },
      pageText: null,
      validateUrl: false,
      previousText: '',
      trackingLink: true,
      trackingLinkDropdown: []
    }
  },
  props: {
    actionTitlePrefix: {
      type: String,
      default: 'Add'
    },
    marketId: {},
    siteId: {},
    editor: {}
  },
  computed: {
    ...mapState({
      isLoading: state => state.tiptap.isLoading,
      affiliateOperatorId: state => state.tiptap.affiliateOperatorId
    }),
    ...mapGetters({
      getLinkPreview: 'tiptap/getLinkPreview'
    }),
    preview() {
      const { hrefValue, targetValue, relValue, content } = this.getLinkPreview

      let linkHtml = `<a href="${hrefValue}"`

      if (relValue) {
        linkHtml += ` rel="${relValue}"`
      }

      if (targetValue) {
        linkHtml += ` target="${targetValue}"`
      }

      linkHtml += `>${content}</a>`

      return linkHtml
    },
    selectTrackingLink: {
      get() {
        return (this.$store.state.tiptap.trackingLink && this.$store.state.tiptap.trackingLink.id) || ''
      },
      set(value) {
        this.$store.commit(
          'tiptap/setTrackingLink',
          this.trackingLinkDropdown.find(link => link.id === value)
        )
      }
    },
    customText: {
      get() {
        return this.$store.state.tiptap.selectedText
      },
      set(value) {
        this.$store.commit('tiptap/setSelectedText', value)
      }
    },
    newTab: {
      get() {
        return this.$store.state.tiptap.linkNewTab
      },
      set(value) {
        this.$store.commit('tiptap/setLinkNewTab', value)
      }
    },
    noFollow: {
      get() {
        return this.$store.state.tiptap.linkNoFollow
      },
      set(value) {
        this.$store.commit('tiptap/setLinkNoFollow', value)
      }
    },
    externalURL: {
      get() {
        return this.$store.state.tiptap.linkExternalURL
      },
      set(value) {
        this.$store.commit('tiptap/setLinkExternalURL', value)
      }
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
    this.previousText = this.customText
  },
  methods: {
    async getTrackingLinks() {
      try {
        const response = await AffiliateLinksService.get({
          ...{ affiliate_operator_id: this.affiliateOperatorId },
          with: [
            'affiliate_operator',
            'affiliate_operator.operator',
            'affiliate_operator.market',
            'affiliate_operator.affiliate'
          ].join(',')
        })
        this.trackingLinkDropdown = this.mapLinksArray(response.data.result)
      } catch (error) {
        console.error('Failed to get tracking links:', error)
      }
    },
    mapLinksArray(links) {
      return links.map(link => {
        link.delete = false
        link.link = link.link === null ? '' : link.link
        link.original_value = link.link
        link.original_custom_value = link.custom_link
        return link
      })
    },
    handleBaseRadio(option) {
      console.log('option', option)
      this.trackingLink = option.value === 'Tracking Link'
      this.$store.commit('tiptap/setLinkExternalURL', '')
      if (!this.trackingLink) {
        this.$store.commit('tiptap/setTrackingLink', '')
      }
    },
    async show(attributes = {}) {
      this.active = true
      this.attributes = attributes
      await this.getTrackingLinks()
      const trackingLink = this.trackingLinkDropdown.find(link => link.id === attributes.trackingLinkId)
      if (trackingLink) {
        this.$store.commit('tiptap/setTrackingLink', trackingLink)
      }
      if (attributes.href) {
        this.$store.commit('tiptap/setLinkExternalURL', attributes.href)
      }

      this.trackingLink = !!attributes.trackingLinkId
      this.$store.commit('tiptap/setIsLoading', false)
    },
    validate() {
      if (!this.customText?.length && !this.editor.isActive('image') && !this.editor.isActive('figure')) {
        return 'empty_text_input'
      }

      if (!this.externalURL?.length && !this.trackingLink) {
        return 'invalid_url'
      }
      return 'valid'
    },
    async submit() {
      let attributes = { rel: null, target: null }
      const { hrefValue, targetValue } = this.getLinkPreview
      const validationCode = this.validate()

      if (validationCode !== 'valid') {
        const errorMessages = {
          page_missing: 'Please insert page.',
          invalid_url: 'Please write a valid URL.',
          empty_text_input: 'Text input cannot be empty.'
        }

        this.showErrorMessages(errorMessages[validationCode])
        return
      }

      if (this.noFollow) {
        attributes.rel = 'nofollow'
      }
      if (this.newTab) {
        attributes.rel = 'noopener noreferrer'
      }
      if (this.newTab && this.noFollow) {
        attributes.rel = 'noopener noreferrer nofollow'

        attributes.target = targetValue
      }

      if (this.trackingLink) {
        attributes.href = this.trackingLinkDropdown.find(link => link.id === this.selectTrackingLink).link
        attributes.trackingLinkId = this.selectTrackingLink
      } else {
        attributes.href = hrefValue
      }
      this.showLink = false
      // unset link before setting new one (updating)
      if (this.customText.length == 0 || this.previousText !== this.customText) {
        this.editor
          .chain()
          .focus()
          .unsetLink()
          .run()

        this.editor
          .chain()
          .focus()
          .updateLinkContent(attributes, this.customText)
          .run()
        this.close()
        return
      } else if (this.editor.isActive('link')) {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink(attributes)
          .run()
      } else {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink(attributes)
          .run()
      }

      this.close()
    },
    close() {
      this.$emit('closeLink')
      this.$store.commit('tiptap/setSelectedText', '')
      this.$store.commit('tiptap/setClearLinkModal')
      this.$store.commit('tiptap/setIsLoading', true)
    }
  }
}
</script>
<style scoped lang="scss">
.loading {
  opacity: 0.5;
}
.tracking-link-items {
  display: flex;
  justify-content: space-between;
}

.tracking-link-type {
  padding: 4px 8px;
  border-radius: 4px;
}
.tracking-link-input {
  height: 2rem;
}

.tracking-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
