import Api from '../Api'

export default {
  getOne(data) {
    return Api().get(`koala/v1/accounts/${data.id}/credentials`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`koala/v1/accounts/${data.id}/credentials`, {
      password: data.password,
      api_credentials: {
        api_key: data.api_key,
        api_username: data.api_username
      }
    })
  },

  update(data) {
    const apiCredentials = {}
    if (data.api_key) {
      apiCredentials.api_key = data.api_key
    }

    if (data.api_username) {
      apiCredentials.api_username = data.api_username
    }

    return Api().put(`koala/v1/accounts/${data.id}/credentials`, {
      password: data.password,
      api_credentials: apiCredentials
    })
  }
}
