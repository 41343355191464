<template>
    <data-table
      model="Affiliate"
      service-file="AffiliatesService"
      :path="path()"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      :show-edit-modal="false"
      :customRowClick="customRowClick"
      :showBetaHeader="true"
      :enableDataExport="false"
    />
  </template>
  
  <script lang="jsx">
  import PandoraSiteLoginService from '@services/PandoraSiteLoginService'
  import ProgressCircle from '@atoms/misc/progress-circle.vue';

  export default {
    data() {
      return {
        originPerformanceScore: 'NAN'
      };
    },
    components: {
      ProgressCircle
    },
    created() {
    },
    methods: {
      calculateMetricCoverage(good, poor, value) {
      if (value <= good) {
          return 100;
      } else if (value >= poor) {
          return 0;
      } else {

          // const range = poor - good;
          // const difference = value - good;
          // const percentage = (difference / range) * 100;
          const percentage = (1 - ((value - good) / (poor - good))) * 100;

          return percentage.toFixed(2);
        }
      },
      isText(variable) {
          return typeof variable === 'string';
      },
       calculateWeightedScore(percentageScore, weight) {
        return Math.round((percentageScore / 100) * weight);
      },
      siteLogin(affiliate) {
        const env = process.env.VUE_APP_ENV;

        let siteUrl = affiliate.website;
        if (['local', 'experiment2', 'dev'].includes(env)) {
            siteUrl = `https://dev.${siteUrl}`;
        } else if (env === 'staging') {
            siteUrl = `https://staging.${siteUrl}`;
        } else if (env === 'production') {
            siteUrl = `https://${siteUrl}`;
        }

        const userData = this.$auth.user();
        const dataToSend = {
          siteUrl: siteUrl,
          username: userData.name.split(' ').map(part => part.toLowerCase()).join('.'),
          email: userData.email,
          role: userData.roles,
        };
        PandoraSiteLoginService.post(dataToSend)
          .then(response => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              const redirectUrl = response.data.result.redirectUrl;
              window.open(redirectUrl, '_blank');
            }
          })
          .catch(error => {
            console.error('Error sending data:', error);
          });
      },
      customRowClick(event, row) {
        this.$router.push(`/affiliates/${row.id}`);
      },
      columns() {
        return [
          { field: 'website', label: 'Site', type: 'text', filterable: true, sortable: false},
          { field: 'market', label: 'Market', type: 'text', filterable: false, sortable: false},
          { field: 'last_crawled', label: 'Last Crawled', type: 'text', filterable: false, sortable: false},
          { field: 'cwv_desktop', label: 'Desktop CWV', type: 'text', filterable: false, sortable: false},
          { field: 'cwv_mobile', label: 'Mobile CWV', type: 'text', filterable: false, sortable: false},
          { field: 'sso_button',label: 'Go To', filterable: false, sortable: false},
        ];
      },
      columnTemplates() {
        return {
            website: (row) => {
                const handleOnClick = (e) => {
                    e.stopPropagation();
                };
                return <a href={'https://' + row.website} class="text-capitalize text-dark" onClick={handleOnClick} target="_blank">{row.website}</a>;
            },
            market: (row) => {
              if (row.default_market && row.default_market.label) {
                return <span>{row.default_market.label}</span>;
              } else {
                return <span>Not Available</span>;
              }
            },
            last_crawled: (row) => {
              if (!row.google_page_speed_insight || row.google_page_speed_insight.length === 0) {
                return <div>No Data Available</div>;
              }

              const latestPSI = row.google_page_speed_insight.reduce((prev, current) => {
                return new Date(prev.created_at) > new Date(current.created_at) ? prev : current;
              });

              const psiData = JSON.parse(latestPSI.data);
              
              const desktopCrawledTime = psiData.desktop.crawledTime;
              const mobileCrawledTime = psiData.mobile.crawledTime;
              let displayDate = latestPSI.created_at; 
              
              if (desktopCrawledTime && desktopCrawledTime.length > 0) {
                displayDate = desktopCrawledTime;
              } else if (mobileCrawledTime && mobileCrawledTime.length > 0) {
                displayDate = mobileCrawledTime;
              }
              
              const crawledTime = new Date(displayDate);
              const day = crawledTime.getDate().toString().padStart(2, '0');
              const month = (crawledTime.getMonth() + 1).toString().padStart(2, '0');
              const year = crawledTime.getFullYear();
              const formattedDate = `${day}/${month}/${year}`;

              return <span class="semi-bold">{formattedDate}</span>;
            },
            cwv_desktop: (row) => {
              if (!row.google_page_speed_insight || row.google_page_speed_insight.length === 0) {
                return <ProgressCircle value={NaN} />;
              } else {
                const latestPSI = row.google_page_speed_insight.reduce((prev, current) => {
                  return new Date(prev.created_at) > new Date(current.created_at) ? prev : current;
                });

                const metrics = {
                  CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.1, poor: 0.25, weight: 25 },
                  EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.8, poor: 1.8, weight: 10 },
                  FIRST_CONTENTFUL_PAINT_MS_average: { good: 1.8, poor: 3, weight: 10 },
                  INTERACTION_TO_NEXT_PAINT_average: { good: 0.2, poor: 0.5, weight: 30 },
                  LARGEST_CONTENTFUL_PAINT_MS_average: { good: 2.5, poor: 4, weight: 25 }
                };

                const psiData = JSON.parse(latestPSI.data);
                let desktopAverage = psiData.desktop.originData.averageData;
                
                let desktopScore = 0;

                Object.keys(desktopAverage).forEach(key => {
                  if (metrics.hasOwnProperty.call(metrics,key)) { 
                  let currentDesktopScore = Math.round(this.calculateMetricCoverage(metrics[key].good, metrics[key].poor, desktopAverage[key]));
                  desktopScore += this.calculateWeightedScore(currentDesktopScore, metrics[key].weight);
                  }
                });
                
                let performanceScore = desktopScore;

                if(this.isText(psiData.desktop.originData.performanceScore)){
                  performanceScore = NaN;
                }
                return <ProgressCircle value={performanceScore}/>;
              }
            },
            cwv_mobile: (row) => {
              if (!row.google_page_speed_insight || row.google_page_speed_insight.length === 0) {
                return <ProgressCircle value={NaN} />;
              } else {
                const latestPSI = row.google_page_speed_insight.reduce((prev, current) => {
                  return new Date(prev.created_at) > new Date(current.created_at) ? prev : current;
                });

                const metrics = {
                  CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.1, poor: 0.25, weight: 25 },
                  EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.8, poor: 1.8, weight: 10 },
                  FIRST_CONTENTFUL_PAINT_MS_average: { good: 1.8, poor: 3, weight: 10 },
                  INTERACTION_TO_NEXT_PAINT_average: { good: 0.2, poor: 0.5, weight: 30 },
                  LARGEST_CONTENTFUL_PAINT_MS_average: { good: 2.5, poor: 4, weight: 25 }
                };


                const psiData = JSON.parse(latestPSI.data);

                let mobileAverage = psiData.mobile.originData.averageData;

                let mobileScore = 0;

                Object.keys(mobileAverage).forEach(key => {
                  if (metrics.hasOwnProperty.call(metrics,key)) { 
                  let currentMobileScore = Math.round(this.calculateMetricCoverage(metrics[key].good, metrics[key].poor, mobileAverage[key]));
                  mobileScore += this.calculateWeightedScore(currentMobileScore, metrics[key].weight);
                  }
                });
                let performanceScore = mobileScore;

                if(this.isText(psiData.mobile.originData.performanceScore)){
                  performanceScore = NaN;
                }

                return <ProgressCircle value={performanceScore}/>;              
              }
            },
            sso_button: (row) => {
              const handleOnClick = (e) => {
                e.stopPropagation();
                if (row.theme === 'Olympus') {
                  e.preventDefault(); 
                  this.siteLogin(row);
                }
              };
              return (
                <div class="d-flex justify-content-end">
                  {row.theme === 'Olympus' && (
                    <a href="#" class="btn-sso btn-wordpress" onClick={handleOnClick} title="Login to WordPress">
                      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 128 128"><path fill="currentColor" fillRule="evenodd" d="M64.094 126.224c34.275-.052 62.021-27.933 62.021-62.325c0-33.833-27.618-61.697-60.613-62.286C30.85.995 1.894 29.113 1.885 63.21c-.01 35.079 27.612 63.064 62.209 63.014M63.993 4.63c32.907-.011 59.126 26.725 59.116 60.28c-.011 31.679-26.925 58.18-59.092 58.187c-32.771.007-59.125-26.563-59.124-59.608c.002-32.193 26.766-58.848 59.1-58.859M39.157 35.896c.538 1.793-.968 2.417-2.569 2.542c-1.685.13-3.369.257-5.325.406c6.456 19.234 12.815 38.183 19.325 57.573c.464-.759.655-.973.739-1.223c3.574-10.682 7.168-21.357 10.651-32.069c.318-.977.16-2.271-.188-3.275c-1.843-5.32-4.051-10.524-5.667-15.908c-1.105-3.686-2.571-6.071-6.928-5.644c-.742.073-1.648-1.524-2.479-2.349c1.005-.6 2.003-1.704 3.017-1.719a849.593 849.593 0 0 1 26.618.008c1.018.017 2.016 1.15 3.021 1.765c-.88.804-1.639 2.01-2.668 2.321c-1.651.498-3.482.404-5.458.58l19.349 57.56c2.931-9.736 5.658-18.676 8.31-27.639c2.366-8.001.956-15.473-3.322-22.52c-1.286-2.119-2.866-4.175-3.595-6.486c-.828-2.629-1.516-5.622-1.077-8.259c.745-4.469 4.174-6.688 8.814-7.113C74.333.881 34.431 9.317 19.728 34.922c5.66-.261 11.064-.604 16.472-.678c1.022-.013 2.717.851 2.957 1.652m10.117 77.971c-.118.345-.125.729-.218 1.302c10.943 3.034 21.675 2.815 32.659-.886l-16.78-45.96c-5.37 15.611-10.52 30.575-15.661 45.544m-8.456-2.078l-25.281-69.35c-11.405 22.278-2.729 56.268 25.281 69.35m76.428-44.562c.802-10.534-2.832-25.119-5.97-27.125c-.35 3.875-.106 8.186-1.218 12.114c-2.617 9.255-5.817 18.349-8.899 27.468c-3.35 9.912-6.832 19.779-10.257 29.666c16.092-9.539 24.935-23.618 26.344-42.123" clipRule="evenodd"></path></svg>
                    </a>
                  )}
                  <a href={"https://search.google.com/search-console?utm_source=about-page&resource_id=sc-domain:" + row.website} class="btn-sso btn-gsc ml-4" target="_blank" title="View Google Search Console Data">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 228"><defs><radialGradient id="logosGoogleSearchConsole0" cx="21.66%" cy="28.708%" r="82.87%" fx="21.66%" fy="28.708%" gradientTransform="matrix(.59503 .59486 -.44034 .80383 .214 -.073)"><stop offset="0%" stop-color="#f1f2f2"/><stop offset="100%" stop-color="#e6e7e8"/></radialGradient></defs><path fill="#737373" d="M165.979 0H90.021L71.097 19.055v18.924h18.924V19.055h75.958v18.924h18.924V19.055z"/><path fill="#bfbfbf" d="M90.021 0v19.055h75.958V0z"/><path fill="url(#logosGoogleSearchConsole0)" d="M36.402 37.98L0 74.381v134.177c0 10.513 8.542 18.924 18.924 18.924h218.152c10.513 0 18.924-8.543 18.924-18.924V74.513L219.466 37.98z"/><path fill="#fff" d="M28.517 109.076h199.097v118.538H28.517z"/><path fill="#e0e0e0" d="M36.402 37.979L0 74.382v34.694h256V74.513l-36.534-36.534z"/><path fill="#d1d1d1" d="M42.71 213.29H128v14.193H42.71z"/><path fill="#4285f4" d="M28.517 86.998a14.695 14.695 0 0 1 14.72-14.719h169.527a14.695 14.695 0 0 1 14.719 14.719v22.078H28.517z"/><path fill="#e6e6e6" d="M56.903 90.152a7.067 7.067 0 0 1-7.096 7.096a7.067 7.067 0 0 1-7.097-7.096a7.067 7.067 0 0 1 7.097-7.097a7.067 7.067 0 0 1 7.096 7.097m23.656 0a7.067 7.067 0 0 1-7.097 7.096a7.067 7.067 0 0 1-7.096-7.096a7.067 7.067 0 0 1 7.096-7.097a7.067 7.067 0 0 1 7.097 7.097"/><path fill="#bababa" d="m227.483 165.191l-29.832-29.832l-9.988 30.883l-40.739-40.608l-1.183 62.686l15.113 23.655c2.234-.394-11.302 15.508-11.302 15.508h77.93z"/><path fill="#4d4d4d" d="M208.821 164.008c0-16.821-9.856-31.277-23.918-38.242v39.95l-18.792 10.12l-19.056-10.12v-40.082c-14.061 6.966-23.655 21.553-23.655 38.243c0 16.821 9.725 31.277 23.787 38.242v25.364h37.848v-25.364c13.93-6.834 23.786-21.42 23.786-38.11"/><path fill="#d1d1d1" d="M42.71 123.269h66.366v75.828H42.71z"/></svg>
                  </a>
                </div>
              );
            },
        };
      },
      path() {
        return 'hercules/pandora/sites?with=default_market,google_page_speed_insight';
      }
    }
  }
  </script>
  
<style scoped>
.gsc{
  margin-left: 8px;
}
.poor {
    background-color: #FFF5F5;
    border: 1px solid #F2281D;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
}
.needs-improvement {
    background-color: #FFF2E7;
    border: 1px solid #FF8F2F;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
}
.good {
    background-color: #F2FAF4;
    border: 1px solid #5CE482;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
}
.not-available {
  background-color: #F2F2F2;
  border: 1px solid #BDBDBD;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
}
.btn-sso{
  width: 40px;
  height: 40px;
  background-color: #F6F6FB;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.btn-sso svg{
  width: 24px;
  height: 24px;
}
.btn-wordpress {
  color: #21759B;
}

</style>