<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pb-4">
          <h5 class="modal-title" v-if="changeResult === null">Change page type</h5>
          <h5 class="modal-title" v-else>Page type changed successfuly</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div v-if="changeResult === null">
            <p class="text-muted">
              <i class="uil uil-info-circle mr-1" /> Any unsaved changes made before changing the page type
              will be lost.
            </p>
            <dynamic-form
              form-type="edit"
              v-if="object"
              ref="form"
              :formFields="structure.list"
              path="sites/sites"
              :structure="structure"
              :object="object"
              :show-submit="false"
              :on-submit="submit"
              submit-label="Change"
              :closeOnDestroy="false"
            />
            <page-type-compare :difference="pageTypeDifference" />
            <div class="px-3 pt-3 border mb-3">
              <h5 class="text-danger mb-3">
                If this page was manually selected in a cards v2 module using
                {{ object && object.current_page_type }} page type, it will be removed from the module.
              </h5>
            </div>
            <div class="text-right">
              <button
                class="btn btn-primary"
                :class="{ loading: submitLoading }"
                @click="$refs.form.validateBeforeSubmit()"
              >
                <i class="uil uil-check"></i> Change page type
              </button>
            </div>
          </div>
          <div v-if="changeResult" class="">
            <p>
              Page type was successfully changed to
              <strong> {{ changeResult.page.template.name }}</strong
              >. To edit the page, a reload is required.
            </p>
            <div class="mb-3">
              <button class="btn btn-primary mt-2" @click="reloadPage">
                <i class="uil uil-refresh"></i>Reload this page
              </button>
            </div>
            <div v-if="changeResult.backup_page" class="border-top pt-3">
              <p>
                Your backup is available via the following URL
                <span class="text-muted">(and opens in a new tab)</span>
              </p>
              <a :href="getBackupUrl()" target="_blank">{{ getBackupUrl() }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesService from '@services/PagesService'
import TemplatesService from '@services/TemplatesService'
import DynamicForm from '@/components/fields/DynamicForm'
import PageTypeChange from '@models/cms/PageTypeChange'
import PageTypeCompare from '@atoms/cms/pages/type-compare.vue'

export default {
  components: { DynamicForm, PageTypeCompare },
  data() {
    return {
      loading: false,
      submitLoading: false,
      changeResult: null,
      object: {},
      structure: {},
      pageTypeDifference: null,
      config: PageTypeChange.getConfig(),
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  watch: {
    object: {
      handler(newValue) {
        if (!newValue || newValue.page_type_id === null) {
          this.pageTypeDifference = null
          return
        }
        this.checkPageTypeDifference()
      },
      deep: true,
    },
  },
  methods: {
    show(object) {
      let structure = PageTypeChange.getStructure()
      let pageTypeField = structure.list.find((field) => field.name === 'page_type_id')
      pageTypeField.search_url = pageTypeField.search_url.concat(`&ids_not=${object.template_id}`) // filter out existing page type
      this.structure = structure
      this.object = {
        site_id: object.site_id,
        page_id: object.id,
        page_type_id: null,
        current_page_type_id: object.template_id,
        site_market_id: object.site_market_id,
        current_page_type: object.template.name,
      }
      this.$refs.modal.classList.add('show')
    },
    close() {
      // if change was successful, closing the modal is not allowed; needs a full refresh
      if (this.changeResult) {
        return
      }
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.changeResult = null
      this.pageTypeDifference = null
      this.object = null
    },
    async submit() {
      this.submitLoading = true
      await PagesService.changePageType(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.$cookies.set('formIsDirty', false)
            this.showSuccessMessage('Page type changed')
            this.changeResult = response.data.result
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    async checkPageTypeDifference() {
      this.submitLoading = true
      await TemplatesService.compare({
        source_page_id: this.object.page_id,
        target_page_type_id: this.object.page_type_id,
      })
        .then((response) => {
          this.pageTypeDifference = response.data.result
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    getBackupUrl() {
      return window.location.origin + '/site-pages/' + this.changeResult.backup_page.id
    },
    reloadPage() {
      window.location = '/site-pages/' + this.object.page_id
    },
  },
}
</script>
