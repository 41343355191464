<template>
  <data-table
    model="cms/ExtraField"
    service-file="SiteExtraFieldsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
  />
</template>

<script>
import BasicOptions from '@mixins/BasicOptions'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_code', type: 'text', filterable: true },
        { field: 'help_text', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn('required'), {
          template: TableColumnTemplate.BOOLEAN,
        }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/sites/extra-fields?owner_type=section&owner_id=${this.$route.params.id}`
    },
    createDefaults() {
      return {
        owner_id: parseInt(this.$route.params.id),
        owner_type: 'section',
      }
    },
  },
}
</script>
