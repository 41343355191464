<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="edit"
      :formFields="structure.list"
      :path="config.url"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :on-remove="remove"
      :inCard="true"
    />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import DynamicForm from '@/components/fields/DynamicForm'
import Game from '@models/Game'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import GamesService from '@services/GamesService'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { MultiSelect, DynamicForm, ConfirmDialog },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Game.getConfig(),
    }
  },
  mounted() {
    this.getGame()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} General`)
  },
  methods: {
    getGame() {
      GamesService.getOne(this.$route.params['id'], {
        with: 'software,game_categories,themes,currencies',
      }).then((response) => {
        this.object = response.data.result[0]
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = Game.getStructure()
    },
    submit() {
      delete this.object.status_changed_by
      this.$refs.form
        .process(this.object)
        .then((response) => {
          delete response.iframe_is_valid
          delete response.iframe_status_message
          delete response.iframe_last_validated
          return GamesService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage(`${this.structure.config.name} updated`)
            this.$parent.$parent.getGame()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure want to delete this item?')) {
        GamesService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/games')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
