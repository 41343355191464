<template>
  <div class="card">
    <div class="card-header">
      <h2>Maintenance mode</h2>
    </div>
    <div class="card-body">
      <form v-on:submit.prevent="save()">
        <base-input v-model="text" :required="true" label="Message" placeholder="Type a message" />
        <div class="switch">
          <input type="checkbox" v-model="enabled" id="maintenance-enabled" />
          <label for="maintenance-enabled">Enabled</label>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <button class="btn btn-primary" @click="save">Save</button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import SettingsService from '@services/SettingsService'

export default {
  components: { BaseInput },
  data() {
    return {
      enabled: false,
      text: '',
    }
  },
  mounted() {
    SettingsService.getAll({
      keys: ['maintenance_mode_enabled', 'maintenance_mode_text'],
    })
      .then((response) => {
        if (response.data.success) {
          const result = response.data.result
          this.enabled = result.find((setting) => setting.key === 'maintenance_mode_enabled').value === '1'
          this.text = result.find((setting) => setting.key === 'maintenance_mode_text').value
        }
      })
      .catch(this.showUnknownErrorMessage)
  },
  methods: {
    save() {
      SettingsService.update({
        maintenance_mode_enabled: this.enabled ? 1 : 0,
        maintenance_mode_text: this.text,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            this.emitter.emit('toggle-maintenance-mode', true)
          } else {
            this.emitter.emit('toggle-maintenance-mode', this.enabled)
            this.showSuccessMessage('Settings saved')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
