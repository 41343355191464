<template>
  <data-table
    model="Country"
    service-file="CountriesService"
    path="hercules/globals/countries"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'code', type: 'text' },
        { field: 'name', type: 'text' },
        { field: 'updated_at', type: 'text', template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'actions', filterable: false }
      ]
    }
  }
}
</script>
