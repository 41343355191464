<template>
  <data-table
    model="SiteConfig"
    service-file="SiteConfigsService"
    path="hercules/sites/deployments"
    ref="list"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :rowActionButtons="rowActions"
    :showRemove="false"
    :showCreate="false"
    :enableRowClick="false"
  />
</template>

<script lang="jsx">
import AffiliateSettingsService from '@services/AffiliateSettingsService'
import DataTable from '@templates/table/table'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  data() {
    return {
      rowActions: [
        {
          action: this.deploySite,
          title: 'Deploy',
          icon: 'uil uil-rocket',
          class: 'btn-info',
        },
        {
          action: this.visitSite,
          title: 'Log',
          icon: 'uil uil-info-circle',
          class: 'btn-secondary',
        },
      ],
    }
  },
  components: {
    DataTable,
  },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'environment', type: 'text', filterable: true },
        { field: 'branch', type: 'text', filterable: true },
        { field: 'total', type: 'text', label: 'Attempts', filterable: false },
        { field: 'success_rate', type: 'text', label: 'Success %', filterable: false },
        { field: 'average_duration', type: 'text', label: 'Avg Duration', filterable: false },
        { field: 'status', type: 'text', label: 'Status', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {
        average_duration: function (row) {
          if (row.average_duration) {
            return Math.round(row.average_duration / 1000 / 60) + ' minutes'
          }
          return '-'
        },
        success_rate: function (row) {
          if (row.success_rate) {
            return Math.round(row.success_rate) + '%'
          }
          return '-'
        },
        status: function (row) {
          if (row.status === 'success') {
            return <span class="badge badge-success">success</span>
          } else if (row.status === 'running') {
            return <span class="badge bg-yellow-50">running</span>
          } else if (row.status === 'failed') {
            return <span class="badge badge-danger">failed</span>
          }
          return <span class="badge bg-black-25">{row.status}</span>
        },
      }
    },
    deploySite(row) {
      const self = this
      AffiliateSettingsService.deploy(row.id)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Deploying, the list will automatically refresh in one minute')
            setTimeout(function () {
              self.$refs.list.search()
            }, 60)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          self.$refs.list.search()
        })
    },
    visitSite(row) {
      if (row.status_url) {
        window.open(row.status_url, '_blank')
      } else {
        this.showErrorMessages('Log not available')
      }
    },
  },
}
</script>
