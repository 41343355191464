<template>
  <data-table
      model="AffiliateProgram"
      service-file="AffiliateProgramsService"
      path="hercules/operators/affiliate-programs"
      :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'ask_gamblers_id', type: 'text', filterable: true },
        { field: 'updated_at', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    }
  }
}
</script>
