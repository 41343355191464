<template>
  <div class="modal" ref="modal" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ labelText }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <dynamic-form
          form-type="create"
          v-if="linkObject"
          :structure="structure"
          :object="linkObject"
          :path="structure.config.url"
          :formFields="structure.list"
          :on-submit="submit"
          :inModal="true"
          ref="form"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService'
import AffiliateLink from '@models/AffiliateLink'

export default {
  components: { DynamicForm },
  computed: {
    labelText() {
      return this.structure.config !== undefined ? 'Add New ' + this.structure.config.name : ''
    },
  },
  data() {
    return {
      affiliateId: null,
      structure: AffiliateLink.getStructure(),
      linkObject: {},
    }
  },
  methods: {
    show(affiliateId) {
      this.affiliateId = affiliateId
      this.$nextTick(() => {
        document.getElementById('modal').appendChild(this.$el)
        this.$refs.modal.classList.add('show')
      })
    },
    submit() {
      const form = this.$refs.form
      form.isLoading = true
      AffiliateOperatorsService.postLink(this.affiliateId, this.linkObject)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage(`${this.structure.config.name} created`)
            Object.assign(this.$data, this.$options.data())
            this.$emit('refresh', response.data.result.id)
            this.close()
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(function () {
          form.isLoading = false
        })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
    },
  },
}
</script>
