export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'name',
        type: 'text',
        slug_field: 'short_name'
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short name',
        type: 'slug',
        disabled: true
      },
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'name',
        only_create: true,
        notify_fields: [{ field: 'enabled_sections', param: 'site_id' }]
      },
      {
        name: 'type',
        required: true,
        display_name: 'Relation type',
        type: 'list-one',
        options: [
          'page',
          'operator',
          'game',
          'article',
          'author',
          'top_list',
          'software_provider',
          'payment_method',
          'game_category',
          'sport',
          'event',
          'team',
          'tournament',
          'provider',
          'gambling_company',
          'affiliate_program'
        ],
        selectLabel: '',
        help_text: 'Pick page if there is no relation. Only Page relations can be changed to another relation type.',
      },
      {
        name: 'enabled_sections',
        search_url: 'hercules/sites/sections',
        display_name: 'Sections enabled',
        type: 'list-many',
        validate_changes: true,
        required: false,
        selectLabel: 'name',
        fetch_on_load: false,
        notified_by: [{ field: 'site_id', label: 'Site' }]
      },
      {
        name: 'has_featured_image',
        type: 'checkbox',
        switch: true
      },
      {
        name: 'has_description',
        type: 'checkbox',
        switch: true
      },
      {
        name: 'is_filterable',
        type: 'checkbox',
        required: false,
        display_name: 'Is filterable',
        switch: true,
        help_text: 'Will be used in the cards v2 module and other filterable functions'
      },
      {
        name: 'country_filterable',
        type: 'checkbox',
        required: false,
        display_name: 'Enable Country field',
        switch: true,
        help_text: 'Field can be used to associate a page of this page type to one or more countries'
      },
      {
        name: 'multiple_relation_type',
        type: 'checkbox',
        required: false,
        display_name: 'Enable multiple relations',
        switch: true,
        help_text: 'Allow user to select multiple relations'
      },
      {
        name: 'exclude_from_search_result',
        type: 'checkbox',
        required: false,
        display_name: 'Exclude from search results',
        switch: true
      },
      {
        name: 'feedback_counter',
        type: 'checkbox',
        required: false,
        display_name: 'Feedback counter',
        switch: true,
        help_text:'For non-Gatsby websites: please reload the JSON listener after enabling the feedback counter.'
      },
      {
        name: 'enable_autogenerated_content_tab',
        type: 'checkbox',
        required: false,
        display_name: 'Enable autogenerated content tab',
        switch: true
      },
      {
        name: 'enable_manual_published_date',
        type: 'checkbox',
        required: false,
        display_name: 'Enable manual published date',
        switch: true,
        help_text: 'Manually added dates will not be removed from the database if this feature is disabled.'
      },
      {
        name: 'enable_manual_updated_date',
        type: 'checkbox',
        required: false,
        display_name: 'Enable manual updated date',
        switch: true,
        help_text: 'Manually added dates will not be removed from the database if this feature is disabled.'
      },
    ]
  },
  getConfig() {
    return {
      url: 'sites/templates',
      name: 'Page Type',
      editWithData: 'enabled_sections,site',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
