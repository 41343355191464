import axios from 'axios'
import { router } from '../router'
import { createAuth } from '@websanova/vue-auth'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
export default (app) => {
  app.use(
    createAuth({
      plugins: {
        http: axios, // Axios
        router: router,
      },
      drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
      },
      options: {
        rolesKey: 'type',
        fetchData: {
          url: 'backoffice/auth/user',
        },
        refreshData: {
          url: 'backoffice/auth/refresh',
          enabled: true,
          interval: 10,
        },
        loginData: {
          url: 'backoffice/auth/login',
        },
        notFoundRedirect: { name: 'user-account' },
      },
    })
  )
}
