<template>
  <div>
    <data-table
      model="koala/Accounts"
      service-file="koala/AccountsService"
      :path="path()"
      permissionPath="koala/v1/accounts"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      @bulkClickByIds="bulkClickByIds"
      :enableBulkSelect="true"
      :rowActionButtons="rowActions()"
      ref="table"
      :tableLimits="[10, 50, 100]"
    />
    <credentials-modal ref="modal" :createCredentials="createCredentials" />
    <monitoring-info-modal ref="modalMonitoring" :object="migrationObject"/>
    <dynamic-bulk-edit
      v-if="showBulkEdit"
      ref="bulkEdit"
      model="koala/Accounts"
      service-file="koala/AccountsService"
      @closed="() => (this.showBulkEdit = false)"
      @refresh="$refs.table.search()"
    />
  </div>
</template>

<script lang="jsx">
import CredentialsModal from '@molecules/koala/credentials-modal.vue'
import TableColumnTemplate from '@constants/table-column-template'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import AccountsService from '@services/koala/AccountsService'
import UsersService from '@services/UsersService'
import DynamicBulkEdit from '@molecules/koala/dynamic-bulk-edit.vue'
import FilterChangedLogic from '@mixins/FilterChangedLogic'
import RunAccount from '@mixins/RunAccount'
import BasicOptions from '@mixins/BasicOptions'
import AccountStatus from '@atoms/table/cell/account-status.vue'
import MonitoringInfoModal from '@molecules/koala/monitoring-info/monitoring-info-modal.vue'
import Api from '@services/Api'

export default {
  mixins: [FilterChangedLogic, RunAccount],
  created() {
    this.setTitle()
    this.getUsers()
  },
  data() {
    return {
      createCredentials: false,
      users: [],
      showBulkEdit: false,
      account_manager_id: null,
      migrationObject: []
    }
  },
  components: { DynamicBulkEdit, CredentialsModal, MonitoringInfoModal },
  methods: {
    columns() {
      return [
        {
          field: 'finance_id',
          type: 'text',
          label: 'ID',
          filterable: true,
          advancedFilter: false,
          sortable: true,
          order_key: 'finance_id'
        },
        Object.assign({}, BasicOptions.booleanColumn('operator.platform.scrapable'), {
          search_key: 'platform_scrapable',
          template: TableColumnTemplate.BOOLEAN,
          label: 'Platform Scrapable',
          customOptions: [
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' }
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          clearable: true
        }),
        {
          label: 'Platform',
          field: 'platform_name',
          type: 'text',
          class: 'col-platforms',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          order_key: 'operator.platform.name',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name'
        },
        {
          label: 'Operator',
          field: 'operator',
          type: 'text',
          class: 'col-operators',
          search_key: 'operators',
          advancedFilter: true,
          path: this.computedPath.operatorsPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          order_key: 'operator.name',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name'
        },
        {
          label: 'Username',
          field: 'username',
          type: 'text',
          class: 'col-username',
          search_key: 'username',
          advancedFilter: true,
          optionLabelKey: 'username',
          showModalAdvanced: true,
          basicFilterType: 'text',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'username',
          showAllOption: true,
          searchBy: 'username',
          width: '200px'
        },
        {
          field: 'operator.base_url',
          fieldCustomName: 'Base URL',
          type: 'text',
          search_key: 'operator_base_url',
          label: 'Base URL',
          filterable: true,
          advancedFilter: true,
          sortable: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
          template: TableColumnTemplate.LINK_CELL,
        },
        {
          field: 'currency',
          type: 'text',
          class: 'col-currencies',
          search_key: 'currencies',
          advancedFilter: true,
          path: 'koala/v1/currencies?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          order_key: 'currency.name',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name'
        },
        {
          field: 'business_unit',
          type: 'text',
          class: 'col-business_units',
          search_key: 'business_units',
          advancedFilter: true,
          path: 'koala/v1/business-units?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          order_key: 'business_unit.name',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name'
        },
        {
          label: 'Status',
          field: 'status',
          search_key: 'status',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          clearable: true,
          allowNullOption: true,
          customOptions: [
            { label: 'Active', id: 'Active' },
            { label: 'Closed', id: 'Closed' },
            { label: 'Migrated', id: 'Migrated' },
            { label: 'Merged', id: 'Merged' }
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
        },
        { field: 'migrated_to_date', type: 'text', filterable: false, sortable: false, template: TableColumnTemplate.FORMATTED_DATE_TIME },
        {
          field: 'notes',
          type: 'text',
          filterable: false,
          sortable: false
        },
        {
          field: 'account_manager_name',
          type: 'text',
          class: 'col-account_manager_name',
          search_key: 'account_manager_name',
          advancedFilter: true,
          path: 'backoffice/users/byRole?roles=accounts,affiliate management&orderBy=name&ascending=1',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'name',
          showAllOption: true,
          searchBy: 'name'
        },
        {
          field: 'proxy',
          type: 'text',
          class: 'col-proxies',
          search_key: 'proxies',
          advancedFilter: true,
          path: 'koala/v1/proxies?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          order_key: 'proxy.name',
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name'
        },
        {
          field: 'active',
          label: 'Enabled',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          clearable: true,
          allowNullOption: true,
          customOptions: [
            { label: 'Enabled', id: 'true' },
            { label: 'Disabled', id: 'false' }
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          order_key: 'active',
          sortable: true
        },
        {
          label: 'Last Enabled / Disabled',
          field: 'active_updated',
          type: 'text',
          filterable: false,
          sortable: true,
          template: TableColumnTemplate.FORMATTED_DATE_TIME
        },
        { field: 'updated', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.table.isLoading = true
      let object = { id }
      object[field] = value
      AccountsService.patch(object)
        .then(() => {
          this.$refs.table.search()
          this.showSuccessMessage('Column updated')
        })
        .catch(() => {
          this.showErrorMessage('Failed to update account')
          this.$refs.table.isLoading = false
        })
    },
    columnTemplates() {
      const self = this
      return Object.assign({
        name: function(row) {
          return row.name
        },
        active: function(row) {
          return <EnabledActionColumn row={row} field={'active'} onColumnClick={self.actionColumnClick} />
        },
        operator: function(row) {
          return row.operator.name
        },
        currency: function(row) {
          return row.currency.name
        },
        business_unit: function(row) {
          return row.business_unit.name
        },
        account_manager: function(row) {
          return row.account_manager_name
        },
        proxy: function(row) {
          return row.proxy ? row.proxy.name + ' - ' + row.proxy.ip : ''
        },
        status: function(row) {
          return <AccountStatus row={row} status={row.status} />
        }
      })
    },
    bulkClickByIds(ids, filteredResults, filters, resultCount) {
      if (filteredResults) {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByFilters(filters, resultCount, { users: this.users }))
      } else {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByIds(ids, { users: this.users }))
      }
    },
    bulkClickByFilters(filters, resultCount) {
      this.showBulkEdit = true
      this.$nextTick(() => this.$refs.bulkEdit.showByFilters(filters, resultCount))
    },
    rowActions() {
      return [
        {
          action: row => this.runAccount(row.id),
          title: 'Run Account',
          icon: 'uil uil-play',
          class: 'btn-success',
          disabledCondition: row => !row.active
        },
        {
          action: this.credentials,
          title: 'Credentials',
          icon: 'uil uil-key-skeleton',
          class: 'btn-info'
        },
        {
          action: this.migratedInfo,
          title: 'Migration info',
          icon: 'uil uil-info-circle',
          class: 'btn-info',
          disabledCondition: row => !row.migrated_to_id || row.migrated_from_ids.length < 0
        },
        {
          action: this.monitoringAction,
          title: 'Monitoring',
          icon: 'uil uil-align-justify',
          class: 'btn-warning'
        },
      ];
    },
    path() {
      return `koala/v1/accounts`
    },
    migratedInfo(row){
      Api().get(`${this.path()}/${row.migrated_to_id}`)
        .then(response => {
          const result = response.data.result[0];
          this.migrationObject = {
            id: result.id,
            scrapable: result.operator.platform.scrapable,
            platform: result.platform_name,
            operator: result.operator.name,
            username: result.username,
            status: result.status,
            account_manager_name: result.account_manager_name,
            active: result.active,
            migrated_from_ids: row.migrated_from_ids
          };
          this.$refs.modalMonitoring.show()
        })
        .catch(() => {
          this.showErrorMessage('Failed to get migrated to id account.')
        })
    },
    monitoringAction(row) {
      let routeData = this.$router.resolve({
        path: `/koala-monitoring?`,
        query: {
          accounts: row.id,
        }
      })
      window.open(routeData.href, '_blank');
    },
    credentials(row) {
      let object = { id: row.id, operator: row.operator.name, username: row.username }
      AccountsService.getCredentials(row.id)
        .then(response => {
          if (response.data.messages) {
            this.$refs.modal.object = object
            this.$refs.modal.show()
            this.createCredentials = true
            return
          }
          const { password, api_credentials } = response.data.result[0]
          this.createCredentials = false
          object = {
            ...object,
            password: password,
            api_username: api_credentials.api_username,
            api_key: api_credentials.api_key
          }

          this.$refs.modal.object = object
          this.$refs.modal.show()
        })
        .catch(() => {
          this.showErrorMessage('Failed to get credentials')
        })
    },
    getUsers() {
      UsersService.getUsersByRole({ roles: 'accounts,affiliate management' }).then(response => {
        if (response.data.success) {
          response.data.result.forEach(user => {
            this.users[user.id] = user.name
          })
        }
      })
    }
  }
}
</script>
