<template>
  <data-table
    model="cms/TemplateBlock"
    service-file="TemplateBlocksService"
    :path="`hercules/sites/template-blocks?with=template,section&template_id=${$route.params.id}`"
    :columns="columns()"
    :create-defaults="createDefaults()"
    pusherModel="sites-template_blocks"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import BasicOptions from '@mixins/BasicOptions'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'section.name', type: 'text', label: 'Section', filterable: true },
        { field: 'anchor_label', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        template_id: parseInt(this.$route.params['id']),
        site_id: this.$parent.$parent.template.site_id,
      }
    },
  },
}
</script>
