<template>
  <div class="sidebar narrow">
    <div class="sidebar-heading">
      <div class="sidebar-brand" @click="goHome()">
        <router-link :to="{ path: '/' }" class="d-flex align-items-center sidebar-icon">
          <svg
            id="sidebar-logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 117.96 117.84"
            height="40px"
            fill="#fff"
          >
            <path
              d="M117.96 29.34v59.39c0 8.31-2.42 15.35-7.03 20.43l-.33.36c-.13.14-.26.28-.4.41-.05.06-.11.11-.17.17l-.07.07c-.05.05-.11.11-.17.16-.07.07-.15.14-.22.21l-.36.33c-4.93 4.44-11.88 6.97-20.62 6.97H29.36c-8.74 0-15.69-2.53-20.62-6.97l-.36-.33c-.13-.13-.27-.26-.4-.38-.08-.08-.16-.16-.23-.24-.13-.13-.26-.26-.39-.4l-.33-.36C2.42 104.09 0 97.04 0 88.73V29.34c0-9 2.54-15.97 7.03-20.86.11-.12.22-.24.33-.35l.12-.12c.05-.05.11-.11.16-.17l.07-.07c.06-.06.11-.11.17-.16.1-.1.21-.2.32-.31l.36-.33C13.67 2.35 20.83 0 29.36 0H88.6c8.54 0 15.7 2.35 20.81 6.97l.36.33c.1.1.21.2.31.29.08.08.16.15.23.23.1.1.2.2.29.3.11.12.22.23.33.35 4.49 4.9 7.03 11.88 7.03 20.87"
              :fill="$store.state.app.environmentColor"
            />
            <path
              class="st1"
              d="M68.16 16.66l-.93 25.83 9.71 6.96v33.64l-10.89-7.8-.89 24.54 13.25-8.56 14.64-38.22 8.63.37zM41.02 83.09V49.45l9.83-7.05-.92-25.74-33.52 36.76 8.63-.37 14.64 38.22 13.25 8.56-.89-24.64z"
            />
            <path fill="#ffd100" d="M72.94 51.51L58.98 41.5 45.02 51.51V75.3l13.96-10 13.96 10z" />
          </svg>
        </router-link>
      </div>
    </div>
    <div class="sidebar-body menu-sidebar-body" v-if="userHasRoles">
      <div class="sidebar-body-content">
        <ul ref="sidebar-items" class="nav ul-nav overflow-scrollbar">
          <li
            v-for="(menu, index) in structure"
            :key="index"
            @click="openCategory(menu.name)"
            :class="{ active: menu.name === selectedItem, 'sticky-menu-item': index === 0 }"
            class="d-flex flex-column align-items-center justify-content-center cursor-pointer"
          >
            <i :class="menu.icon"></i>
            <span>{{ menu.displayName ? menu.displayName : menu.name !== 'Search' ? menu.name : '' }}</span>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="userHasRoles">
      <div class="collapse-submenu submenu-open" @click="collapseSubmenu(false)" v-if="submenuOpen">
        &larr;
      </div>
      <div class="collapse-submenu" @click="collapseSubmenu(true)" v-else>&rarr;</div>
    </template>
  </div>
</template>

<script>
import $ from 'jquery'
import hotkeys from 'hotkeys-js'

export default {
  props: {
    structure: {
      type: Array,
      required: true,
    },
    submenuOpen: {
      type: Boolean,
      required: true,
    },
    userHasRoles: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    hotkeys('cmd + /', (event) => {
      if (!this.userHasRoles) {
        return
      }

      event.preventDefault()
      this.$emit('collapseSubmenu', true)
      this.$nextTick(() => {
        this.foucsOnSearch()
      })
    })
    hotkeys('ctrl + /', (event) => {
      if (!this.userHasRoles) {
        return
      }

      event.preventDefault()
      this.foucsOnSearch()
    })

    const self = this
    document.addEventListener('keydown', async function (event) {
      if (
        event.keyCode === 219 &&
        $('input:focus').length == 0 &&
        $('textarea:focus').length == 0 &&
        $('.editr--content:focus').length == 0 &&
        $('.fr-element:focus').length == 0 &&
        $('.ProseMirror-focused').length == 0 &&
        self.userHasRoles
      ) {
        self.collapseSubmenu(!self.submenuOpen)
      }
    })
  },

  computed: {
    selectedItem() {
      return this.$store.state.sidebar.selectedSidebarItem
    },
  },
  methods: {
    goHome() {
      this.$store.commit('sidebar/setSelectedSidebarItem', 'Home')
    },
    async foucsOnSearch() {
      await this.$emit('collapseSubmenu', true)
      this.$parent.$el.querySelector('.sidebar-right > div.sidebar-body.new-menu.pl-0 > input').focus()
    },
    collapseSubmenu(collapse) {
      this.$emit('collapseSubmenu', collapse)
    },
    openCategory(name) {
      if (name === 'Search') {
        this.foucsOnSearch()
        return
      }
      if (!this.userHasRoles) {
        return
      }
      if (name === '') {
        this.foucsOnSearch()
        return
      }

      this.$emit('collapseSubmenu', true)
      this.$store.commit('sidebar/setSearch', null)
      this.$store.commit('sidebar/setSelectedSidebarItem', name)
    },
  },
}
</script>
<style lang="scss">
// new sidebar styles
.overflow-scrollbar {
  overflow-y: auto;
  // height:
}

.sticky-menu-item {
  position: sticky;
  top: 0;
  background: white;
}

.menu-sidebar-body {
  &:not(:last-child) {
    bottom: 8px !important;
  }
}
</style>
