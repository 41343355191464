<template>
  <div class="position-relative" v-if="list">
    <div class="toplist-edit-warning d-flex align-items-center font-size-sm bg-orange-10 rounded-lg p-2">
      <i class="uil uil-exclamation-triangle mr-2 text-orange" />
      Save your changes before exiting
    </div>

    <div class="toplist-edit-actions d-flex">
      <div>
        <button v-if="hasPartnership" class="btn btn-sm btn-secondary ml-3" @click="generateWidget">
          <i class="uil uil-apps" />
          Generate widget
        </button>

        <button
          v-if="hasDifferentMarkets"
          class="btn btn-sm btn-secondary ml-3"
          @click="$refs.marketModal.show()"
        >
          <i class="uil uil-globe" />
          Open different market
          <span v-if="hasDifferentMarkets">({{ differentMarkets.length }})</span>
        </button>

        <button v-if="schedulingActive" class="btn btn-sm btn-danger ml-3" @click="toggleScheduling">
          <i class="uil uil-trash" />
          Unschedule
        </button>

        <button v-else class="btn btn-sm btn-secondary ml-3" @click="toggleScheduling">
          <i class="uil uil-calendar-alt" />
          Schedule
        </button>

        <button
          class="btn btn-sm btn-secondary ml-3"
          @click="$refs.cloneModal.show(list)"
          v-if="!isPrefilledTemplate"
        >
          <i class="uil uil-copy" />
          Clone
        </button>
      </div>

      <a
        class="btn btn-sm btn-secondary ml-3"
        :href="`/prefilled-toplists/${list.prefilled_template_id}`"
        target="_blank"
        v-if="isPrefilled"
      >
        <i class="uil uil-edit" />
        Edit prefilled list
      </a>

      <button class="btn btn-sm btn-primary ml-3" :class="isLoading && 'loading'" @click="saveList">
        <i class="uil uil-check" />
        Save
      </button>
    </div>

    <div class="d-flex w-100 toplist-edit-wrapper" :class="[schedulingActive && 'scheduling-active']">
      <Items :list="list" :schedulingActive="schedulingActive" storeName="normalToplist" />

      <Items
        v-if="schedulingActive"
        :list="list"
        :schedulingActive="schedulingActive"
        :scheduledList="true"
        storeName="scheduledToplist"
      />
    </div>
    <clone-modal ref="cloneModal" @refresh="$refs.list && $refs.list.search()" :openInNewTab="true" />
    <change-market-modal v-if="list" ref="marketModal" :list="list" />
  </div>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import TopListsService from '@services/TopListsService'
import moment from 'moment'
import CloneModal from '@molecules/dms/toplists/clone-modal.vue'
import ChangeMarketModal from '@organisms/dms/toplist/change-market-modal.vue'
import AffiliateLinkEdit from '@molecules/dms/affiliate-link/edit-modal.vue'
import AffiliateBonusEdit from '@organisms/dms/toplist/edit-bonus-modal.vue'
import { openDialog } from 'vue3-promise-dialog'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import AddModal from '@organisms/dms/toplist/add-modal.vue'
import Items from './items.vue'
import toplistStore from '@store/modules/toplist'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

const storeNames = ['normalToplist', 'scheduledToplist']

export default {
  components: {
    AddModal,
    AffiliateLinkEdit,
    AffiliateBonusEdit,
    ChangeMarketModal,
    CloneModal,
    Items,
  },
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data: function () {
    return {
      trackerRowEditing: null,
      showAddModal: false,
      showLinkEditModal: false,
      showBonusEditModal: false,
      isLoading: false,
      differentMarkets: null,
      list: null,
      showFullBonus: false,
      showFullTracker: false,
    }
  },
  computed: {
    hasDifferentMarkets() {
      return this.differentMarkets && this.differentMarkets.length > 0
    },
    schedulingActive() {
      return this.list.scheduled_at !== null
    },
    scheduledItems() {
      return this.$store.state[storeNames[1]].items
    },
    hasPartnership() {
      return this.$parent.$parent.siteType === 'partnership'
    },
    isPrefilled() {
      return this.list.list_type === 'prefilled'
    },
    isPrefilledTemplate() {
      return this.list.list_type === 'prefilled_template'
    },
  },
  beforeCreate() {
    storeNames.map((name) => {
      if (!this.$store.state[name]) {
        this.$store.registerModule(name, toplistStore)
      }
    })
  },
  unmounted() {
    storeNames.map((name) => {
      this.$store.dispatch(`${name}/setItems`, [])
    })
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        TopListsService.fetchTopListItems(this.$route.params.id)
          .then((response) => {
            if (response.data.success === false) {
              this.showErrorMessage('Failed to load top list items')
              return reject(false)
            }
            this.list = response.data.result
            this.getDifferentMarkets()

            const mappedItems = response.data.result.real_items.map((item) => {
              item.added = false
              item.removed = false
              item.soft_remove = true
              return item
            })
            const mappedScheduledItems = response.data.result.real_scheduled_items.map((item) => {
              item.added = false
              item.removed = false
              item.soft_remove = true
              return item
            })
            this.$store.dispatch('normalToplist/setItems', mappedItems)
            this.$store.dispatch('scheduledToplist/setItems', mappedScheduledItems)
            resolve(true)
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    async saveList() {
      this.isLoading = true
      const scheduledItems = this.$store.state[storeNames[1]].items
      await this.$http
        .post(`hercules/toplists/lists/${this.list.id}/items/sort`, {
          scheduled_at: this.list.scheduled_at,
          items: this.$store.state[storeNames[0]].items,
          scheduled_items: scheduledItems,
        })
        .then(async (response) => {
          if (response.data.success === true) {
            await this.getItems()
            this.showSuccessMessage('Saved')
          } else {
            this.showErrorMessage('Failed to save list')
          }
        })
        .catch(() => {
          this.showErrorMessage('Failed to save list')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async toggleScheduling() {
      if (this.schedulingActive && this.scheduledItems.length === 0) {
        this.$store.dispatch(`${storeNames[1]}/setItems`, [])
        this.list.scheduled_at = null
      } else if (
        this.schedulingActive &&
        (await confirmDelete(
          'Are you sure?',
          `This will remove the selected items from the scheduled list`,
          'Yes'
        ))
      ) {
        this.$store.dispatch(`${storeNames[1]}/setItems`, [])
        this.list.scheduled_at = null
      } else {
        this.list.scheduled_at = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    },
    getDifferentMarkets() {
      this.$http
        .get('hercules/toplists/lists', {
          params: {
            affiliate_id: this.list.affiliate_id,
            short_code: this.list.short_code,
            type: this.list.type,
            tracker: this.list.tracker,
            one_liner: this.list.one_liner,
            with: 'market',
            orderBy: 'market_name',
            ascending: 1,
            exact_match: 'short_code',
          },
        })
        .then((response) => {
          this.differentMarkets = response.data.result.filter((item) => item.id !== this.list.id)
        })
    },
    exportCsv() {
      const filename = `toplist-${moment().format('YYYY-MM-DD-HHmm')}`
      const csvExporter = new ExportToCsv({
        useKeysAsHeaders: true,
        filename: filename,
      })
      let results = this.items.map((item) => {
        return {
          position: item.sort_nr,
          operator: item.operator.name,
          bonus: item.operator?.affiliate_operators[0]?.types[0]?.format || '',
          tracker: item.operator?.affiliate_operators[0]?.links[0]?.link || '',
        }
      })
      csvExporter.generateCsv(results)
    },
    generateWidget() {
      this.isLoading = true
      this.$http
        .post(`hercules/widgets/toplist`, {
          site_id: this.$parent.$parent.topList.affiliate_id,
          market_id: this.$parent.$parent.topList.market.id,
          title: this.$parent.$parent.topList.label,
          short_code: this.$parent.$parent.topList.short_code,
          type: 'toplist',
        })
        .then((response) => {
          if (response.data.success === true) {
            let widget_toplists_id = response.data.result.id
            this.createWidgetToplistItems(widget_toplists_id)

            this.showSuccessMessage('Widget generated')
          } else {
            this.showErrorMessage(response.data.messages[0])
          }
        })
        .catch((err) => {
          console.log(err)
          this.showErrorMessage('Failed to generate widget')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createWidgetToplistItems(widget_toplists_id) {
      this.$http
        .post(`hercules/widgets/toplist-items`, {
          widget_toplists_id: widget_toplists_id,
          toplist_id: this.$parent.$parent.topList.id,
        })
        .then((response) => {
          if (response.data.success === true) {
            let path = `/site-widgets-toplist/${widget_toplists_id}`

            setTimeout(() => {
              window.open(path, '_blank')
            }, 2000)
          } else {
            this.showErrorMessage('Failed to generate toplist item')
          }
        })
        .catch((err) => {
          console.log(err)
          this.showErrorMessage('Failed to generate toplist item')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.toplist-edit-actions {
  position: absolute;
  top: -50px;
  right: 0;
}
.toplist-edit-warning {
  position: absolute;
  top: -110px;
  right: 0;
  border: 1px solid #f39d1f;
}
.toplist-edit-wrapper {
  gap: 10px;
}
</style>
