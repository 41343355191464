<template>
  <div>
    <data-table
      model="widgets/sports/sports"
      service-file="WidgetSportsService"
      path="hercules/widgets/sports"
      :rowActionButtons="rowActions"
      :columns="columns()"
      class="widgets-table"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  data() {
    return {
      loading: false,
      rowActions: [
        {
          action: this.copyToken,
          title: 'Copy Token',
          icon: 'uil uil-copy',
          class: 'btn-info',
        },
      ],
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    copyToken(row) {
      navigator.clipboard
        .writeText(row.token)
        .then(() => {
          this.showSuccessMessage('Token copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
    columns() {
      return [
        { field: 'site_name', type: 'text', label: 'Site', filterable: true },
        {
          field: 'type',
          type: 'text',
          label: 'Sports type',
          filterable: true,
          basicFilterType: 'select',
          customOptions: [
            { label: 'All', id: null },
            { label: 'Event details', id: 'event_details' },
            { label: 'Tournament standings', id: 'tournament_standings' },
            { label: 'Round schedule', id: 'round_schedule' },
            { label: 'Tournament schedule', id: 'tournaments_schedule' },
          ],
          template: TableColumnTemplate.SPORTS_STATUS,
        },
        { field: 'title', type: 'text', filterable: true },
        {
          field: 'odds_enabled',
          type: 'boolean',
          label: 'Odds',
          filterable: true,
          template: TableColumnTemplate.BOOLEAN,
        },
        {
          field: 'details_label',
          type: 'text',
          label: 'Details',
          filterable: true,
          exportable: false,
          template: TableColumnTemplate.BADGES,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
}
</script>
<style lang="scss">
.widgets-table {
  .card {
    overflow-x: scroll;
  }
}
</style>
