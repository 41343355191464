import ClipboardColumn from '../components/table/clipboard-column'
import CountColumn from '../components/table/count-column'
import dateFormat from '@/filters/CustomFilters'
import _ from 'lodash'
import moment from 'moment'

export default {
  activeTemplate: function(columns) {
    const object = {}
    _.each(columns, column => {
      object[column] = (h, row) => {
        return row[column] ? (
          <span class="badge badge-success">
            <i class="uil uil-check"></i>
          </span>
        ) : (
          <span class="badge badge-light">
            <i class="uil uil-times"></i>
          </span>
        )
      }
    })
    return object
  },
  clipboardTemplate: function(columns) {
    const object = {}
    _.each(columns, column => {
      object[column] = (h, row) => {
        return <ClipboardColumn value={row[column]} />
      }
    })
    return object
  },
  countTemplate: function(columns, action) {
    const object = {}
    _.each(columns, column => {
      object[column] = (h, row) => {
        return <CountColumn value={row[column]} id={row.id} action={action} />
      }
    })
    return object
  },
  relativeDateTemplate: function(columns, withoutSuffix = false) {
    const object = {}
    _.each(columns, column => {
      object[column] = (h, row) => {
        if (Date.parse(row[column])) {
          return moment(row[column]).fromNow(withoutSuffix)
        } else {
          return row[column]
        }
      }
    })
    return object
  },
  dateTemplate: function(columns) {
    const object = {}
    _.each(columns, column => {
      object[column] = (h, row) => {
        return dateFormat.date(row[column])
      }
    })
    return object
  },
  maxLengthTemplate: function(columns, length, tooltip = false) {
    const object = {}
    _.each(columns, column => {
      object[column] = row => {
        if (row[column].length > length) {
          const shortened = row[column].substring(0, length) + '...'
          if (tooltip) {
            return <span data-tooltip={row[column]}>{shortened}</span>
          }
          return shortened
        }
        return row[column]
      }
    })
    return object
  },
  strippedHtmlTemplate: function(columns) {
    const object = {}
    _.each(columns, column => {
      object[column] = (h, row) => {
        return row[column].replace(/<[^>]+>/g, '')
      }
    })
    return object
  },
  booleanColumn: function(fieldName) {
    return {
      field: fieldName,
      type: 'check',
      advancedFilter: true,
      showModalAdvanced: false,
      filterable: true,
      customOptions: [
        { label: 'All', id: null },
        { label: 'Yes', id: 1 },
        { label: 'No', id: 0 }
      ],
      basicFilterType: 'select',
      optionLabelKey: 'label'
    }
  },
  // To be removed once we convert all base-list components to the new data table
  activeColumn: function() {
    return {
      field: 'active',
      type: 'check',
      advancedFilter: true,
      showModalAdvanced: false,
      filterable: false,
      customOptions: [
        { label: 'All', id: null },
        { label: 'Active', id: 1 },
        { label: 'Inactive', id: 0 }
      ],
      basicFilterType: 'select',
      optionLabelKey: 'label'
    }
  },
  newActiveColumn: function(name = 'active') {
    return {
      field: name,
      type: 'check',
      advancedFilter: true,
      showModalAdvanced: false,
      filterable: true,
      customOptions: [
        { label: 'All', id: null },
        { label: 'Active', id: 1 },
        { label: 'Inactive', id: 0 }
      ],
      basicFilterType: 'select',
      optionLabelKey: 'label'
    }
  }
}
