<template>
  <div :class="wrapperClass" :hidden="type === 'hidden'">
    <label v-if="label" class>{{ $prettyLabels(label) }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <template v-if="notifiedByField && !$parent.object[notifiedByField.field]">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </template>
    <div class="dropdown" v-if="options.length > 0 || !loadOnMount">
      <input
        type="text"
        :disabled="disabled || (notifiedByField && !$parent.object[notifiedByField.field])"
        @click="dropdownClicked"
        class="form-control"
        v-debounce:200ms="valueChanged"
        v-model="activeOptionLabel"
        :placeholder="placeholderLabel ? placeholderLabel : placeholder"
        ref="dropdownInput"
        @blur="hideDropdown()"
        @keydown.up.exact.prevent="focusPrevious(true)"
        @keydown.down.exact.prevent="focusNext(true)"
        @keypress.enter.exact.prevent="selectItem()"
        @keyup.esc.exact.prevent="$parent.formType === 'edit' && hideDropdown()"
      />
      <span @click="clearInput" v-if="canBeCleared">
        <i class="uil uil-multiply position-absolute p-2 mb-1 clickable right-0 bottom-0" style="top: \5px" />
      </span>
      <div class="dropdown-menu list-container" ref="dropdown-list">
        <div @click="$emit('input', null)" v-if="showAllOption">All</div>
        <virtual-list
          class="dropdown-item"
          :style="[
            staticStyles,
            dropdownHeight !== null ? { 'max-height': dropdownHeight + 'px', 'overflow-y': 'auto' } : {}
          ]"
          :data-key="'id'"
          :data-sources="optionItems"
          :data-component="activeOption"
          :extra-props="{
            dataFromParent: data,
            trackBy,
            pointer,
            optionLabelKey,
            allowNullOption,
            removeActiveText,
            extraOptionLabelKey
          }"
          :wrap-class="'wrapper-items'"
          :footer-class="'footer-items'"
          :lengthOfOptions="lengthOfOptions"
          :lengthOfItems="optionItems.length"
        />
        <div v-if="!customOptions && optionItems.length === 0" class="virtual-list-footer">
          <span v-if="optionItems.length === 0 && !loading">No matching records</span>
          <span v-else-if="loading">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else class="form-control text-muted">
      <template v-if="loading">
        <span>Loading ...</span>
      </template>
      <template v-else>
        <span>No {{ noOptionsPlaceholder }} available</span>
        <span v-if="noOptionsLink"
          >, click
          <a v-if="noOptionsLink" :href="noOptionsLink" target="blank"> here </a>
          to add one
        </span>
      </template>
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import GeneralService from '../../services/GeneralService'
import activeOption from './vue-scroll-component/scroll-item.vue'

export default {
  name: 'base-select',
  props: {
    removeActiveText: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      default: () => 'form-group position-relative'
    },
    capitalizeLabel: {
      default: true,
      type: Boolean
    },
    clearable: {
      default: () => false
    },
    clearableIfOneOption: {
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    parameters: {
      type: Object,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select an option'
    },
    label: {
      type: String
    },
    helpText: {
      type: String
    },
    optionLabelKey: {
      type: String,
      default: 'label'
    },
    modelValue: {
      default: () => null
    },
    customOptions: {
      default: () => null
    },
    readOnly: {
      default: () => false
    },
    required: {
      default: () => false,
      type: Boolean
    },
    selectFirstAfterFetch: {
      default: () => false,
      type: Boolean
    },
    selectFirstIfOneResult: {
      // select first item if there's only 1 option
      default: () => false,
      type: Boolean
    },
    loadOnMount: {
      // load options on mount
      default: () => true,
      type: Boolean
    },
    path: {
      default: () => null,
      type: String
    },
    emitOption: {
      type: String,
      default: () => null
    },
    noOptionsPlaceholder: {
      type: String,
      default: () => 'options'
    },
    noOptionsLink: {
      type: String
    },
    trackBy: {
      type: String,
      default: () => 'id'
    },
    showAllOption: {
      type: Boolean,
      default: () => false
    },
    notifiedByField: {
      type: Object,
      default: () => null
    },
    allowNullOption: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      default: 'list-one'
    },
    maxOptions: {
      type: Boolean,
      default: false
    },
    searchBy: {
      type: String,
      default: () => null
    },
    extraOptionLabelKey: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      data: this.modelValue instanceof Object ? this.modelValue[this.trackBy] : this.modelValue,
      options: [],
      loading: true,
      optionItems: [],
      activeOptionLabel: '',
      placeholderLabel: '',
      pointer: 0,
      focusedIndex: 0,
      activeOption,
      search: '',
      dynamicHeight: null,
      staticStyles: {
        maxHeight: '300px',
        overflowY: 'auto',
        zIndex: 9999
      }
    }
  },
  provide() {
    return {
      onChangeItem: this.onChange
    }
  },
  updated() {
   if (this.pointer === '') {
     this.placeholderLabel = '';
     this.activeOptionLabel = '';
   }
 },
  computed: {
    dropdownHeight() {
      // 52 is the height of the all option
      return this.dynamicHeight > 0 ? this.dynamicHeight - 52 : null
    },
    canBeCleared() {
      if (this.clearable && this.data) {
        if (this.options.length === 1) {
          return this.clearableIfOneOption
        } else {
          return true
        }
      }
      return false
    },
    lengthOfOptions() {
      return this.optionItems.length <= 7 ? this.optionItems.length : 7
    }
  },
  async created() {
    if (this.customOptions) {
      this.options = this.customOptions
      this.updateActiveOptionLabel()
    } else if (
      (this.path && this.loadOnMount) ||
      this.data ||
      (this.notifiedByField &&
        this.$parent.$parent.object &&
        this.$parent.$parent.object[this.notifiedByField.field])
    ) {
      this.getOptions()
    }

    if (this.modelValue instanceof Object) {
      this.$emit(
        'input',
        this.data,
        this.emitOption
          ? this.options.filter(item => {
              if (item[this.trackBy] === this.modelValue[this.trackBy]) {
                return item
              }
            })[0][this.emitOption]
          : ''
      )
    }
  },
  watch: {
    path(newValue, oldValue) {
      if (this.path.startsWith('koala/v1') && newValue !== oldValue) {
        this.getOptions()
      }
    },
    value() {
      this.data = this.value instanceof Object ? this.value[this.trackBy] : this.value
      this.updateActiveOptionLabel()
      if (this.$refs.validator) {
        this.$refs.validator.validate()
      }
    },
    customOptions() {
      this.options = this.customOptions
    },
    options() {
      this.optionItems = this.options

      if (this.optionItems.length > 0) {
        if (this.pointer !== this.optionItems[0][this.trackBy]) {
          this.focusItem()
        }
      }
    },
    activeOptionLabel() {
      if (this.searchBy) {
        return;
      }
      this.activeOptionLabel = this.prettifyLabels(this.activeOptionLabel);
      if (this.activeOptionLabel) {
        const searchLabel = this.activeOptionLabel.toLowerCase().replace(/:/g, '');

        this.optionItems = this.options
          .filter(item => {
            if (typeof item === 'object' && item[this.optionLabelKey]) {
              const itemLabel = item[this.optionLabelKey].toLowerCase().replace(/:/g, '');
              const extraLabel = this.extraOptionLabelKey && item[this.extraOptionLabelKey]
                ? item[this.extraOptionLabelKey].toLowerCase() + ' > '
                : '';
              
              return (extraLabel + itemLabel).includes(searchLabel);
            }
            return typeof item === 'string' && item.toLowerCase().replace(/:/g, '').includes(searchLabel);
          })
          .sort((a, b) => {
            const getLabel = (item) => {
              if (typeof item === 'object' && item[this.optionLabelKey]) {
                const itemLabel = item[this.optionLabelKey].toLowerCase();
                const extraLabel = this.extraOptionLabelKey && item[this.extraOptionLabelKey]
                  ? item[this.extraOptionLabelKey].toLowerCase() + ' > '
                  : '';
                return extraLabel + itemLabel;
              }
              return typeof item === 'string' ? item.toLowerCase() : '';
            };

            const labelA = getLabel(a);
            const labelB = getLabel(b);

            const startsWithA = labelA.startsWith(searchLabel);
            const startsWithB = labelB.startsWith(searchLabel);

            if (startsWithA && !startsWithB) {
              return -1;
            } else if (!startsWithA && startsWithB) {
              return 1;
            } else {
              return labelA.localeCompare(labelB);
            }
          });
      } else {
        this.optionItems = this.options;
      }
    }
  },
  methods: {
    valueChanged(value) {
      if (this.searchBy) {
        this.search = value
        this.getOptions()
      }
    },
    clearInput() {
      this.placeholderLabel = ''
      this.$emit('update:modelValue', null)
    },
    updateActiveOptionLabel() {
      if (this.data !== null && this.data !== undefined && this.options.length > 0) {
        let option = this.options.filter(item =>
          item instanceof Object
            ? item[this.trackBy] === (!isNaN(this.data) ? parseInt(this.data) : this.data)
            : item === this.data
        )[0]

        let optionLabel =
          option instanceof Object
            ? this.extraOptionLabelKey
              ? option[this.extraOptionLabelKey] + ' > ' + option[this.optionLabelKey]
              : option[this.optionLabelKey]
            : option

        this.activeOptionLabel = this.capitalizeLabel ? this.capitalize(optionLabel) : optionLabel
      } else {
        this.activeOptionLabel = ''
        this.placeholderLabel = ''
      }

      this.pointer = this.data
      this.focusedIndex = this.optionItems.findIndex(item => item.id === this.data)
    },
    dropdownClicked() {
      this.path && !this.loadOnMount && this.options.length === 0 && this.getOptions()

      if (this.activeOptionLabel !== '') {
        this.placeholderLabel = this.activeOptionLabel
      }
      this.activeOptionLabel = ''

      this.$refs.dropdownInput.focus()
      this.getDropdownHeight()
    },
    getDropdownHeight() {
      const dropdownListRect = this.$refs['dropdown-list'].getBoundingClientRect()
      const tableRect = document.querySelector('.card-body').getBoundingClientRect()

      const spaceBelowViewport = window.innerHeight - dropdownListRect.top

      const spaceBelowTable = tableRect.bottom - dropdownListRect.top

      const maxDropdownHeight = Math.min(spaceBelowViewport, spaceBelowTable)

      this.dynamicHeight = maxDropdownHeight > 0 ? maxDropdownHeight : 0
    },
    onChange(option) {
      this.$refs.dropdownInput.blur()

      if ((option instanceof Object ? option[this.trackBy] : option) === this.data && this.allowNullOption) {
        this.data = null
        this.activeOptionLabel = ''
      } else {
        this.data = option instanceof Object ? option[this.trackBy] : option
      }

      if (this.searchBy) {
        this.search = ''
        this.getOptions()
      }

      this.$emit(
        'input',
        this.data,
        this.emitOption
          ? this.options.filter(item => {
              if (item[this.trackBy] === option[this.trackBy]) {
                return item
              }
            })[0][this.emitOption]
          : ''
      )
    },
    getOptions(selectFirst = false) {
      let data = {}
      if (this.notifiedByField && this.$parent.$parent.object) {
        data[this.notifiedByField.field] = this.$parent.$parent.object[this.notifiedByField.field]
      }

      data = {
        ...data,
        ...(this.parameters ? this.parameters : {}),
        ...(this.searchBy && this.search.length ? { [this.searchBy]: this.search } : {})
      }

      return new Promise(resolve => {
        GeneralService.fetchItems(this.path, data).then(response => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            if (!this.emitOption) {
              this.options = response.data.result
                .filter(item => {
                  return !!this.resolve(this.optionLabelKey, item)
                })
                .map(item => ({
                  ['id']: item[this.trackBy],
                  [this.trackBy]: item[this.trackBy],
                  [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
                  ...(this.extraOptionLabelKey
                    ? { [this.extraOptionLabelKey]: this.resolve(this.extraOptionLabelKey, item) }
                    : {})
                }))
            } else {
              this.options = response.data.result.map(item => ({
                ['id']: item[this.trackBy],
                [this.trackBy]: item[this.trackBy],
                [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
                [this.emitOption]: item[this.emitOption],
                ...(this.extraOptionLabelKey
                  ? { [this.extraOptionLabelKey]: this.resolve(this.extraOptionLabelKey, item) }
                  : {})
              }))
            }

            this.$nextTick(() => {
              if (selectFirst) {
                this.selectFirstOption()
              } else if (
                this.selectFirstAfterFetch ||
                (this.selectFirstIfOneResult && this.options.length === 1)
              ) {
                this.selectFirstOption()
              }
            })

            if (this.loading) {
              this.updateActiveOptionLabel()
            }

            this.loading = false
            resolve(this.options)
          }
        })
      })
    },
    resolve(path, obj) {
      return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    selectFirstOption() {
      if (this.options && this.options.length) {
        this.$emit(
          'input',
          this.options[0][this.trackBy],
          this.emitOption ? this.options[0][this.emitOption] : ''
        )
      } else {
        this.$emit('input', null)
      }
    },
    focusNext(isArrowKey) {
      if (this.focusedIndex < this.optionItems.length - 1) {
        this.focusedIndex = this.focusedIndex + 1
        if (isArrowKey) {
          this.focusItem()
        }
      }
    },
    focusPrevious(isArrowKey) {
      if (this.focusedIndex > 0) {
        this.focusedIndex = this.focusedIndex - 1
        if (isArrowKey) {
          this.focusItem()
        }
      }
    },
    focusItem() {
      if (this.optionItems.length != 0) {
        this.pointer =
          this.optionItems[this.focusedIndex] instanceof Object
            ? this.optionItems[this.focusedIndex][this.trackBy]
            : this.optionItems[this.focusedIndex]
      }
    },
    selectItem() {
      this.onChange(this.optionItems[this.focusedIndex])
    },
    hideDropdown() {
      this.$refs.dropdownInput.blur()
      if (this.data === null) {
        this.focusedIndex = 0
        this.focusItem()
      }
      this.valueChanged('')
      setTimeout(() => {
        this.updateActiveOptionLabel()
      }, 300)
    },
    prettifyLabels(label) {
      return this.capitalizeLabel ? this.$prettyLabels(label) : label
    }
  }
}
</script>
<style lang="scss">
.wrapper-items {
  background: white !important;
  color: #6e6e84 !important;
}

.list-container {
  max-height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0 !important;
  margin: 5rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  .dropdown-item {
    padding: 0 !important;
    bottom: 0;
  }
}
</style>
<style scoped>
.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.list-container::-webkit-scrollbar {
  width: 0;
}

.dropdown input {
  padding: 0 14px;
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.dropdown > input.form-control {
  background-image: unset !important;
}

.dropdown > input.form-control::placeholder,
.dropdown > input.form-control:focus {
  color: #999 !important;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.absolute-clear-button {
  position: absolute;
  top: -25px;
  right: 4px;
}

.no-hover {
  color: #17182f !important;
  font-weight: 500 !important;
  /* font-size: 12px !important; */
  background: white;
  text-align: center;
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
