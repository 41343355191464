import Api from '@services/Api'
import AccountsService from '@services/koala/AccountsService'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'

import App from '@/main.js'

const confirmRunAccount = async (
  title,
  content,
  actionButtonTitle,
  actionButtonCancelTitle,
  actionButtonIconClass,
  actionButtonClass
) => {
  return await openDialog(ConfirmDialog, {
    title,
    content,
    actionButtonTitle,
    actionButtonCancelTitle,
    actionButtonIconClass,
    actionButtonClass,
  })
}

export default {
  methods: {
    async runAccount(row) {
      try {
        const response = await AccountsService.getOne(row)
        const { username, id, operator, platform_name, business_unit } = response.data.result[0]

        const user = App.config.globalProperties.$auth.user()
        let userObject = {
          hercules_user_id: user.id,
          hercules_user_name: user.name,
          account_ids: [id],
          dry_run: true,
        }

        let runAccountPostResponse = await Api().post('koala/v1/account-jobs', userObject)

        if (runAccountPostResponse.data.result.accounts_queued === 1) {
          const confirmMessage = `Are you sure you want to run the account ${username} for operator ${operator.name} on platform ${platform_name} with business unit ${business_unit.name} ?`
          const userConfirmed = await confirmRunAccount(
            'Confirm',
            confirmMessage,
            'Yes',
            'No',
            'uil uil-play',
            'btn btn-success'
          )
          if (userConfirmed) {
            userObject.dry_run = false
            runAccountPostResponse = await Api().post('koala/v1/account-jobs', userObject)
            if (runAccountPostResponse.data.success) {
              this.showSuccessMessage('Account run successfully')
            }
          }
        } else {
          throw new Error(
            'Failed to run account with status code: ' + runAccountPostResponse.data.messages[0]
          )
        }
      } catch (error) {
        this.showErrorMessage(error.message, 'Failed to run account')
      }
    },
  },
}
