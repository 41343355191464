<template>
  <div class="col mx-auto py-5">
    <div class="text-center mb-4">
      <svg
        id="login-logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 117.96 117.84"
        height="120px"
        fill="#fff"
      >
        <path
          d="M117.96 29.34v59.39c0 8.31-2.42 15.35-7.03 20.43l-.33.36c-.13.14-.26.28-.4.41-.05.06-.11.11-.17.17l-.07.07c-.05.05-.11.11-.17.16-.07.07-.15.14-.22.21l-.36.33c-4.93 4.44-11.88 6.97-20.62 6.97H29.36c-8.74 0-15.69-2.53-20.62-6.97l-.36-.33c-.13-.13-.27-.26-.4-.38-.08-.08-.16-.16-.23-.24-.13-.13-.26-.26-.39-.4l-.33-.36C2.42 104.09 0 97.04 0 88.73V29.34c0-9 2.54-15.97 7.03-20.86.11-.12.22-.24.33-.35l.12-.12c.05-.05.11-.11.16-.17l.07-.07c.06-.06.11-.11.17-.16.1-.1.21-.2.32-.31l.36-.33C13.67 2.35 20.83 0 29.36 0H88.6c8.54 0 15.7 2.35 20.81 6.97l.36.33c.1.1.21.2.31.29.08.08.16.15.23.23.1.1.2.2.29.3.11.12.22.23.33.35 4.49 4.9 7.03 11.88 7.03 20.87"
          :fill="$store.state.app.environmentColor"
        />
        <path
          class="st1"
          d="M68.16 16.66l-.93 25.83 9.71 6.96v33.64l-10.89-7.8-.89 24.54 13.25-8.56 14.64-38.22 8.63.37zM41.02 83.09V49.45l9.83-7.05-.92-25.74-33.52 36.76 8.63-.37 14.64 38.22 13.25 8.56-.89-24.64z"
        />
        <path fill="#ffd100" d="M72.94 51.51L58.98 41.5 45.02 51.51V75.3l13.96-10 13.96 10z" />
      </svg>
      <h1 class="mt-5">
        Welcome to
        <strong>Hercules</strong> 👋
      </h1>
      <div class="text-uppercase my-3">
        <h3 class="font-weight-bold" :style="{ color: $store.state.app.environmentColor }">
          {{ $store.state.app.environmentName }}
        </h3>
      </div>
    </div>
    <div class="card mx-auto" v-if="signInWithGoogle" style="max-width: 450px">
      <div class="card-header">
        <h2 class="card-title">Sign in</h2>
      </div>
      <div class="card-body">
        <router-link :to="{ path: '/azure-login' }" class="btn btn-primary btn-block">
          <i
              class="icon d-inline-block"
              style="width: 12px; height: 12px; background: url('/microsoft.svg') no-repeat center / contain;"
          ></i>
          Using your <strong>G2M</strong> account
        </router-link>
        <hr />
        <div class="text-center">
          <div class="d-flex align-items-center text-muted  font-size-sm my-2">
            If you do NOT have a G2M email <i class="uil uil-corner-right-down mt-3 ml-2"></i>
          </div>
          <a
            class="btn btn-secondary btn-block text-muted"
            href="#"
            @click.prevent="signInWithGoogle = !signInWithGoogle"
          >
            <i class="uil uil-envelope"></i> Sign in using <strong>email</strong>
          </a>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card mx-auto" style="max-width: 450px">
        <div class="card-header">
          <h2 class="card-title">Sign in</h2>
        </div>
        <div class="card-body">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <label class="text-muted">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                class="form-control"
                placeholder="user@example.com"
                v-model="email"
                autocomplete="email"
                required
              />
            </div>
            <div class="form-group">
              <label class="text-muted">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                class="form-control"
                v-model="password"
                required
              />
            </div>
            <button type="submit" class="btn btn-block btn-primary" v-bind:class="{ loading: isLoading }">
              Sign in
            </button>
          </form>
        </div>
        <p class="text-center">
          <router-link :to="{ path: '/forgot-password' }" class="text-bluegrey-3"
            >Forgot your password?</router-link
          >
        </p>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mb-3">
        <h6 class="font-weight-bold" :style="{ color: $store.state.app.environmentColor }">or</h6>
        <div class="col-3 mt-2">
          <router-link :to="{ path: '/azure-login' }" class="btn btn-secondary btn-block">
            <i
                class="icon d-inline-block"
                style="width: 12px; height: 12px; background: url('/microsoft.svg') no-repeat center / contain;"
            ></i>
            Sign in with Microsoft Azure
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      password: null,
      error: false,
      isLoading: false,
      signInWithGoogle: true,
    }
  },
  created() {
    this.$parent.$parent.showSidebar = false
    this.$parent.$parent.showNavbar = false
    this.setTitle()
  },

  mounted() {
    console.log('process.env', process.env)
  },
  methods: {
    onSubmit() {
      this.isLoading = true
      this.$auth
        .login({
          url: 'backoffice/auth/login',
          data: { email: this.email, password: this.password },
          rememberMe: true,
          staySignedIn: true,
        })
        .then(() => {
          this.$store.dispatch('user/setUser', this.$auth.user())
        })
        .catch((error) => {
          if (error.response && error.response.data.msg) {
            this.showErrorMessage(error.response.data.msg)
          } else {
            this.showErrorMessage('Failed to login')
          }
          this.isLoading = false
        })
    },
  },
}
</script>
