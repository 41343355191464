<template>
  <div>
    <data-table
      model="Software"
      service-file="SoftwareService"
      path="hercules/globals/software"
      :columns="columns()"
      :rowActionButtons="rowActions()"
    />
    <operator-list-modal ref="modal" model="model" />
  </div>
</template>

<script>
import OperatorsService from '@services/OperatorsService'
import OperatorListModal from '@atoms/general/operator-list-modal.vue'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  components: { OperatorListModal },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    rowActions() {
      return [
        {
          action: this.showOperatorList,
          title: 'Operators',
          icon: 'uil uil-align-justify',
          class: 'btn-info'
        }
      ]
    },
    showOperatorList(row) {
      OperatorsService.getOperatorsByProvider(row.id)
        .then(response => {
          if (response.data.success) {
            this.$refs.modal.operators = response.data.result
            this.$refs.modal.show()
          }
        })
        .catch(this.showUnknownErrorMessage)
    }
  }
}
</script>
