<template>
  <data-table
    service-file="AffiliateMarketDataService"
    model="AffiliateMarketData"
    :path="path()"
    ref="list"
    :columns="columns()"
    :create-defaults="createDefaults()"
    notifyExtraFieldIdCreate="affiliate_id"
    notifyExtraFieldIdEdit="id"
    :serviceParams="[affiliate_id]"
    :extraDeleteInfo="{
      title: 'Warning',
      message: '',
      extraMessage: 'It will be deleted from CMS Markets as well.',
      boldText: 'font-weight-bold'
    }"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Markets Data`
    )
  },
  data() {
    return {
      affiliate_id: this.$route.params['id'],
    }
  },
  methods: {
    columns() {
      return [
        { field: 'market', type: 'text', filterable: true, template: TableColumnTemplate.MARKET_LABEL },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/operators/affiliate-markets-data?affiliate_id=${this.$route.params['id']}&with=market,linked_group`
    },
    createDefaults() {
      return {
        affiliate_id: this.$route.params['id'],
      }
    },
  },
}
</script>
