export default {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  getters: {
    getItems: (state) => {
      return state.items
    },
  },
  actions: {
    setItems({ commit }, value) {
      commit('setItems', value)
    },
    addItems({ commit }, items) {
      commit('addItems', items)
    },
    updateItem({ commit }, value) {
      commit('updateItem', value)
    },
  },
  mutations: {
    setItems(state, value) {
      state.items = value
    },
    addItems(state, newItems) {
      newItems.forEach((item) => {
        state.items.splice(item.sort_nr - 1, 0, item)
      })
    },
    updateItem(state, value) {
      let clone = [...state.items]
      clone[value.index] = value.item
      state.items = clone
    },
    createSitesData(state, value) {
      const { result, item } = value
      let index = state.items.findIndex((x) => x.id === item.id)
      state.items[index].operator.affiliate_operators = result
    },
  },
}
