import Markets from '@pages/general/markets.vue'
import Languages from '@pages/general/languages.vue'
import Currencies from '@pages/general/currencies.vue'
import Providers from '@pages/general/providers.vue'
import Countries from '@pages/general/countries.vue'
import SellingPoints from '@pages/general/selling-points.vue'
import Depositmethods from '@pages/general/deposit-methods/deposit-methods.vue'
import DepositMethodsGeneral from '@pages/general/deposit-methods/general.vue'
import DepositMethodsEdit from '@pages/general/deposit-methods/edit.vue'
import DepositMethodsMarketsData from '@pages/general/deposit-methods/markets-data.vue'
import GameCategories from '@pages/general/game-categories.vue'
import SupportTypes from '@pages/general/support-types.vue'
import Units from '@pages/general/units.vue'
import Licences from '@pages/general/licences.vue'
import ResponsibleGaming from '@pages/general/responsible-gamings.vue'

import CryptoLicencesList from '@pages/general/crypto-licences.vue'
import CryptoServicesList from '@pages/general/crypto-services.vue'
import GamblingCompanies from '@pages/general/gambling-companies.vue'
import AffiliatePrograms from '@pages/general/affiliate-programs.vue'
import SportCategories from '@pages/general/sport-categories.vue'
import BettingTypes from '@pages/general/betting-types.vue'

import AffiliatePlatforms from '@pages/dms/affiliate-platforms/affiliate-platforms.vue'
import AffiliatePlatformEdit from '@pages/dms/affiliate-platforms/edit/edit.vue'
import AffiliatePlatformGeneral from '@pages/dms/affiliate-platforms/edit/general.vue'
import AffiliatePlatformDomains from '@pages/dms/affiliate-platforms/edit/domains.vue'

export default [
  {
    path: '/countries',
    component: Countries,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Countries'
    }
  },
  {
    path: '/markets',
    component: Markets,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Markets'
    }
  },
  {
    path: '/languages',
    component: Languages,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Languages'
    }
  },
  {
    path: '/currencies',
    component: Currencies,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Currencies'
    }
  },
  {
    path: '/providers',
    component: Providers,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Game Providers',
      permission: 'view.globals/software'
    }
  },
  {
    path: '/licences',
    component: Licences,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Licences',
      permission: 'view.operators/licenses'
    }
  },
  {
    path: '/selling-points',
    component: SellingPoints,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Selling Points',
      permission: 'view.globals/selling-points'
    }
  },
  {
    path: '/deposit-methods',
    name: 'Payment Methods',
    component: Depositmethods,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Payment Methods',
      permission: 'view.globals/deposit-methods'
    }
  },
  {
    path: '/deposit-methods/:id',
    component: DepositMethodsGeneral,
    meta: {
      parent: 'General Data',
      name: 'Payment Methods'
    },
    children: [
      {
        path: '',
        component: DepositMethodsEdit,
        meta: {
          parent: 'General Data',
          name: 'General'
        }
      },
      {
        path: '/deposit-methods/:id/markets-data',
        component: DepositMethodsMarketsData,
        meta: {
          parent: 'General Data',
          name: 'Markets Data',
          permission: 'view.globals/deposit-methods-markets-data'
        }
      }
    ]
  },
  {
    path: '/game-categories',
    component: GameCategories,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Game Categories',
      permission: 'view.globals/game-categories'
    }
  },
  {
    path: '/support-types',
    component: SupportTypes,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Support Types',
      permission: 'view.globals/support-types'
    }
  },
  {
    path: '/units',
    component: Units,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Units'
    }
  },
  {
    path: '/responsible-gamings',
    component: ResponsibleGaming,
    meta: {
      parent: 'General Data',
      auth: true,
      name: 'Responsible Gamings'
    }
  },
  {
    path: '/crypto-licences',
    component: CryptoLicencesList,
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Crypto Licences',
      permission: 'view.cryptos/licences'
    }
  },
  {
    path: '/crypto-services',
    component: CryptoServicesList,
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Crypto Services',
      permission: 'view.cryptos/services'
    }
  },
  {
    path: '/gambling-companies',
    component: GamblingCompanies,
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Gambling Companies',
      permission: 'view.operators/gambling-companies'
    }
  },
  {
    path: '/affiliate-programs',
    component: AffiliatePrograms,
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Affiliate Programs',
      permission: 'view.operators/affiliate-programs'
    }
  },
  {
    path: '/affiliate-platforms',
    component: AffiliatePlatforms,
    name: 'Affiliate Platforms',
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Affiliate Platforms'
    }
  },
  {
    path: '/sport-categories',
    component: SportCategories,
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Sport Categories',
      permission: 'view.operators/sport-categories'
    }
  },
  {
    path: '/betting-types',
    component: BettingTypes,
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Betting Types',
      permission: 'view.operators/betting-types'
    }
  },
  {
    path: '/affiliate-platforms/:id',
    component: AffiliatePlatformGeneral,
    children: [
      {
        path: '',
        component: AffiliatePlatformEdit,
        meta: {
          parent: 'General Data',
          auth: true,
          name: 'AffiliatePlatformGeneral'
        }
      },
      {
        path: 'domains',
        component: AffiliatePlatformDomains,
        meta: { parent: 'General Data', name: 'AffiliatePlatformDomains' }
      }
    ],
    meta: {
      auth: true,
      parent: 'General Data',
      name: 'Platforms'
    }
  }
]
