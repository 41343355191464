<template>
  <div v-if="showTable">
    <data-table
      ref="list"
      model="SiteConfig"
      service-file="SiteConfigsService"
      :path="getPath()"
      :columns="columns()"
      :column-templates="columnTemplates()"
      :rowActionButtons="[
        {
          action: reloadSite,
          title: 'Reload',
          icon: 'uil uil-adjust-circle',
          class: 'btn-secondary',
          permission: 'misc.sites/reload-site-configurations',
        },
      ]"
    />
  </div>

  <div v-else class="row d-flex justify-content-center container-fluid">
    <div class="card col-12 col-xl-6">
      <div class="card-body">
        <div class="text-center">
          <i class="uil uil-sad hero-icon"></i>

          <h5>
            Sorry, JSON Tokens
            <strong>cannot</strong>
            be managed on this environment
          </h5>

          <p>The JsonUpdater always connects to Hercules live to request settings.</p>

          <a class="btn btn-primary" href="https://hercules.gigmedia.com/site-configurations" target="_blank">
            Go to Hercules live
            <i class="uil uil-external-link-alt ml-2"></i>
          </a>

          <div class="mt-2">
            <a class="text-muted font-size-sm" @click="() => (forceShowTable = true)">Or show anyways</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AffiliateSettingsService from '@services/AffiliateSettingsService'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      forceShowTable: false, // to show on local env
    }
  },
  computed: {
    showTable() {
      // eslint-disable-next-line no-undef
      return this.forceShowTable || process.env.VUE_APP_ENV === 'production'
    },
  },
  methods: {
    columns() {
      return [
        { field: 'site_name', type: 'text', label: 'Site', filterable: false },
        { field: 'url', type: 'text', label: 'URL', filterable: true },
        { field: 'site_environment', type: 'text', filterable: true },
        { field: 'api_environment', type: 'text', filterable: true, label: 'API environment' },
        {
          field: 'token',
          type: 'text',
          filterable: true,
          clipboard: true,
          clickable: false,
          template: TableColumnTemplate.CLIPBOARD,
        },
        {
          field: 'last_connected_at',
          type: 'text',
          filterable: false,
          label: 'Last active',
          template: TableColumnTemplate.RELATIVE_DATE,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {}
    },
    getPath() {
      let url = 'hercules/sites/configs?with=site'
      if (Object.hasOwn(this.$route.query, 'id')) {
        url += `&id=${this.$route.query.id}`
      }
      return url
    },
    reloadSite(row) {
      AffiliateSettingsService.reload(row.id)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Reloading data...', 'Reload call has been sent')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
