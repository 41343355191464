<template>
  <div>
    <data-table
      model="SellingPoint"
      service-file="SellingPointsService"
      path="hercules/globals/selling-points"
      :rowActionButtons="rowActions()"
      :columns="columns()"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
     
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    rowActions() {
      return [
        {
          action: this.translationAction,
          title: 'Translations',
          icon: 'uil uil-adjust',
          class: 'btn-info'
        }
      ]
    },
    translationAction(row) {
      let routeData = this.$router.resolve('translations?key=' + row.short_name)
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
