<template>
    <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Rating to {{ row.operator.name }}</h5>
            <button class="close" v-on:click="close"></button>
          </div>
          <div class="modal-body pb-2">
            <div class="gap-4 w-100">
              <base-input
                v-for="rating in inputRatings.ratings"
                :key="rating.key"
                :label="rating.label"
                v-model="rating.value"
                type="number"
                :min="0"
                :max="5"
                :step="0.1"
                @input="calculateAverage"
              />
            </div>
            <base-input
              label="Average Rating"
              :value="formatRating(inputRatings.average_rating)"
              v-model="inputRatings.average_rating"
              :min="0"
              :max="5"
              step="any"
              :readOnly="isAverageReadOnly"
            />
          </div>
          <div class="modal-footer pt-3">
              <div class="w-100 text-right">
                <button type="submit" v-if="row.operator.affiliate_operators[0] && row.operator.affiliate_operators[0] !== ''" class="btn btn-primary" :class="{ loading: loading }" @click="updateRatingsModal">
                  <i class="uil uil-check"></i>
                  Update
                </button>
                <button v-else type="submit" class="btn btn-primary" :class="{ loading: loading }" @click="submitRatingsModal">
                  <i class="uil uil-check"></i>
                  Submit
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { cloneDeep } from 'lodash'
  import BaseInput from '@atoms/fields/base-input.vue'
  import AffiliateOperatorsService from '@services/AffiliateOperatorsService'

  export default {
    components: {
      BaseInput
    },
    data: function () {
      return {
        loading: false,
        showModal: false,
        inputRatings: {
          ratings: [
            { key: 'rating_casino', value: null, label: 'Casino' },
            { key: 'rating_games', value: null, label: 'Games' },
            { key: 'rating_bonuses', value: null, label: 'Bonuses' },
            { key: 'rating_customer', value: null, label: 'Customer' },
            { key: 'rating_payout', value: null, label: 'Payout' }
          ],
          average_rating: null,
        },
        initialRatings: {},
      }
    },
    props: {
      index: Number,
      row: Object,
      list: Object,
      storeName: String,
    },
    mounted() {
      this.initializeRatings();
      document.getElementById('modal').appendChild(this.$el)
    },
    computed: {
        isAverageReadOnly() {
            const ratings = [
             this.inputRatings.rating_casino,
             this.inputRatings.rating_games,
             this.inputRatings.rating_bonuses,
             this.inputRatings.rating_customer,
             this.inputRatings.rating_payout
            ];
            return ratings.some(rating => rating !== null && rating !== '');
        }
    },
    methods: {
      buildUpdateObject() {
        let payload = { 
          id: this.row.operator.affiliate_operators[0].id,
          rating: this.inputRatings.average_rating
        };
        this.inputRatings.ratings.forEach(r => {
          if (r.value !== this.initialRatings[r.key]) {
            payload[r.key] = r.value;
          }
        });
        return payload;
      },
      initializeRatings() {
        if (this.row && this.row.operator.affiliate_operators[0]) {
          const operator = this.row.operator.affiliate_operators[0];
          this.inputRatings.ratings.forEach(r => {
            r.value = operator[r.key] || null;
          });
          this.inputRatings.average_rating = operator.rating || null;
          this.initialRatings = JSON.parse(JSON.stringify(this.inputRatings));
        }
      },
      formatRating(rating) {
        return Number(rating).toFixed(1);
      },
      calculateAverage() {
        const ratings = this.inputRatings.ratings
          .map(r => Number(r.value))
          .filter(value => !isNaN(value) && value > 0);

        if (ratings.length > 0) {
          const sum = ratings.reduce((acc, value) => acc + value, 0);
          const average = sum / ratings.length
          this.inputRatings.average_rating = this.formatRating(average);
        } else {
          this.inputRatings.average_rating = null;
        }
      },
      show() {
        this.initializeRatings();
        this.$refs.modal.classList.add('show');
      },
      close() {
        this.$refs.modal && this.$refs.modal.classList.remove('show');
      },
      //Update ratings
      async updateRatings() {
        this.loading = true;
        const updateData = this.buildUpdateObject();
        try {
            const response = await AffiliateOperatorsService.update(updateData);
            if (response.data.messages) {
                this.showErrorMessages(response.data.messages);
            } else {
                this.showSuccessMessage('Affiliate operator ratings updated');
            }
            } catch (error) {
                this.showUnknownErrorMessage(); 
            } finally {
            this.loading = false;
        }
      },
      //Submit ratings from modal
      async submitModal() {
        this.loading = true;
        let payload = {
            affiliate_id: this.list.affiliate_id,
            market_id: this.list.market_id,
            type: this.list.operator_type,
            operator_id: this.row.operator_id,
            rating: this.inputRatings.average_rating || null,
        }
        this.inputRatings.ratings.forEach(ratings => {
          payload[ratings.key] = ratings.value || null;
        });
        try {
            const response = await AffiliateOperatorsService.post(payload)
            if (response.data.messages) {
                this.showErrorMessages(response.data.messages);
            } else {
                response.data.result.types = []
                response.data.result.links = []
                this.$store.commit(`${this.storeName}/createSitesData`, {
                  result: [response.data.result],
                  item: this.row,
                })
                this.showSuccessMessage('Affiliate operator ratings added succesfully');
            }
            } catch (error) {
                this.showUnknownErrorMessage(); 
            } finally {
            this.loading = false;
        }
      },
      updateRatingsModal() {
        let clone = cloneDeep(this.row);
        Object.assign(clone.operator.affiliate_operators[0], this.inputRatings);
        this.$store.dispatch(`${this.storeName}/updateItem`, { index: this.index, item: clone });
        this.updateRatings();
        this.close();
      },
      submitRatingsModal() {
        this.submitModal();
        this.close();
      },
    },
  }
  </script>
  
<style scoped>
.modal-dialog{
    max-width: 960px!important
}
.gap-4{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
}
@media (max-width: 768px){
    .gap-4{
        grid-template-columns: 1fr;
        gap: 0;
    }
}
</style>