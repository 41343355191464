<template>
  <div>
    <data-table
      ref="list"
      model="TopList"
      service-file="TopListsService"
      path="hercules/toplists/lists?with=site,market,category,linked_group&with_count=items"
      :column-templates="columnTemplates()"
      :columns="columns()"
      :rowActionButtons="rowActions"
      :showRemove="false"
      :show-edit-modal="false"
      @bulkClickByIds="bulkClick"
      :enableBulkSelect="true"
      :customRowClick="customRowClick"
      :postCreateNavigationRoute="rowPath"
    />
    <clone-modal ref="cloneModal" @refresh="$refs.list && $refs.list.search()" />
    <list-modal
      ref="listModal"
      label="url"
      title="Pages"
      empty-message="No pages are related to this list"
      :item-is-link="true"
    />
    <toplist-edit-bulk
      v-if="showBulkEdit"
      ref="bulkEdit"
      @refresh="$refs.list.search()"
      @closed="() => (showBulkEdit = false)"
    />
  </div>
</template>

<script lang="jsx">
import TopListsService from '@/services/TopListsService'
import ListModal from '@atoms/dms/toplists/list-modal'
import CloneModal from '@molecules/dms/toplists/clone-modal'
import TableColumnTemplate from '@constants/table-column-template'
import ToplistEditBulk from '@molecules/dms/toplists/edit-bulk.vue'
import TopListService from '@services/TopListsService'
import { openDialog } from 'vue3-promise-dialog'
import ConfirmDialog from '@atoms/misc/confirm-dialog'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  data() {
    return {
      rowActions: [
        {
          action: this.cloneClick,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'btn-info',
        },
        {
          action: this.deleteClick,
          title: 'Delete',
          icon: 'uil uil-trash',
          class: 'btn-danger',
        },
      ],
      showBulkEdit: false,
      rowPath: 'toplists',
    }
  },
  components: { ListModal, CloneModal, ConfirmDialog, ToplistEditBulk },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'unit', type: 'text', filterable: true },
        { field: 'site_name', type: 'text', filterable: true, label: 'Site' },
        {
          field: 'market_name',
          type: 'text',
          filterable: true,
          label: 'Market',
          template: TableColumnTemplate.MARKET_LABEL,
        },
        { field: 'label', type: 'text', filterable: true },
        { field: 'short_code', type: 'text', filterable: true },
        { field: 'items_count', type: 'text', filterable: true },
        { field: 'list_type', type: 'text', filterable: true },
        { field: 'operator_type', type: 'text', filterable: true, sortable: true },
        {
          field: 'scheduled_at',
          type: 'text',
          label: 'Scheduled',
          filterable: true,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
        {
          field: 'updated_at',
          label: 'Updated',
          type: 'text',
          filterable: true,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    columnTemplates() {
      return Object.assign({
        category_name: function (row) {
          return row.category ? row.category.name : ''
        },
        label: (row) => {
          return (
            <span>
              {row.label}
              {row.protected == 1 ? (
                <span data-tooltip="Protected" class="ml-2 text-muted">
                  <i class="fas fa-lock" />
                </span>
              ) : (
                ''
              )}
            </span>
          )
        },
      })
    },
    pagesClick(id) {
      TopListsService.getAffiliatePageUrls(id).then((response) => {
        this.$refs.listModal.items = response.data.result
        this.$refs.listModal.show()
      })
    },
    multiEditClick(id) {
      this.$router.push(`/toplists/multi-edit?ids=${id}`)
    },
    cloneClick(row) {
      this.$nextTick(() => {
        this.$refs.cloneModal.show(row)
      })
    },
    bulkClick(ids) {
      if (ids.length > 0) {
        this.showBulkEdit = true
        this.$nextTick(() => {
          this.$refs.bulkEdit.show(ids)
        })
      }
    },
    customRowClick(click, row, ctrlClick) {
      const path = `toplists/${row.id}`
      ctrlClick ? window.open(path, '_blank') : this.$router.push(path)
    },
    async deleteClick(row) {
      if (await confirmDelete('Warning', 'Are you sure want to delete this item?')) {
        await TopListService.remove({ id: row.id })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${row.label} removed`)
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.$refs.list.search()
          })
      }
    },
  },
}
</script>
