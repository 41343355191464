<script>
import TableModalForm from '@organisms/table/table-modal-form.vue'

export default {
  components: { TableModalForm },
  extends: TableModalForm,
  data() {
    return {
      formType: 'create',
    }
  },
  props: {
    showRemove: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    show() {
      this.clear()
      this.object = Object.assign(this.object, this.createDefaults)
      // this.$store.commit('dynamicForm/setObject', this.object)
      if (this.notifyExtraFieldIdCreate) {
        this.notifyExtraFields({ param: this.notifyExtraFieldIdCreate })
      }
      this.showModal()
    },
    submit() {
      this.$refs.form.isLoading = true
      // this.$store.commit('dynamicForm/setObject', this.object)

      return this.serviceLoader()
        .then((service) => {
          return service.default.post(this.object)
        })
        .then((response) => {
          if (this.$refs.form) {
            this.$refs.form.isLoading = false
          }
          return this.handleCreateResponse(response)
        })
        .catch(() => this.showUnknownErrorMessage)
    },
    handleCreateResponse(response) {
      return new Promise((resolve) => {
        let id = null

        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
        } else {
          if (this.structure.config.msgProp) {
            const msg = this.structure.config.msg

            this.showSuccessMessage(`${response.data.result[this.structure.config.msgProp]} ${msg}`)
          } else {
            this.showSuccessMessage(`${this.structure.config.name} created`)
          }
          this.close()
          this.$nextTick(() => {
            if (this.postCreateNavigationRoute) {
              this.$router.push(`${this.postCreateNavigationRoute}/${response.data.result.id}`)
            } else {
              this.$emit('refresh')
            }
            if (response.data && response.data.result) {
              id = response.data.result.id
            }

            resolve(id)
          })
        }
      })
    },
  },
}
</script>
