<template>
  <data-table
    ref="list"
    model="Operator"
    service-file="OperatorsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    :column-templates="columnTemplates()"
    :show-edit-modal="false"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle('Operators')
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true, sortable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        {
          field: 'countries',
          type: 'text',
          class: 'col-countries',
          search_key: 'countries__id',
          advancedFilter: true,
          path: 'hercules/globals/countries',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: false,
        },
        { field: 'games_amount', type: 'text', filterable: true },
        { field: 'founded', type: 'text', filterable: true },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        enabled: true,
      }
    },
    columnTemplates() {
      return {
        countries: function (row) {
          if (!row.countries || !row.countries.length) {
            return <span>N/A</span>
          }
          
          let countries = row.countries.map((country) => country?.name)

          if (countries.length <= 6) {
            return <span>{countries.join(', ')}</span>
          }

          let displayedCountries = [...countries].splice(0, 6).join(', ') + ' ...'
          let tooltipCountries = countries.join(', ')
          return (
            <span
              data-tooltip={tooltipCountries}
              class={`tooltipCountries${row.countries.length > 200 ? '-200' : ''}`}
            >
              {displayedCountries}
            </span>
          )
        },
      }
    },
    path() {
      return `hercules/operators/operators?with=countries`
    },
  },
}
</script>
<style lang="scss">
.tooltipCountries-200 {
  &::after {
    width: 600px !important;
    white-space: normal;
    bottom: unset !important;
    top: 100%;
  }
  &::before {
    bottom: unset !important;
    top: 100% !important;
  }
}

.tooltipCountries {
  &::after {
    width: 500px !important;
    white-space: normal;
  }
}
</style>
