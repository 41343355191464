<template>
  <div>
    <data-table
      model="Log"
      path="hercules/logs/performance-logs/producer?with=consumerPerformanceLogs"
      :column-templates="columnTemplates()"
      :columns="columns()"
      :rowActionButtons="rowActions()"
      :customRowClick="customRowClick"
      :showCreate="false"
      :showRemove="false"
      :showEditModal="false"
      ref="table"
    />
    <performance-logs-modal ref="modal" model="model" />
  </div>
</template>

<script lang="jsx">
import PerformanceLogsModal from '@atoms/logs/listener-performance-logs-modal.vue'
import ActivityLogsActionColumn from '@atoms/logs/activity-logs-action-column.vue'
import TableColumnTemplate from '@constants/table-column-template'
import byteSize from 'byte-size'
import { min, max, mean } from 'lodash'
import moment from 'moment'
export default {
  components: { PerformanceLogsModal },
  data() {
    return {
      refreshCooldown: false,
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'environment', type: 'text', filterable: true },
        { field: 'channel', type: 'text', filterable: true },
        { field: 'ip_address', type: 'text', filterable: true },
        { field: 'from', type: 'text', filterable: true },
        { field: 'message_size', type: 'text', filterable: true },
        { field: 'total_lowest', type: 'text', filterable: true, sortable: false },
        { field: 'total_highest', type: 'text', filterable: true, sortable: false },
        { field: 'total_average', type: 'text', filterable: true, sortable: false },
        { field: 'consumers', type: 'text', filterable: true, sortable: false },
        {
          field: 'sent_from_hercules',
          type: 'text',
          label: 'Published',
          template: TableColumnTemplate.RELATIVE_DATE,
          filterable: true,
        },
      ]
    },
    rowActions() {
      return [
        {
          action: this.showDiff,
          title: 'Details',
          icon: 'uil uil-eye',
          class: 'btn-info',
        },
      ]
    },
    columnTemplates() {
      return {
        consumers: function (row) {
          return row.consumer_performance_logs.length
        },
        total_lowest: function (row) {
          if (row.consumer_performance_logs.length === 0) {
            return ''
          }
          return (
            min(
              row.consumer_performance_logs.map((log) => {
                return moment(log.json_updater_message_processed).diff(
                  moment(row.sent_from_hercules),
                  'seconds',
                  true
                )
              })
            ).toFixed(2) + 's'
          )
        },
        total_highest: function (row) {
          if (row.consumer_performance_logs.length === 0) {
            return ''
          }
          return (
            max(
              row.consumer_performance_logs.map((log) => {
                return moment(log.json_updater_message_processed).diff(
                  moment(row.sent_from_hercules),
                  'seconds',
                  true
                )
              })
            ).toFixed(2) + 's'
          )
        },
        total_average: function (row) {
          if (row.consumer_performance_logs.length === 0) {
            return ''
          }
          return (
            mean(
              row.consumer_performance_logs.map((log) => {
                return moment(log.json_updater_message_processed).diff(
                  moment(row.sent_from_hercules),
                  'seconds',
                  true
                )
              })
            ).toFixed(2) + 's'
          )
        },
        message_size: function (row) {
          const size = byteSize(row.message_size)
          return `${size.value} ${size.unit}`
        },
        action: function (row) {
          return <ActivityLogsActionColumn action={row.action} />
        },
      }
    },
    customRowClick(_event, row) {
      this.showDiff(row)
    },
    showDiff(row) {
      this.$refs.modal.object = row
      this.$refs.modal.show()
    },
  },
}
</script>
