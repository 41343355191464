<template>
  <div class="d-flex flex-gutter-x-sm">
    <div class="dropdown d-inline-block bulk-action" v-if="showBulkAction && !basicHeader">
      <button class="btn btn-secondary">{{ bulkLabel }}</button>
      <ul class="dropdown-menu">
        <li @click="$emit('bulkEditClick')">Selected rows {{ rowsSelected }}</li>
        <li @click="clearBulkSelection">Clear</li>
      </ul>
    </div>
    <button v-else-if="showBulkAction && basicHeader" @click="$emit('bulkEditClick')" class="btn btn-primary">
      Select ({{ rowsSelected }})
    </button>
    <div class="dropdown sticky d-inline-block" v-if="!basicHeader">
      <button class="btn btn-secondary">Columns</button>
      <ul class="dropdown-menu" style="min-width: 250px">
        <li v-for="(column, index) in columnsFiltered" :key="index">
          <div class="checkbox">
            <input
              type="checkbox"
              @change="toggleColumn(column)"
              :checked="visibleColumns.includes(column.field)"
              :id="`col_${index}`"
            />
            <label :for="`col_${index}`">{{
              $prettyLabels(column.label ? column.label : column.field)
            }}</label>
          </div>
        </li>
        <li class="dropdown-divider"></li>
        <li @click="resetDefault">Reset to defaults</li>
      </ul>
    </div>
    <span @click="openModalInformation()" class="btn btn-outline-info btn-md ml-2" v-if="confluenceId">
      <i class="uil uil-info-circle"></i>Help
    </span>
    <button
      v-if="customActionButton"
      :class="['mr-0', customActionButton.class || 'btn btn-warning', { loading: customActionButtonLoading }]"
      @click="customActionButton.action"
      class="ml-2"
    >
      <i v-if="customActionButton.icon" :class="customActionButton.icon" />
      {{ customActionButton.title || 'Title missing' }}
    </button>
    <button
      v-if="customSecondaryButton"
      :class="[
        'mr-0',
        customSecondaryButton.class || 'btn btn-warning',
        { loading: customSecondaryButtonLoading },
      ]"
      @click="customSecondaryButton.action"
      class="ml-2"
    >
      <i v-if="customSecondaryButton.icon" :class="customSecondaryButton.icon" />
      {{ customSecondaryButton.title || 'Title missing' }}
    </button>
    <button
      v-if="showCreate"
      style="margin-right: 0 !important"
      class="btn btn-primary ml-2"
      @click="$emit('createClick')"
    >
      <i class="uil uil-plus" />
      Create
    </button>
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import UsersService from '@services/UsersService'
import patterns from '@constants/regex-patterns'
import InformationModal from '@molecules/cms/modals/information-modal'

export default {
  data() {
    return {
      showCreate: this.$store.getters[`${this.stateName}/showCreate`],
      confluenceId: 0,
    }
  },
  components: {
    InformationModal,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    stateName: {
      type: String,
      required: true,
    },
    basicHeader: {
      type: Boolean,
      required: true,
    },
    customActionButton: {
      type: Object,
      default: null,
    },
    customSecondaryButton: {
      type: Object,
      default: null,
    },
    totalRows: {
      type: Number,
    },
  },
  created() {
    patterns.table_actions.some(
      function (item) {
        let match = this.$route.path.match(item.regex)
        if (match && match.length > 0 && match[0] === this.$route.path) {
          this.confluenceId = item.id
          return true
        }
      }.bind(this)
    )
  },
  computed: {
    rowsSelected() {
      return this.bulkSelectAllItems ? this.totalRows : this.bulkSelected.length
    },
    visibleColumns() {
      return this.$store.getters[`${this.stateName}/visibleColumns`]
    },
    customActionButtonLoading() {
      return this.$store.getters[`${this.stateName}/customActionButtonLoading`]
    },
    customSecondaryButtonLoading() {
      return this.$store.getters[`${this.stateName}/customSecondaryButtonLoading`]
    },
    bulkLabel() {
      return this.rowsSelected > 0 ? `Bulk edit (${this.rowsSelected})` : 'Bulk edit'
    },
    showBulkAction() {
      return this.bulkSelected.length > 0 || this.bulkSelectAllItems
    },
    bulkSelected() {
      return this.$store.getters[`${this.stateName}/selecteditems`]
    },
    bulkSelectAllItems() {
      return this.$store.getters[`${this.stateName}/allItemsSelected`]
    },
    columnsFiltered() {
      return this.columns.filter((column) => {
        return column.field !== 'actions' && column.type !== 'csv'
      })
    },
  },

  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    clearBulkSelection() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
    },
    resetDefault() {
      this.$store.dispatch(
        `${this.stateName}/setVisibleColumns`,
        this.columnsFiltered.map((column) => {
          return column.field
        })
      )
      this.updateUserSettings()
    },
    updateUserSettings() {
      const user = this.$auth.user()

      let userObject = {
        id: user.id,
        table_settings: user.table_settings,
      }

      if (!userObject.table_settings) {
        userObject.table_settings = {}
      }

      userObject.table_settings[this.$route.path] = this.visibleColumns
      UsersService.updateSettings(userObject)
    },
    toggleColumn(column) {
      this.$store.dispatch(`${this.stateName}/setVisibleColumns`, column.field)
      this.updateUserSettings()
    },
  },
}
</script>
