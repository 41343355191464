const state = {
  model: null,
  isLoading: true,
}

const mutations = {
  setModel(state, value) {
    state.model = value
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
