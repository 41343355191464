<template>
  <div class="row">
    <div class="w-100">
      <sidebar-filter showPageTypes class="" ref="sidebar" :site-id="$route.params['id']" />
      <data-table
        v-if="marketId && pageType"
        model="cms/Category"
        pusherModel="sites-categories"
        service-file="CategoryService"
        :path="path"
        :columns="columns()"
        :create-defaults="createDefaults()"
        :extraDeleteInfo="{
          title: 'Careful, this is a destructive action!',
          message: 'All breadcrumbs/pages etc... that use this category will be affected.',
        }"
        ref="list"
      />
    </div>
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import SidebarFilter from '@molecules/cms/sites/categories-sidebar-filter.vue'

export default {
  mounted() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      showBulkEdit: false,
    }
  },
  computed: {
    path() {
      return `hercules/sites/categories?with=site&site_id=${this.$route.params['id']}&market_id=${this.marketId}&page_type_id=${this.pageType}`
    },
    marketId() {
      return this.$store.state.sidebar.market && this.$store.state.sidebar.market
    },
    pageType() {
      return this.$store.state.sidebar.pageType && this.$store.state.sidebar.pageType
    },
  },
  watch: {
    marketId(marketId) {
      if (marketId) this.updateTableFilters({ market_id: this.marketId, page_type_id: this.pageType })
    },
    pageType(pageType) {
      if (pageType && this.marketId)
        this.updateTableFilters({ market_id: this.marketId, page_type_id: this.pageType })
    },
  },
  methods: {
    updateTableFilters(filters) {
      this.emitter.emit('data-table.applyFilters', filters)
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'description', type: 'textarea', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        site_id: parseInt(this.$route.params['id']),
        market_id: this.marketId,
        page_type_id: this.pageType,
      }
    },
  },
  components: { SidebarFilter },
  unmounted() {
    this.$store.commit('sidebar/setSite', null)
    this.$store.commit('sidebar/setMarket', null)
    this.$store.commit('sidebar/setPageType', null)
    this.$store.commit('sidebar/setCategory', null)
  },
}
</script>
