<template>
  <div class="modal in" id="sports-add" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="close" @click="handleButton(false)"></button>
        </div>
        <div class="modal-body pb-1">
          <p>{{ content }}</p>
          <p v-if="extraContent" :class="boldText">{{ extraContent }}</p>
        </div>
        <div class="modal-footer pt-3">
          <div class="text-right">
            <button class="btn btn-secondary mr-3" @click="handleButton(false)">
              {{ actionButtonCancelTitle }}
            </button>
            <button
              :class="actionButtonClass"
              @click="handleButton(true)"
              v-if="showActionButton"
              ref="focus"
            >
              <i :class="actionButtonIconClass"></i>
              {{ actionButtonTitle }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closeDialog } from 'vue3-promise-dialog'
export default {
  methods: {
    handleButton(value) {
      closeDialog(value)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.focus.focus()
    })
  },
  props: {
    title: {
      default: () => 'Confirm',
    },
    content: {
      default: () => 'Are you sure you want to delete this item?',
    },
    showActionButton: {
      default: () => true,
    },
    actionButtonTitle: {
      default: () => 'Delete item',
    },
    actionButtonIconClass: {
      default: () => 'uil uil-trash',
    },
    actionButtonClass: {
      default: () => 'btn btn-danger',
    },
    actionButtonCancelTitle: {
      default: () => 'Close',
    },
    boldText: {
      default: () => 'font-weight-normal',
    },
  },
}
</script>
