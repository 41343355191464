<template>
  <div>
    <div v-bind:key="index" v-for="(moduleField, index) in pageModule.module_value_fields">
      <model-select
        v-if="moduleField.field.name === 'cards_link_value'"
        :parameters="{ site_id: siteId, market_id: marketId, with_count: 'items' }"
        :label="$prettyLabels(moduleField.field.name)"
        model="page"
        :value="moduleField.value"
        :value-label="moduleField.value_label"
        @valuePicked="(value, label) => pageValuePicked(moduleField, value, label)"
      />

      <base-select
        v-else-if="Object.hasOwn(isItWithOptions, moduleField.field.name)"
        :label="moduleField.field.name"
        v-model="moduleField.value"
        :placeholder="'Select ' + $prettyLabels(moduleField.field.name).toLowerCase()"
        :custom-options="isItWithOptions[moduleField.field.name]"
      />

      <base-radio
        v-else-if="
          isItRadioButtonsType &&
          Object.hasOwn(isItRadioButtonsType, moduleField.field.name) &&
          (moduleField.value
            ? moduleField.value
            : (moduleField.value = isItRadioButtonsType[moduleField.field.name].default))
        "
        :label="moduleField.field.name"
        :modelValue="
          moduleField.value ? moduleField.value : isItRadioButtonsType[moduleField.field.name].default
        "
        :name="moduleField.field.name"
        :data="isItRadioButtonsType[moduleField.field.name].values"
        @update:modelValue="(option) => (moduleField.value = option.value)"
        v-bind:key="index"
      />

      <div v-else-if="customCardsDisplayInput && isFieldHaveModalPage.includes(moduleField.field.name)">
        <module-group-add-item :moduleField="moduleField" :site-id="siteId" :market-id="marketId">
          <template v-slot:default="slotProps">
            <model-select
              :parameters="{ site_id: siteId, market_id: marketId, with_count: 'items' }"
              model="page"
              :moduleField="slotProps.module"
              :value="slotProps.module.children[0].value"
              :value-label="slotProps.module.children[0].value_label"
              @valuePicked="(value, label) => pageValuePicked(slotProps.module.children[0], value, label)"
            />
          </template>
        </module-group-add-item>
      </div>

      <page-edit-module-field
        v-else-if="moduleField.field.name !== 'cards_display_input_custom_list'"
        :index="index"
        :siteId="siteId"
        :affiliateId="affiliateId"
        :marketId="marketId"
        :moduleField="moduleField"
        @update:modelValue="(value) => fieldChange(moduleField)"
      />
    </div>
  </div>
</template>

<script>
import ModelSelect from '@molecules/fields/model-select/base-model-select'

import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select'
import BaseRadio from '@atoms/fields/base-radio.vue'
import ModuleGroupAddItem from '@atoms/cms/modules/module-group-add-item'
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'

export default {
  components: { ModelSelect, BaseSelect, BaseInput, ModuleGroupAddItem, BaseRadio, PageEditModuleField },
  props: {
    siteId: {
      required: false,
      default: null,
    },
    marketId: {
      required: false,
      default: null,
    },
    pageModule: {
      type: Object,
      required: true,
      default: () => {},
    },
    affiliateId: {},
  },
  data() {
    return {
      isItRadioButtonsType: {
        cards_sort_by: {
          values: ['created_at', 'updated_at', 'random'],
          default: 'created_at',
        },
        cards_sort_order: {
          values: ['ascending', 'descending'],
          default: 'ascending',
        },
        cards_display_type: {
          values: ['default', 'featured', 'advanced'],
          default: 'default',
        },
        cards_display_input: {
          values: ['automatic', 'custom'],
          default: 'automatic',
        },
      },
      isItWithOptions: {
        cards_model_type: ['article', 'game', 'operator', 'software_provider', 'payment_method'],
      },
      isFieldHaveModalPage: ['cards_display_input_custom_list'],
    }
  },
  computed: {
    customCardsDisplayInput() {
      return this.pageModule.module_value_fields.filter((obj) => {
        return obj.field.name === 'cards_display_input'
      })[0].value == 'custom'
        ? true
        : false
    },
  },
  methods: {
    pageValuePicked(moduleField, value, valueLabel) {
      moduleField.value = value
      moduleField.value_label = valueLabel
      this.$forceUpdate()
    },
    pageSelectColumns() {
      return [
        { field: 'title', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
      ]
    },
  },
}
</script>
