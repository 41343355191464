export default {
  getForm(operatorId) {
    return [
      {
        name: 'affiliate_id',
        search_url: 'hercules/operators/affiliates',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'website'
      },
      {
        name: 'type',
        search_url: `hercules/operators/game-types?operator_id=${operatorId}`,
        required: true,
        display_name: 'Type',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'type'
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        selectLabel: 'label',
        type: 'list-one',
        value_key: 'market.label'
      },
      { name: 'review_link', required: false, display_name: 'Review Link', type: 'text', validation: '' }
    ]
  },
  getConfig() {
    return {
      url: 'operators/affiliate-operators',
      name: 'Sites Data',
      pagination: true
    }
  },
  getStructure(operatorId) {
    return {
      list: this.getForm(operatorId),
      config: this.getConfig()
    }
  }
}
