<template>
  <div class="modal show" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clone module</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2">
          <div class="callout callout-danger">
            Before cloning, make sure the target page does not have any unsaved changes
          </div>
          <div class="callout callout-info mb-4">
            The cloned module will be placed at the bottom of the module list and by default it's going to be inactive
          </div>
          <model-select
            :parameters="{ market_id: marketId, site_id: siteId }"
            label="Selected page"
            ref="modelSelect"
            modal-size-class="modal-lg2"
            model="page"
            customLabel="Target page"
            :loadOnMount="false"
            :value="targetPageId"
            :value-label="valueLabel"
            @valuePicked="pageValuePicked"
          />
          <BaseSelect
            class="mt-2"
            :disabled="targetPageId === null"
            :loadOnMount="false"
            optionLabelKey="name"
            label="Target section"
            v-model="targetSectionId"
            :customOptions="sections"
          >
          </BaseSelect>
        </div>
        <div class="modal-footer">
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex align-items-center text-muted">
              <i class="uil uil-info-circle pr-2"></i>After cloning, the target page will be openened in a new
              tab.
            </div>
            <button
              type="button"
              class="btn btn-success"
              :disabled="targetPageId === null || targetSectionId === null"
              :class="{ loading: isLoading }"
              @click="cloneModule"
            >
              Clone
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesService from '@services/PagesService'
import ModelSelect from '@molecules/fields/model-select/base-model-select'
import BaseSelect from '@/components/fields/base-select.vue'
import ModulesService from '@services/ModulesService'

export default {
  name: 'clone-modal',
  data() {
    return {
      valueLabel: '',
      sections: [],
      targetSectionId: null,
      targetPageId: null,
      isLoading: false
    }
  },
  props: {
    siteId: {
      required: true
    },
    marketId: {
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    pageModule: {
      type: Object,
      required: true
    }
  },
  components: {
    ModelSelect,
    BaseSelect
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    async cloneModule() {
      this.isLoading = true
      await ModulesService.persist({
        target_section_id: this.targetSectionId,
        module_id: this.pageModule.module_id,
        site_id: this.siteId,
        target_page_id: this.targetPageId,
        module_value_id: this.pageModule.id
      })
        .then(response => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Module cloned')
            if (this.targetPageId == this.$route.params.id) {
              let fullPath = this.$route.fullPath;
              if (!fullPath.includes('#sections')) {
                fullPath += '#sections'
              }
              window.open(fullPath, '_blank')
            } else {
              let pathSegments = this.$route.path.split('/')
              pathSegments.pop()
              pathSegments.push(this.targetPageId)
              let newPath = pathSegments.join('/') + '#sections'
              window.open(newPath, '_blank')
            }
            this.close()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    pageValuePicked(id, path) {
      this.targetPageId = id
      this.valueLabel = path
      if (id === null) {
        this.sections = []
        this.targetSectionId = null
        return
      }
      this.getModulesData(id)
    },
    async getModulesData(id) {
      await PagesService.getOne(id, {
        with: 'sections'
      })
        .then(response => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.sections = response.data.result[0].sections
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
