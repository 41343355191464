<template>
  <div>
    <data-table
      model="cms/Redirect"
      pusherModel="sites-redirects"
      service-file="RedirectService"
      path="hercules/sites/redirects"
      ref="list"
      :columns="columns()"
      :createDefaults="{ active: 1, type: '301' }"
      :column-templates="columnTemplates()"
      :customActionButton="{
        action: () => (showBulkRedirectModal = true),
        class: 'btn btn-warning',
        title: 'CSV Upload',
      }"
      class="redirects-table"
    />
    <redirects-modal v-if="showBulkRedirectModal" ref="RedirectsModal" v-on:refresh="refresh" />
  </div>
</template>

<script>
import BasicOptions from '@mixins/BasicOptions'
import TableColumnTemplate from '@constants/table-column-template'
import RedirectsModal from '@molecules/cms/redirects/csv-modal.vue'

export default {
  data() {
    return {
      showBulkRedirectModal: false,
    }
  },
  created() {
    this.setTitle()
  },
  components: { RedirectsModal },
  methods: {
    columns() {
      return [
        { field: 'site', type: 'text', label: 'Site', filterable: true },
        { field: 'from', type: 'text', filterable: true },
        { field: 'to', type: 'text', filterable: true, template: TableColumnTemplate.CLIPBOARD },
        { field: 'type', type: 'text', filterable: true, width: '100px' },
        Object.assign({}, BasicOptions.newActiveColumn(), {
          template: TableColumnTemplate.BOOLEAN,
          width: '100px',
        }),
        { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign({
        site: function (row) {
          return row.site.name
        },
      })
    },

    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
<style lang="scss">
.redirects-table {
  .search-input {
    .form-control {
      width: 185px;
    }
  }
}
</style>
