<template>
  <data-table
    ref="list"
    model="cms/BannerItem"
    service-file="cms/BannerItemsService"
    :path="path"
    :columns="columns()"
    :enableRowClick="false"
    :showRemove="false"
    :loadOnMount="false"
    :showCreate="false"
    :row-action-buttons="rowActions()"
    :columnTemplates="columnTemplates()"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'
import PageStatusBadge from '@atoms/table/cell/pages-status.vue'
import GeneralService from '@services/GeneralService.js'

export default {
  data() {
    return {
      moduleValueFields: null,
    }
  },
  watch: {
    path() {
      if (this.pageIds) {
        this.emitter.emit('data-table.applyFilters', {
          ids: this.pageIds,
        })
      }
    },
  },
  computed: {
    path() {
      return `hercules/sites/pages?with=market,template,categories&full_url=1&ids=${this.pageIds}`
    },
    pageIds() {
      if (this.moduleValueFields) {
        const pageIds = this.moduleValueFields.map((field) => field.module_value.owner_id)
        return pageIds.length > 0 ? pageIds.join(',') : '-1'
      }
      return null
    },
  },
  created() {
    this.getModuleValueFields()
  },
  methods: {
    columns() {
      return [
        { field: 'title', type: 'text', filterable: true, template: TableColumnTemplate.TEXT_TRUNCATE },
        { field: 'path', type: 'text', filterable: true, template: TableColumnTemplate.CLIPBOARD },
        { field: 'template', type: 'text', label: 'Type', filterable: true },
        { field: 'market', type: 'text', label: 'Market', filterable: true },
        {
          field: 'updated_at',
          label: 'Updated',
          type: 'text',
          filterable: true,
          template: TableColumnTemplate.RELATIVE_DATE,
        },

        { field: 'status', type: 'text', label: 'Status', filterable: true },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          status: function (row) {
            return <PageStatusBadge page={row} minimal={true} />
          },
        },
        {
          template: function (row) {
            return row.template ? row.template.name : ''
          },
        },
        {
          market: function (row) {
            return row.market.label
          },
        }
      )
    },
    rowActions() {
      return [
        {
          action: this.editPage,
          title: 'Edit page',
          icon: 'uil uil-edit',
          class: 'btn-warning',
        },
        {
          action: this.viewLivePage,
          title: 'View live page',
          icon: 'uil uil-external-link-alt',
          class: 'btn-info',
        },
      ]
    },
    getModuleValueFields() {
      GeneralService.fetchItems('hercules/sites/module-value-fields', {
        site_id: this.$parent.$parent.cmsSiteId,
        value: this.$route.params.id,
        field_name: 'top_list_item_id',
        exact_match: 'value',
        with: 'module_value',
        owner_type: 'page',
      }).then((response) => {
        if (response.data.result) {
          this.moduleValueFields = response.data.result
        }
      })
    },
    editPage(row) {
      const module = this.moduleValueFields.find((item) => {
        return item.module_value.owner_id == row.id
      })
      if (module) {
        window.open(`/site-pages/${row.id}?index=${module.module_value.position}#sections`, '_blank')
      } else {
        this.showErrorMessage('Top list module was not found')
      }
    },
    viewLivePage(row) {
      window.open(`${row.full_url}`, '_blank')
    },
  },
}
</script>
