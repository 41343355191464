<template>
  <div
    class="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    v-click-outside="close"
    v-bind:class="{ show: isActive }"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add a module</h5>
          <div class="modal-title-buttons">
            <button class="close modal-title-button" @click="close"></button>
          </div>
        </div>
        <div class="modal-body modal-max-height" v-if="section">
          <button
            class="btn btn-secondary btn-link mb-3"
            v-if="selectedModule !== null"
            @click="backToSearch"
          >
            <i class="uil uil-angle-left"></i>
            Back to search
          </button>
          <div v-if="selectedModule === null">
            <div class="mb-3 has-helper-text">
              <input
                type="text"
                class="form-control"
                placeholder="Search for any module"
                v-model="search"
                v-on:keyup.enter="addFirstModule"
                ref="input"
              />
              <span class="helper-text bg-white-50 mr-3 text-muted" v-if="search">
                Press enter to add the first result
              </span>
            </div>
            <div class="mb-2">
              <ul class="nav nav-tabs">
                <li
                  class="nav-item"
                  v-for="(tab, index) in tabs"
                  @click="() => (activeTab = tab)"
                  :key="index"
                >
                  <a class="nav-link" v-bind:class="{ active: activeTab === tab }">
                    {{ tab }} ({{ tabCount(tab) }})</a
                  >
                </li>
              </ul>
            </div>
            <div class="d-grid grid-narrow">
              <div
                class="card card-clickable"
                v-for="(module, index) in allModulesTabbed"
                :key="index"
                @click="selectModule(module)"
              >
                <div class="card-header">
                  <div
                    class="left-element bottom-0 left-0 position-absolute m-3"
                    v-if="search && index === 0"
                  >
                    <i class="uil uil-enter"></i>
                  </div>
                  <div class="right-element top-0 right-0 position-absolute m-3">
                    <div class="badge bg-orange-75" v-if="module.moduleType === 'Template Block'">Block</div>
                    <div class="badge bg-purple-75" v-if="module.moduleType === 'Prefilled Module'">
                      Prefilled
                    </div>
                  </div>
                </div>
                <div class="card-body text-center">
                  <span
                    class="btn btn-lg icon-button rounded-pill pointer-events-none"
                    v-bind:class="getStyle(module).color"
                  >
                    <i v-bind:class="[getStyle(module).icon]" />
                  </span>
                  <span class="h3 d-block mt-3 text-truncate w-100">{{
                    $prettyLabels(getModuleName(module))
                  }}</span>
                  <p class="additional text-muted">{{ getStyle(module).description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h4>
              Where would you like to add the
              <strong class="text-blue-75">{{ $prettyLabels(selectedModule.name) }}</strong> module?
            </h4>
            <ul class="list-group mh-300 mt-3 border">
              <li
                class="list-group-item d-flex align-items-center"
                v-for="(moduleValue, index) in section.module_values"
                :key="index"
              >
                <span
                  class="badge badge-secondary counter-badge"
                  v-bind:class="{ 'bg-green-75': index === addIndex }"
                  >{{ index + 1 }}</span
                >
                <span class="title ml-3">{{ getModuleValueName(moduleValue) }}</span>
                <div class="ml-auto">
                  <button
                    class="btn btn-primary bg-green-50 float-right on-hover ml-3"
                    @click="addModule(selectedModule, index + 1)"
                  >
                    Add below <i class="uil uil-corner-right-down"></i>
                  </button>
                  <button
                    class="btn btn-primary bg-green-75 float-right on-hover"
                    @click="addModule(selectedModule, index)"
                  >
                    <i class="uil uil-corner-up-right"></i> Add above
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'
import ModuleValue from '@constants/module-value'

export default {
  components: { BaseInput, ModuleValue },
  data() {
    return {
      isActive: false,
      search: null,
      tabs: ['All', 'Module', 'Prefilled Module', 'Template Block'],
      activeTab: 'All',
      addIndex: null,
      selectedModule: null,
      disableButton: false,
    }
  },
  props: {
    section: {},
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  computed: {
    allModulesTabbed() {
      return this.filterModules(true)
    },
    allModules() {
      return this.filterModules(false)
    },
  },
  methods: {
    filterModules(tabbed) {
      let modules = []
      if (this.section.enabled_modules) {
        this.section.enabled_modules.forEach((module) => {
          module.moduleType = 'Module'
          modules.push(module)
        })
      }
      if (this.section.template_blocks) {
        this.section.template_blocks.forEach((module) => {
          module.moduleType = 'Template Block'
          modules.push(module)
        })
      }
      if (this.section.prefilled_modules) {
        this.section.prefilled_modules.forEach((module) => {
          module.moduleType = 'Prefilled Module'
          modules.push(module)
        })
      }
      if (this.search) {
        modules = modules.filter((item) => {
          let name = this.getModuleName(item).toLowerCase().includes(this.search.toLowerCase())
          if (!name) {
            name = this.getStyle(item).description.toLowerCase().includes(this.search.toLowerCase())
          }
          return name
        })
      }
      if (tabbed && this.activeTab !== 'All') {
        modules = modules.filter((item) => item.moduleType === this.activeTab)
      }
      return modules
    },
    tabCount(tab) {
      if (tab === 'All') return this.allModules.length
      return this.allModules.filter((module) => module.moduleType === tab).length
    },
    backToSearch() {
      this.selectedModule = null
      this.search = null
      this.disableButton = false
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    addModule(module, index) {
      this.$emit('addModule', module, index)
      this.close()
    },
    addFirstModule() {
      if (this.search) {
        this.selectModule(this.allModules[0])
      }
    },
    selectModule(module) {
      if (!this.disableButton) {
        this.disableButton = true
        if (Number.isInteger(this.addIndex)) {
          this.addModule(module, this.addIndex + 1)
        } else if (this.section.module_values.length === 0) {
          this.addModule(module, 0)
        } else {
          this.selectedModule = module
        }
      }
    },
    getModuleValueName(moduleValue) {
      if (moduleValue.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return moduleValue.template_block_name
      }
      return moduleValue.module.label
    },
    getStyle(module) {
      if (module.moduleType === 'Prefilled Module') {
        return { icon: 'uil uil-window', color: 'bg-purple-75', description: this.getDescription(module) }
      } else if (module.moduleType === 'Template Block') {
        return { icon: 'uil uil-box', color: 'bg-orange-75', description: this.getDescription(module) }
      }
      const moduleColor = 'bg-azure-75'
      const styles = {
        anchor: {
          icon: 'uil uil-anchor',
          color: moduleColor,
          description: 'Show automatically generated anchor list',
        },
        archive: { icon: 'uil uil-archive', color: moduleColor, description: 'A paginated list of pages' },
        banner: { icon: 'uil uil-images', color: moduleColor, description: 'Feature an image' },
        bonus: { icon: 'uil uil-euro-circle', color: moduleColor, description: 'Show an Operator Bonus' },
        counter: {
          icon: 'uil uil-percentage',
          color: moduleColor,
          description: 'Display a numerical statistic',
        },
        cards: { icon: 'uil uil-postcard', color: moduleColor, description: 'Feature pages of your choice' },
        cards_v2: {
          icon: 'uil uil-postcard',
          color: moduleColor,
          description: 'Feature pages of your choice',
        },
        carousel: {
          icon: 'uil uil-image-minus',
          color: moduleColor,
          description: 'Show horizontally scrollable images ',
        },
        content: { icon: 'uil uil-newspaper', color: moduleColor, description: 'Add text via editor' },
        default: { icon: 'uil uil-octagon', color: moduleColor, description: 'Add a module' },
        faq: {
          icon: 'uil uil-comment-alt-question',
          color: moduleColor,
          description: 'Add questions and answers',
        },
        iframe: { icon: 'uil uil-arrow', color: moduleColor, description: 'Embed an inline frame' },
        image: {
          icon: 'uil uil-image',
          color: moduleColor,
          description: 'Add an image using a separate module',
        },
        menu: { icon: 'uil uil-bars', color: moduleColor, description: 'Show a navigation element' },
        pros_and_cons: {
          icon: 'uil uil-thumbs-up',
          color: moduleColor,
          description: 'List advantages & disadvantages',
        },
        spotlights: {
          icon: 'uil uil-document-layout-center',
          color: moduleColor,
          description: 'Link to URLs with your text & image',
        },
        top_list: { icon: 'uil-list-ul', color: moduleColor, description: 'Display a list of operators' },
      }
      return (styles[module.name] ??= styles['default'])
    },
    getDescription(module) {
      if (module.moduleType === 'Prefilled Module') {
        return module.name
      }
      if (module.description) {
        return module.description
      }
      return 'No description available'
    },
    getModuleName(item) {
      if (item.moduleType === 'Prefilled Module') {
        return item.module.name
      }
      return item.name
    },
    show(addIndex = null) {
      this.isActive = true
      this.addIndex = addIndex
      this.disableButton = false
      setTimeout(() => {
        this.$refs.input.focus()
      }, 100)
    },
    close() {
      this.isActive = false
      this.search = null
      this.selectedModule = null
      this.activeTab = 'All'
    },
  },
}
</script>
