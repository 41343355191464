<template>
  <data-table
    service-file="AffiliateGameService"
    model="GameSiteData"
    :path="path()"
    ref="list"
    :columns="columns()"
    :showEditModal="false"
    :csvColumnTemplates="csvColumnTemplates()"
    :customRowClick="rowClick"
    :showCreate="false"
    :column-templates="columnTemplates()"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'
import StatusColumn from '@/components/table/status-column'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Games`
    )
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'provider', type: 'text', filterable: true },
        { field: 'market', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'status', type: 'text', filterable: true },
        {
          field: 'iframe_is_valid',
          label: 'Iframe valid',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: false,
          customOptions: [
            { label: 'Valid', id: 1 },
            { label: 'Not Valid', id: 0 },
            { label: 'Not verified', id: 2 },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
        },
        { field: 'iframe_validation_date', type: 'csv', label: 'Iframe Validation Date' },
      ]
    },
    rowClick(event, row) {
      const ctrlOrMetaKey = event.ctrlKey || event.metaKey
      const path = `/games/${row.game_id}/sites?site_name=${this.$store.state.editPage.model.website}`
      if (ctrlOrMetaKey) {
        // open in new tab
        window.open(path, '_blank')
      } else {
        // navigate to edit page
        this.$router.push(path)
      }
    },
    path() {
      return `hercules/games/affiliate-games?affiliate_id=${this.$route.params['id']}&with=game,market`
    },
    columnTemplates() {
      return Object.assign({
        name: function (row) {
          return row.game.name
        },
        provider: function (row) {
          return row.game.provider
        },
        market: function (row) {
          if (row.market) {
            return row.market.label
          }
        },
        status: function (row) {
          return <StatusColumn status={row.status} />
        },
        iframe_is_valid: function (row) {
          if (row.game.iframe_is_valid === null) {
            return (
              <span data-tooltip="Not verified">
                <i class="uil uil-question-circle text-dark"></i>
              </span>
            )
          }
          let message = row.game.iframe_status_message
          return row.game.iframe_is_valid ? (
            <span data-tooltip={message}>
              <i class="uil uil-check-circle text-success"></i>
            </span>
          ) : (
            <span data-tooltip={message}>
              <i class="uil uil-ban text-danger"></i>
            </span>
          )
        },
      })
    },
    csvColumnTemplates() {
      return Object.assign({
        iframe_is_valid: function (row) {
          return row.game.iframe_is_valid === null
            ? 'Not Verified'
            : row.game.iframe_status_message.split(':')[1]
        },
        iframe_validation_date: function (row) {
          return row.game.iframe_is_valid === null
            ? 'Not Verified'
            : row.game.iframe_status_message
            ? row.game.iframe_status_message.split(':')[0]
            : row.game.iframe_status_message.split(':')[1]
        },
      })
    },
  },
}
</script>
