<template>
  <div>
    <data-table
      model="SiteLogs"
      service-file="SiteLogsService"
      path="hercules/logs/wp-logs"
      :columns="columns()"
    />
  </div>
</template>
    
<script>
import TableColumnTemplate from '@constants/table-column-template'
export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'site', type: 'text', filterable: true },
        { field: 'user_name', type: 'text', filterable: true },
        { field: 'user_email', type: 'text', filterable: true },
        { field: 'market', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'message', type: 'text', filterable: true, template: TableColumnTemplate.TEXT_TRUNCATE },
        { field: 'date_received', type: 'text', label: 'Timestamp', filterable: true, template: TableColumnTemplate.DATE }
      ]
    },
    createDefaults() {
      return {
        enabled: true
      }
    },
  }
}
</script>