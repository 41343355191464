<template>
  <div class="form-group">
    <base-radio
      @update:modelValue="(option) => typeChange(option)"
      :label="$prettyLabels(label)"
      :modelValue="value.type"
      name="type"
      :data="['page', 'external']"
      :useNameValue="false"
      :inlineLayout="true"
    />
    <model-select
      v-if="value.type === 'page'"
      :parameters="{ market_id: marketId, site_id: siteId, match_market_language: 1 }"
      label="Selected page"
      ref="modelSelect"
      model="page"
      :value="value.value"
      :value-label="value.value_label"
      @valuePicked="pageValuePicked"
      :valueLabel="this.value.value_label"
    />
    <base-input
      v-if="value.type === 'external'"
      label="Link value"
      v-model="value.value"
      @update:modelValue="updateValue"
    />
    <base-input
      v-if="enableLabel"
      :label="linkTitle"
      v-model="value.title"
      @update:modelValue="updateValue"
    />
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import BaseRadio from '@atoms/fields/base-radio.vue'
import ModelSelect from '@molecules/fields/model-select/base-model-select'
import BaseInput from '@atoms/fields/base-input.vue'

const defaultValue = { type: 'page', value: null, title: null }

export default {
  components: {
    BaseRadio,
    ModelSelect,
    BaseInput,
  },
  data() {
    return {
      linkTitle: this.getTitleLabel(),
    }
  },
  created() {
    if (this.value === null) {
      // set defaults
      this.$emit('valueChanged', JSON.stringify(defaultValue))
    } else {
      this.updateValue()
    }
  },
  updated() {
    if (this.value === null) {
      this.$emit('valueChanged', JSON.stringify(defaultValue))
    }
  },
  props: {
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    value: {
      type: [Object],
      default: () => defaultValue,
    },
    enableLabel: {
      type: [Boolean],
      default: false,
    },
    siteId: {
      type: Number,
      required: true,
    },
    marketId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getTitleLabel() {
      return this.value.type == 'page'
        ? this.$prettyLabels(this.label) + ' custom title'
        : this.$prettyLabels(this.label) + ' title'
    },
    updateValue() {
      this.$emit('valueChanged', JSON.stringify(this.value))
    },
    typeChange(type) {
      this.value.value = null
      this.value.type = type
      this.updateValue()
      this.linkTitle = this.getTitleLabel()
      this.$forceUpdate()
    },
    pageValuePicked(value, valueLabel) {
      this.value.value = value
      this.value.value_label = valueLabel
      this.$forceUpdate()
      this.updateValue()
    },
  },
}
</script>
