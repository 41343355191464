<template>
  <div>
    <monitoring-filter
      @filterChanged="filterChanged"
      extraDateInfo="Filtering timezone is CET."
      ref="monitoring-filter"
    />
    <data-table
      model="koala/Accounts"
      service-file="koala/AccountsService"
      path="koala/v1/account-statuses"
      permissionPath="koala/v1/accounts"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      ref="table"
      :rowActionButtons="rowActions()"
      :tableLimits="[10, 50, 100]"
      :extraComponentHeight="monitoringFilterHeight"
      :fieldAccountId="'account_id'"
    />
    <credentials-modal ref="modal" :createCredentials="createCredentials" />
  </div>
</template>

<script lang="jsx">
import AccountsService from '@services/koala/AccountsService'
import CredentialsModal from '@molecules/koala/credentials-modal.vue'
import BaseDate from '@atoms/fields/base-date'
import MonitoringFilter from '@molecules/cms/pages/monitoring-filter.vue'
import BasicOptions from '@mixins/BasicOptions'
import TableColumnTemplate from '@constants/table-column-template'
import RunAccount from '@mixins/RunAccount'
import FilterChangedLogic from '@mixins/FilterChangedLogic'

export default {
  mixins: [FilterChangedLogic, RunAccount],
  created() {
    this.setTitle()
  },
  mounted() {
    this.monitoringFilterHeight = this.$refs['monitoring-filter'].$el.clientHeight
    this.setTitle()
  },
  data() {
    return {
      monitoringFilterHeight: 0,
      createCredentials: false,
      users: [],
      start: null,
      end: null,
    }
  },
  components: { BaseDate, MonitoringFilter, CredentialsModal },
  methods: {
    columns() {
      return [
        {
          label: 'BI id',
          field: 'account_finance_id',
          type: 'text',
          filterable: true,
          advancedFilter: false,
          sortable: true,
          order_key: 'account_finance_id',
        },
        {
          label: 'Platform',
          field: 'platform_name',
          type: 'text',
          class: 'col-platforms',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          label: 'Operator',
          field: 'operator_name',
          type: 'text',
          class: 'col-operators',
          search_key: 'operators',
          advancedFilter: true,
          path: this.computedPath.operatorsPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          order_key: 'operator_name',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          label: 'Username',
          field: 'account_username',
          type: 'text',
          class: 'col-account_username',
          search_key: 'accounts',
          advancedFilter: true,
          path: this.computedPath.accountsPath,
          optionLabelKey: 'username',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          sortable: true,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'username',
          extraSelectLabel: 'operator.name',
          width: '300px',
        },
        {
          label: 'Unit',
          field: 'business_unit_name',
          type: 'text',
          class: 'col-business_units',
          search_key: 'business_units',
          advancedFilter: true,
          path: 'koala/v1/business-units?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
          width: '200px',
        },
        {
          label: 'Account Manager',
          field: 'account_manager_name',
          type: 'text',
          filterable: true,
          order_key: 'account_manager_name',
          class: 'col-account_manager_name',
          search_key: 'account_manager_name',
          advancedFilter: true,
          path: 'backoffice/users/byRole?roles=accounts,affiliate management&orderBy=name&ascending=1',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: true,
          fetch_on_load: false,
          trackBy: 'name',
          showAllOption: true,
          searchBy: 'name',
          width: '200px',
        },
        Object.assign({}, BasicOptions.booleanColumn('account_active'), {
          template: TableColumnTemplate.BOOLEAN,
          label: 'Account Enabled',
          allowNullOption: true,
          clearable: true,
          sortable: true,
          customOptions: [
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' },
          ],
        }),
        {
          label: 'Account Last Enabled / Disabled',
          field: 'account_active_updated',
          search_key: 'account_active_updated',
          type: 'text',
          filterable: false,
          advancedFilter: false,
          clearable: true,
          sortable: true,
          allowNullOption: true,
          template: TableColumnTemplate.FORMATTED_DATE_TIME,
        },
        {
          label: 'Account Status',
          field: 'account_status',
          search_key: 'account_status',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          clearable: true,
          allowNullOption: true,
          customOptions: [
            { label: 'Active', id: 'Active' },
            { label: 'Closed', id: 'Closed' },
            { label: 'Migrated', id: 'Migrated' },
            { label: 'Merged', id: 'Merged' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          template: TableColumnTemplate.ACCOUNT_STATUS,
        },
        {
          label: 'Timestamp',
          field: 'created',
          type: 'text',
          filterable: false,
          advancedFilter: false,
          template: TableColumnTemplate.FORMATTED_DATE_TIME,
        },
        {
          label: 'Report Date',
          field: 'date',
          type: 'text',
          filterable: false,
          advancedFilter: false,
        },
        {
          label: 'Step',
          field: 'step',
          type: 'text',
          search_key: 'step',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          order_key: 'step',
          showEmptyNull: true,
          fetch_on_load: false,
          clearable: true,
          optionLabelKey: 'step',
          trackBy: 'step',
          searchBy: 'step',
          isGroupedMonitoring: true,
          width: '100px',
        },
        {
          label: 'Status',
          field: 'status',
          search_key: 'is_ok',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          clearable: true,
          allowNullOption: true,
          customOptions: [
            { label: 'OK', id: 'true' },
            { label: 'not OK', id: 'false' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
        },
        Object.assign({}, BasicOptions.booleanColumn('auto_disabled'), {
          template: TableColumnTemplate.BOOLEAN,
          customOptions: [
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          clearable: true,
        }),
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          platform_name: function (row) {
            return row.platform_name
          },
        },
        {
          operator_name: function (row) {
            return row.operator_name
          },
        },
        {
          account_username: function (row) {
            return row.account_username
          },
        },
        {
          account_status: function (row) {
            return row.account_status
          },
        },
        {
          account_id: function (row) {
            return row.account_id
          },
        },
        {
          business_unit_name: function (row) {
            return row.business_unit_name
          },
        },
        {
          account_manager_name: function (row) {
            return row.account_manager_name
          },
        },
        {
          created: function (row) {
            return row.created
          },
        },
        {
          step: function (row) {
            return row.step
          },
        },
        {
          status: function (row) {
            return row.status
          },
        }
      )
    },
    rowActions() {
      return [
        {
          action: (row) => this.runAccount(row.account_id),
          title: 'Run Account',
          icon: 'uil uil-play',
          class: 'btn-success',
          disabledCondition: (row) => !row.account_active,
        },
        {
          action: this.credentials,
          title: 'Credentials',
          icon: 'uil uil-key-skeleton',
          class: 'btn-info',
        },
      ]
    },
    credentials(row) {
      let object = { id: row.account_id, operator: row.operator_name, username: row.account_username }
      AccountsService.getCredentials(row.account_id)
        .then((response) => {
          if (response.data.messages) {
            this.$refs.modal.object = object
            this.$refs.modal.show()
            this.createCredentials = true
            return
          }
          const { password, api_credentials } = response.data.result[0]
          this.createCredentials = false
          object = {
            ...object,
            password: password,
            api_username: api_credentials.api_username,
            api_key: api_credentials.api_key,
          }

          this.$refs.modal.object = object
          this.$refs.modal.show()
        })
        .catch(() => {
          this.showErrorMessage('Failed to get credentials')
        })
    },
    dataModifier(data) {
      let modified = { ...data }
      const objectKeys = [
        'platform_name',
        'operator_name',
        'account_username',
        'business_unit_name',
        'account_manager_name',
        'created',
        'step',
        'status',
        'auto_disabled',
      ]
      objectKeys.forEach((key) => {
        if (data[key] && data[key].id) {
          modified[key] = data[key].id
        }
      })
      modified.keywords = data.keywords ? data.keywords : []
      return modified
    },
    filterChanged(filter) {
      this.start = filter.created_start
      this.end = filter.created_end
      this.$store.commit('data-table/setActivePage', 1)
      this.$store.dispatch('data-table/setSiteFilters', filter)
    },
  },
  unmounted() {
    this.$store.dispatch('data-table/setSiteFilters', {})
  },
}
</script>
