const state = {
  extraOption: null,
  baseCheckboxValue: null
}

const getters = {
  getExtraOption: state => {
    return state.extraOption
  },
  getBaseCheckboxValue: state => {
    return state.baseCheckboxValue
  }
}

const actions = {
  addExtraOption({ commit }, value) {
    commit('addExtraOption', value)
  }
}

const mutations = {
  addExtraOption(state, value) {
    state.extraOption = value
  },
  setBaseCheckboxValue(state, value) {
    state.baseCheckboxValue = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}