<template>
  <div class="d-flex justify-content-end">
    <div class="d-flex" v-for="(button, index) in rowActionButtons" :key="index">
      <button
        :class="`btn btn-sm ml-3 text-center ${button.class}`"
        :disabled="button.disabledCondition && button.disabledCondition(row)"
        v-on:click.stop="button.action(row)"
        v-if="button.action && hasPermission(button.permission) && (button.visibleCondition ? button.visibleCondition(row) : true)"
      >
        <i :class="button.icon"></i>
        {{ typeof button.title === 'function' ? button.title(row) : button.title }}
      </button>
    </div>
    <a
      v-if="showRemove && showMinimalRemove === false"
      class="btn btn-danger btn-sm ml-2 icon-btn"
      v-on:click.stop="$emit('remove')"
    >
      <i class="uil uil-trash-alt"></i> Delete
    </a>
    <button
      v-if="showRemove && showMinimalRemove === true"
      class="btn btn-danger text-white btn-sm ml-2 btn-link icon-button"
      v-on:click.stop="$emit('remove')"
    >
      <i class="uil uil-trash"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => {}
    },
    showMinimalRemove: {
      type: Boolean,
      default: () => false
    },
    showRemove: {
      type: Boolean,
      default: () => true
    },
    rowActionButtons: {
      type: Array,
      default: () => []
    }
  }
}
</script>